import { faStar as faStarFull } from '@fortawesome/pro-solid-svg-icons'
import { faStarHalf as faStarHalf } from '@fortawesome/pro-duotone-svg-icons'

import { Icon } from 'ui'
import theme from '../config/tailwind.config.js'
import Image from 'next/image'

type Props = { rating: number; color?: string; sharpStar?: boolean; className?: string }

export default function ReviewStars({ rating, color, sharpStar, className = 'w-6 h-6' }: Props) {
	const parsedRating =
		rating != null
			? // Force 4.5 stars if the rating is above 4.5 but less than a perfect 5
			  (Math.round((rating >= 4.5 && rating < 5 ? 4.5 : rating) * 2) / 2).toFixed(1)
			: null

	const fullStars = parsedRating && Math.floor(Number(parsedRating))
	const emptyStars = parsedRating && 5 - Math.floor(Number(parsedRating))
	const withHalfStar = parsedRating && parsedRating.toString().includes('.5')

	return fullStars || withHalfStar ? (
		<div className="flex gap-1">
			{fullStars
				? Array.apply(null, Array(fullStars)).map((_: unknown, i: number) => {
						if (sharpStar) {
							return (
								<Image
									src="/images/svg/sharp-star.svg"
									alt="star"
									width={80}
									height={80}
									sizes="80px"
									quality={70}
									className="mx-auto w-10 md:w-16 lg:w-20"
								/>
							)
						}
						return <Icon icon={faStarFull} className={`${className}`} key={i} />
				  })
				: null}

			{withHalfStar ? (
				sharpStar ? (
					<Image
						src="/images/svg/sharp-star-half.svg"
						alt="star"
						width={80}
						height={80}
						sizes="80px"
						quality={70}
						className="mx-auto"
					/>
				) : (
					<Icon
						icon={faStarHalf}
						className={className}
						// fontawesome duotone colors
						style={{
							'--fa-primary-color': color || theme.theme.extend.colors.black.rt,
							'--fa-secondary-color': theme.theme.extend.colors.g[50],
							'--fa-secondary-opacity': 1.0
						}}
					/>
				)
			) : null}

			{emptyStars
				? Array.apply(null, Array(withHalfStar ? emptyStars - 1 : emptyStars)).map(
						(_: unknown, i: number) => {
							if (sharpStar) {
								return (
									<Image
										src="/images/svg/sharp-star-empty.svg"
										alt="star"
										width={80}
										height={80}
										sizes="80px"
										quality={70}
										className="mx-auto"
									/>
								)
							}
							return <Icon icon={faStarFull} className={`${className} !text-gray-200/80`} key={i} />
						}
				  )
				: null}
		</div>
	) : (
		<>
			{Array.apply(null, Array(5)).map((_: unknown, i: number) => {
				return <Icon icon={faStarFull} className={`${className}  !text-gray-200/80`} key={i} />
			})}
		</>
	)
}
