// Global Builder Queries

export const globalBuilderQuery = () => `
  {
    global_builder {
        ...global_builderFields
        primary_cta_link {
            ...on product_category {
                ...product_categoryFields
            }
        }
        secondary_cta_link {
            ...on product_category {
                ...product_categoryFields
            }
        }
        primary_cta_link1 {
            ...on product_category {
                ...product_categoryFields
            }
        }
        secondary_cta_link1 {
            ...on product_category {
                ...product_categoryFields
            }
        }
        primary_cta_link2 {
            ...on product_category {
                ...product_categoryFields
            }
        }
        secondary_cta_link2 {
            ...on product_category {
                ...product_categoryFields
            }
        }
       
        artwork_modal_link {
            ...on modal {
                ...modalFields
                primary_cta_link {
                    ...on product_category {
                        ...product_categoryFields
                    }
                }
            }
        }
        delivery_date_modal_link {
            ...on modal {
                ...modalFields
                 primary_cta_link {
                    ...on product_category {
                        ...product_categoryFields
                    }
                }
            }
        }
        cart {
            ...on modal {
                ...modalFields
                 primary_cta_link {
                    ...on product_category {
                        ...product_categoryFields
                    }
                }
            }
        }
        artwork_reviews_modal_link {
            ...on modal {
                ...modalFields
                primary_cta_link {
                    ...on product_category {
                        ...product_categoryFields
                    }
                }
            }
        }
    }
  }
`
