import { Layout } from '@/components/layout'
import type { AppProps } from 'next/app'
import 'styles/global.scss'
import { PrismicPreview } from '@prismicio/next'

import { ApolloProvider } from '@apollo/client'

import apolloClient from 'auth/client/apollo/apolloClient'

import { SessionProvider } from 'auth'

import { SpeedInsights } from '@vercel/speed-insights/next'

import { Provider as WrapBalancerProvider } from 'react-wrap-balancer'
import { CustomerLabsProvider } from 'analytics'
import '@fortawesome/fontawesome-svg-core/styles.css'
import { repositoryName } from 'config/prismic.config'

const App = ({ Component, pageProps }: AppProps) => {
	return (
		<>
			<CustomerLabsProvider cdn={process.env.NEXT_PUBLIC_CUSTOMER_LABS_CDN}>
				<ApolloProvider client={apolloClient}>
					<SessionProvider>
						<WrapBalancerProvider>
							<Layout
								data={pageProps?.page?.data}
								globalData={pageProps?.page?.globalData}
								promotionalPopups={pageProps?.page?.promotionalPopups}
							>
								<Component {...pageProps} />
							</Layout>
						</WrapBalancerProvider>
					</SessionProvider>
				</ApolloProvider>
			</CustomerLabsProvider>

			{/* Vercel Speed Insights */}
			<SpeedInsights />

			<PrismicPreview repositoryName={repositoryName} />
		</>
	)
}

export default App
