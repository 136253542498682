import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import { useForm } from 'react-hook-form'
import { Input, Spinner, Error, Icon } from 'ui'
import Link from 'next/link'

import { VerificationFlow, UpdateVerificationFlowBody } from '@ory/client'
import {
	handleFlowError,
	getCsrfToken,
	getOryVerificationFlow,
	sendVerificationEmail,
	useSession
} from 'auth'
import { useUserInfo } from 'hooks'
import clsx from 'clsx'
import { faSpinnerThird } from '@fortawesome/pro-light-svg-icons'
import { faArrowRight } from '@fortawesome/pro-solid-svg-icons'
import { useCookie } from 'react-use'

type Props = {
	isFromCheckout?: boolean
	setIsCodeValidated?: Dispatch<SetStateAction<boolean>>
}

const VerifyEmail = ({ isFromCheckout }: Props) => {
	const [_updateCookie, setCookie] = useCookie('brand_new_user')

	const { userInfo, isLoading } = useUserInfo()

	const { currentUser, flow, verifyEmail, setFlow } = useSession()

	const [csrfToken, setCsrfToken] = useState<string>(null)
	const [loading, setLoading] = useState<boolean>(false)
	const [verificationSuccess, setVerificationSuccess] = useState<boolean>(false)

	const { control, handleSubmit, register, setValue } = useForm()

	// The "flow" represents a registration process and contains
	// information about the form we need to render (e.g. username + password)
	const router = useRouter()

	// Get ?flow=... from the URL
	const { flow: flowId } = router.query

	useEffect(() => {
		setCookie('true')
		getOryVerificationFlow(flow, setFlow, router, flowId)
		const token = getCsrfToken(flow)
		setCsrfToken(token)
	}, [flowId, router, flow])

	const handleVerifyEmail = async (values: UpdateVerificationFlowBody) => {
		setLoading(true)
		try {
			await verifyEmail(values, flow as VerificationFlow, isFromCheckout)
			setVerificationSuccess(true)
		} catch (error) {
			setVerificationSuccess(false)
			try {
				await handleFlowError(error, router, 'verify-email', setFlow)
			} catch (err) {
				// If the previous handler did not catch the error it's most likely a form validation error
				if (err.response?.status === 400) {
					setFlow(err.response?.data)
					return
				}

				return Promise.reject(err)
			}
		} finally {
			setLoading(false)
		}
	}

	if (isLoading) {
		return (
			<div className="min-h-[240px] flex items-center justify-center">
				<Icon icon={faSpinnerThird} className="fa-sharp fa-spin text-3xl" />
			</div>
		)
	}

	return (
		<div className={clsx('gap-4', isFromCheckout ? 'md:w-full' : 'max-w-xl')}>
			{
				// User access to this page without flowId
				!flowId ? (
					<>
						{currentUser?.verifiable_addresses[0].verified || verificationSuccess ? (
							<>
								<p className="text-sm text-success">Your email has been already verified.</p>
							</>
						) : (
							<>
								<p className="text-error text-sm mb-4">
									The verification token is not valid or has already been used.
								</p>

								<button
									className="button button-dark button-lg w-full"
									onClick={() =>
										sendVerificationEmail(
											csrfToken,
											isFromCheckout ? currentUser.traits : userInfo,
											flow,
											setFlow,
											router,
											verifyEmail
										)
									}>
									Send new verification email
									<Icon icon={faArrowRight} className="right-arrow-hover mb-1" />
								</button>
							</>
						)}
					</>
				) : (
					<>
						{flow?.ui?.messages?.map((message) => (
							<div key={message.id}>
								{message.type === 'success' || message.type === 'info' ? (
									<p className="text-sm mb-4">
										We’ve sent a verification code to{' '}
										<span className="font-medium">
											{currentUser?.verifiable_addresses[0].value}
										</span>
										. If you don’t see it, check your spam folder and ensure you used the correct
										address email.
									</p>
								) : (
									<Error className="mb-4">{message.text}</Error>
								)}
							</div>
						))}
						{
							// User access to this page with valid flowId
							flow?.ui?.messages?.[0].type !== 'success' && (
								<form onSubmit={handleSubmit(handleVerifyEmail)}>
									<input
										type="hidden"
										{...register('method', {
											required: true,
											value: 'code'
										})}
									/>

									{csrfToken && (
										<input
											type="hidden"
											{...register('csrf_token', {
												required: true,
												value: csrfToken
											})}
										/>
									)}

									<Input
										name="code"
										type="number"
										label="Verification code"
										control={control}
										rules={{ required: 'Verification code is required' }}
									/>

									<button
										className="button button-dark button-lg mt-6 w-full"
										type="submit"
										disabled={loading}>
										{loading && <Spinner />}
										Verify email
										<Icon icon={faArrowRight} className="right-arrow-hover mb-1" />
									</button>

									<span className="desktop-body-sm block mt-7 text-center">
										Didn’t receive an email?
										<Link
											href=""
											className="hyperlink ml-2"
											onClick={() =>
												sendVerificationEmail(
													csrfToken,
													isFromCheckout ? currentUser.traits : userInfo,
													flow,
													setFlow,
													router,
													verifyEmail
												)
											}>
											Resend code
										</Link>
									</span>
								</form>
							)
						}
					</>
				)
			}
		</div>
	)
}

export default VerifyEmail
