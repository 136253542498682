import { faArrowLeft, faArrowRight } from '@fortawesome/pro-solid-svg-icons'
import { type Dispatch, type SetStateAction } from 'react'

import { Icon } from '../'
import MobileNavLinkOption from './MobileNavLinkOption'
import type { GlobalDocument, NavDropdownLinkGroupDocument } from 'types/types.generated'

type MobileDrawerProps = {
	dropdown: NavDropdownLinkGroupDocument
	isOpen: boolean
	setIsMobileNavOpen: Dispatch<SetStateAction<boolean>>
	setOpenNavDrawer: Dispatch<SetStateAction<number>>
	analyticalClickFactory?: (value: string) => () => void
	globalData: GlobalDocument
}
export default function MobileDrawer({
	dropdown,
	isOpen,
	setIsMobileNavOpen,
	setOpenNavDrawer,
	analyticalClickFactory,
	globalData
}: MobileDrawerProps) {
	const { button_label, description, link_groups } = dropdown.dropdown.data || {}

	return button_label?.[0]?.text ? (
		<div
			className={`mobile-drawer
				${isOpen ? 'translate-x-0 duration-500' : 'translate-x-[105%] duration-300'}
			`}
			role="dialog"
			aria-label="Navigation menu"
			tabIndex={isOpen ? 0 : -1}>
			<div className="flex justify-between items-center mt-2 ml-6 pb-3 text-white">
				{/* 'Close Drawer' / 'Go to Previous Menu' Button */}
				<button
					onClick={() => {
						setOpenNavDrawer(null)
						if (analyticalClickFactory) analyticalClickFactory(button_label[0].text)()
					}}
					className="flex items-center gap-2 py-2 pr-2 text-sm mt-6">
					<Icon icon={faArrowLeft} className="w-4 h-4 mb-1" />
					{button_label[0].text}
				</button>

				<button
					className="nav-button toggled"
					onClick={() => {
						setOpenNavDrawer(null)
						if (analyticalClickFactory) analyticalClickFactory('Toggle the nav drawer')()
					}}
					aria-label="Toggle the nav drawer"
				/>
			</div>

			{/* Description */}
			{description?.[0]?.text ? (
				<p className="font-serif text-lg mt-8">{description[0].text}</p>
			) : null}

			{/* Links */}
			{Array.isArray(link_groups) ? (
				<div className="space-y-8 mt-3 mb-10">
					{link_groups.map((linkGroup, i) => (
						<MobileNavLinkOption
							link={linkGroup}
							key={i}
							navTextContrast="light"
							setOpenNavDrawer={setOpenNavDrawer}
							setIsMobileNavOpen={setIsMobileNavOpen}
							analyticalClickFactory={analyticalClickFactory}
							globalData={globalData}
						/>
					))}
				</div>
			) : null}

			{/* "Get Started" Link */}
			<a
				href="/products"
				className="bg-[#000] text-center text-white w-full mt-auto font-medium text-lg py-4 mt-6"
				{...(analyticalClickFactory ? { onClick: analyticalClickFactory('Get Started') } : {})}>
				Get Started <Icon icon={faArrowRight} className="ml-2 mb-px text-sm" />
			</a>
		</div>
	) : null
}
