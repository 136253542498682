import { faPaperPlane } from '@fortawesome/pro-solid-svg-icons'
import { Icon } from 'ui'

export default function ConfirmationText({ text, textContrast }) {
	return (
		<div
			className={`w-full bg-green-rt border border-green-900/20 bg-opacity-[12%] ${
				textContrast === 'light' ? 'text-white' : 'text-green-900'
			} pt-5 pb-4 px-2 rounded-lg text-[13px] text-center`}>
			<Icon icon={faPaperPlane} className="text-sm mr-3" />
			{text}
		</div>
	)
}
