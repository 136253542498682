import { ApolloClient, InMemoryCache, createHttpLink } from '@apollo/client'
import getBaseUrl from '../../../utils/getBaseUrl'

// To bypass CORS issues when developing locally
const linkOptions =
	// TODO uncomment this when deploying to production on realthread.com
	//			it is needed while working on v2.realthread.com
	// process.env.NEXT_PUBLIC_VERCEL_ENV !== 'production'
	// 	?
	{
		fetch: (_uri, options) => {
			return fetch(`${getBaseUrl()}/api/graphql`, {
				...options,
				headers: {
					...options.headers
				}
			})
		}
	}
// : {}

const link = createHttpLink({
	uri: `${process.env.NEXT_PUBLIC_RT_URL}/graphql`,
	...linkOptions,
	// Add compression headers
	headers: {
		'Accept-Encoding': 'gzip, deflate, br'
	}
})

const apolloClient = new ApolloClient({
	link,
	cache: new InMemoryCache()
})

export default apolloClient
