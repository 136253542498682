import Link from 'next/link'
import Image from 'next/image'
import clsx from 'clsx'

import { getBaseUrl } from 'utils'

type LogoProps = {
	navTextContrast?: 'light' | 'dark'
	className?: string
	light_logo?: { url: string; alt: string }
	dark_logo?: { url: string; alt: string }
}

export default function Logo({ navTextContrast, className, light_logo, dark_logo }: LogoProps) {
	return (
		<Link
			href={`${getBaseUrl()}/`}
			className={clsx(className, 'block relative w-[150px] h-9 shrink-0')}>
			<Image
				src={
					(navTextContrast === 'light' ? light_logo?.url : dark_logo?.url) ??
					'https://rstr.in/real-thread/marketing/AFddfOK1x7y'
				}
				alt={navTextContrast === 'light' ? light_logo?.alt : dark_logo?.alt ?? 'Logo'}
				fill
				style={{ objectFit: 'contain', objectPosition: 'center' }}
				sizes="150px"
				draggable={false}
			/>
		</Link>
	)
}
