import { useState } from 'react'
import { default as NextLink } from 'next/link'
import { faQuestionCircle, faArrowRight } from '@fortawesome/pro-solid-svg-icons'
import clsx from 'clsx'

import { linkResolver } from 'utils/prismic'
import { Icon, PrismicModal } from '../'
import PlainText from './PlainText'
import { getBaseUrl } from 'utils'
import { ModalChat } from 'ui/modals'
import type { GlobalDocument } from 'types/types.generated'

type PrismicLinkProps = {
	link?: { uid?: string; url?: string; target?: '_blank'; type?: string; id?: string }
	disabled?: boolean
	anchor?: string
	label: string
	withArrow?: boolean
	className?: string
	onClick?: () => void
	globalData?: GlobalDocument
	withIcon?: boolean
	children?: React.ReactNode
}
export default function PrismicLink({
	link,
	disabled,
	anchor,
	label,
	withArrow = false,
	className = '',
	onClick,
	globalData,
	withIcon,
	children
}: PrismicLinkProps) {
	const classes = `${className} ${withArrow ? 'inline-flex items-center space-x-3 group' : ''}`

	const [openChat, setOpenChat] = useState(false)
	const [openModal, setOpenModal] = useState(false)

	const linkChildren = (
		<>
			<PlainText
				as="span"
				className={clsx(withArrow ? 'translate-y-[2.125px]' : '')}
				content={label}
			/>

			{withArrow ? (
				<Icon
					icon={faArrowRight}
					className="text-sm group-hover:translate-x-1 transition-transform duration-300 ease-in-out ml-2"
				/>
			) : null}
		</>
	)

	const Link = () =>
		link?.type === 'launch_chat' ? (
			<button
				type="button"
				className={classes}
				onClick={() => setOpenChat(true)}
				disabled={disabled}
				{...(link?.target === '_blank'
					? {
							target: '_blank',
							rel: 'noopener noreferrer'
					  }
					: {})}>
				{children ?? linkChildren}
			</button>
		) : link?.type === 'modal' ? (
			<button
				type="button"
				className={clsx(classes)}
				onClick={() => setOpenModal(true)}
				disabled={disabled}
				{...(link?.target === '_blank'
					? {
							target: '_blank',
							rel: 'noopener noreferrer'
					  }
					: {})}>
				<span>{children ?? linkChildren}</span>
				{withIcon && <Icon icon={faQuestionCircle} className="w-4 h-4 ml-2 -mt-0.5" />}
			</button>
		) : anchor ? (
			<a className={classes} href={`#${anchor}`} {...(onClick ? { onClick } : {})}>
				{children ?? linkChildren}
			</a>
		) : link?.url ? (
			<a
				className={classes}
				href={link.url}
				{...(onClick ? { onClick } : {})}
				{...(link?.target === '_blank'
					? {
							target: '_blank',
							rel: 'noopener noreferrer'
					  }
					: {})}>
				{children ?? linkChildren}
			</a>
		) : (
			<NextLink
				href={getBaseUrl() + linkResolver(link)}
				className={classes}
				{...(onClick ? { onClick } : {})}>
				{children ?? linkChildren}
			</NextLink>
		)

	return (
		<>
			<Link />
			<ModalChat open={openChat} setOpen={setOpenChat} globalData={globalData} />
			{link?.type === 'modal' && (
				<PrismicModal
					open={openModal}
					setOpen={setOpenModal}
					modalId={link.id}
					globalData={globalData}
				/>
			)}
		</>
	)
}
