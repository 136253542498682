import { useRouter } from 'next/router'
import { Fragment } from 'react'
import { isNotEmptyArray, unslugify } from 'utils/client'
import { PrismicLink } from './prismic'

type Props = {
	items?: {
		title: string
		slug?: string
	}[]
	category?: string
	hideBreadcrumbs?: boolean
	breadcrumbsOverride?: string
	withRootLevelBreadcrumbPaths?: boolean
	centered?: boolean
	className?: string
}

export default function Breadcrumbs({
	items,
	category,
	hideBreadcrumbs,
	breadcrumbsOverride,
	withRootLevelBreadcrumbPaths,
	centered,
	className
}: Props) {
	const router = useRouter()
	let breadcrumbs

	if (items) {
		breadcrumbs = items
	} else {
		breadcrumbs = generateBreadcrumbs(
			hideBreadcrumbs ? null : breadcrumbsOverride != null ? breadcrumbsOverride : router.asPath,
			withRootLevelBreadcrumbPaths,
			category
		)
	}

	return isNotEmptyArray(breadcrumbs) ? (
		<div
			className={`breadcrumbs flex flex-wrap space-x-2 text-[13px] lg:text-base xl:text-sm font-medium
        ${centered ? 'justify-center' : ''}
				${className ?? ''}
      `}>
			{breadcrumbs.map((crumb, i) => {
				return (
					<Fragment key={i}>
						<PrismicLink
							link={{ uid: crumb.slug }}
							label={crumb.title}
							className={`last:brushstroke-2 text-[13px] ${
								i !== breadcrumbs.length - 1 ? 'hide-on-print' : ''
							}`}
						/>
						{breadcrumbs.length > 1 && i !== breadcrumbs.length - 1 ? (
							<p className="select-none font-extralight hide-on-print">{'/'}</p>
						) : null}
					</Fragment>
				)
			})}
		</div>
	) : null
}

const generateBreadcrumbs = (
	str: string,
	withRootLevelBreadcrumbPaths: boolean,
	category: string
) => {
	if (typeof str === 'string') {
		const strArr = str.split('/')

		const breadcrumbs = [
			{
				slug: '/',
				title: 'Home'
			}
		]
		const parsedBreadcrumbs = strArr.reduce((arr, slug, i) => {
			if (slug) {
				const fullSlug = strArr
					.reduce((fullStrArr, slugStr, strIndex) => {
						if (slugStr && strIndex <= i) {
							fullStrArr.push(slugStr)
						}

						return fullStrArr
					}, [])
					.join('/')
					.split('?')[0]

				arr.push({
					slug: withRootLevelBreadcrumbPaths ? slug.split('?')[0] : fullSlug,
					title:
						// if i is the last index, use the category name
						i === strArr.length - 1 && category ? category : unslugify(slug.split('?')[0])
				})
			}

			return arr
		}, [])

		breadcrumbs.push(...parsedBreadcrumbs)

		return breadcrumbs
	}

	return []
}
