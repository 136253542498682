import { gql, useQuery } from '@apollo/client'

export const GET_USER_INFO = gql(`
	query UserInfo {
		userInfo {
			__typename
            sfid
            email
            firstname
            lastname
            surveyResponse
			profilePhoto
			mobilePhone
			shirtSize
			birthdate
			orderApprovalSms
			orderShippedSms
			orderShippedEmail
			orderDeliveredSms
			orderDeliveredEmail
			company
			website
		}
	}
`)

export const useUserInfo = () => {
	const { loading, error, data, refetch } = useQuery(GET_USER_INFO)

	return {
		userInfo: data && data.userInfo,
		isLoading: loading,
		isError: error,
		refetch
	}
}
