import { useState } from 'react'
import { useMedia } from 'react-use'

const useCarouselProps = () => {
	const [isTransitioning, setIsTransitioning] = useState(false)
	const isTouchScreen = useMedia('( pointer: coarse )', true)
	const isMobile = useMedia('(max-width: 330px)')

	return {
		isTransitioning,
		carouselProps: {
			wrapAround: true,
			cellSpacing: isMobile ? 10 : 32,
			slidesToShow: 3.5,
			slidesToScroll: 1,
			renderBottomCenterControls: null,
			renderCenterLeftControls: null,
			renderCenterRightControls: null,
			beforeSlide: () => setIsTransitioning(true),
			afterSlide: () => setIsTransitioning(false),
			dragging: isTouchScreen
		}
	}
}

export default useCarouselProps
