/**
 * This context is used to load CustomerLabs script and provide the functions used by CustomerLabs to track the events.
 */
import { createContext, useContext, useEffect, useMemo } from 'react'

const CUSTOMER_LABS_METHODS = [
	'trackSubmit',
	'trackClick',
	'pageview',
	'identify',
	'track',
	'trackConsent'
] as const
type MethodNames = typeof CUSTOMER_LABS_METHODS
type TrackingMethod = (...args: any[]) => Array<Array<any>>

type CustomerLabsContextType = {
	[K in MethodNames[number]]: TrackingMethod
}

function useCustomerLabsInterface(clAnalyticsObject: string) {
	let value = useMemo(() => {
		const _window = typeof window === 'undefined' ? globalThis : window
		;(_window.ClAnalyticsObject = clAnalyticsObject),
			(_window[clAnalyticsObject] = Array.isArray(_window[clAnalyticsObject])
				? _window[clAnalyticsObject]
				: []),
			(_window[clAnalyticsObject].methods = CUSTOMER_LABS_METHODS),
			(_window[clAnalyticsObject].factory = function (method: string) {
				return function () {
					// instantiating the argument which is array of event related data
					var instance = Array.prototype.slice.call(arguments)

					// pushing the method of event at the start of that array
					instance.unshift(method)

					//pushing the event in the array which will be read by the customerIO.
					_window[clAnalyticsObject].push(instance)

					return _window[clAnalyticsObject]
				}
			}),
			(_window[clAnalyticsObject].SNIPPET_VERSION = '2.0.0')
		const returnVal = {}
		// Instantiating the methods
		for (var i = 0; i < _window[clAnalyticsObject].methods.length; i++) {
			const method = _window[clAnalyticsObject].methods[i]
			_window[clAnalyticsObject][method] = _window[clAnalyticsObject].factory(method)
			returnVal[method] = (...args: any[]) => _window[clAnalyticsObject][method](...args)
		}
		return returnVal
	}, [clAnalyticsObject])

	return value
}

function useCustomerLabsScript(customerLabsCDN: string) {
	useEffect(() => {
		if (!customerLabsCDN) {
			console.warn(
				'Customer Labs CDN is not defined. Please make sure you have wrapped your app with CustomerLabsProvider'
			)
			return
		}
		// Mounting the Customer IO script to load with script tag.
		const scriptTag = 'script'
		const allScripts = document.getElementsByTagName(scriptTag)

		// To ensure that the script is not loaded multiple times.
		if (Array.from(allScripts).some((script) => script.src === customerLabsCDN)) return

		const newScriptTag: any = document.createElement(scriptTag)
		const topScriptTag = allScripts[0]

		;(newScriptTag.async = 1),
			(newScriptTag.crossOrigin = 'anonymous'),
			(newScriptTag.src = customerLabsCDN)
		if (topScriptTag?.parentNode) topScriptTag.parentNode.insertBefore(newScriptTag, topScriptTag)
		else document.head.appendChild(newScriptTag)
	}, [customerLabsCDN])
}
const CustomerLabsContext = createContext(undefined)

export const useCustomerLabsContext = () => {
	const context = useContext(CustomerLabsContext)

	if (context === undefined) {
		console.warn(
			'Customer Labs context is not defined. Please make sure you have wrapped your app with CustomerLabsProvider'
		)
		return {} as CustomerLabsContextType
	}

	return context as CustomerLabsContextType
}

type CustomerLabsProviderProps = {
	cdn: string
	children: React.ReactNode
}

// Note: This provider should not be children of provider which could cause it to re-render.
// Although react should be smart enough to ignore the useMemo without dependencies and there are checks,
// its best to keep this as parent as the value for this provider is static.
// This is mounted at:
//      projects/website/pages/_app.tsx:18

export const CustomerLabsProvider = ({ cdn, children }: CustomerLabsProviderProps) => {
	const value = useCustomerLabsInterface('_cl')
	useCustomerLabsScript(cdn)
	return <CustomerLabsContext.Provider value={value}>{children}</CustomerLabsContext.Provider>
}
