import { useRef, useState, useEffect } from 'react'
import { PlainText, PrismicLink, RichText } from 'ui'
import Breadcrumbs from 'ui/Breadcrumbs'
import clsx from 'clsx'
import type { GlobalDocument } from 'types/types.generated'

type Props = {
	hero?: boolean
	showBreadcrumbs?: boolean
	title?: string
	titleMaxWidth?: number
	titleFontSize?: string
	headerAs?: 'h1' | 'h2' | 'h3' | 'h4' | 'p' | 'span'
	description?: { text: string }[]
	descriptionHidden?: { text: string }
	descriptionMaxWidth?: number
	primaryCta?: {
		label: string
		link?: {
			uid?: string
			url?: string
			target?: '_blank'
		}
		withArrow?: boolean
	}
	secondaryCta?: {
		label: string
		link?: {
			uid?: string
			url?: string
			target?: '_blank'
		}
		withArrow?: boolean
	}
	centered?: boolean
	textContrast?: string
	className?: string
	descriptionClassName?: string
	globalData?: GlobalDocument
}

export default function HeaderContent({
	hero,
	showBreadcrumbs,
	title,
	titleMaxWidth,
	titleFontSize,
	headerAs,
	description,
	descriptionHidden,
	descriptionMaxWidth,
	primaryCta,
	secondaryCta,
	centered = true,
	textContrast,
	className,
	descriptionClassName,
	globalData
}: Props) {
	// secondary button defaults arrow to true
	if (secondaryCta && secondaryCta.withArrow === undefined) {
		secondaryCta.withArrow = true
	}

	const headerRef = useRef<HTMLDivElement>()

	const [isClient, setIsClient] = useState(false)
	useEffect(() => {
		setIsClient(true)
	}, [])

	return (
		<div
			ref={headerRef}
			className={clsx(
				'header-content flex flex-col gap-y-4 pb-12 z-10 remove-space-if-in-rich-text',
				{
					'items-center': centered
				},
				textContrast === 'light' && 'text-light',
				className
			)}>
			{showBreadcrumbs ? <Breadcrumbs centered={centered} /> : null}
			{title?.length ? (
				<PlainText
					data-lcp-element
					as={headerAs ?? 'h2'}
					className={`
						${headerAs === 'h1' ? 'desktop-h1' : 'desktop-h2'}
            ${titleFontSize || ''}
            ${centered ? 'text-center' : ''}
						${textContrast === 'light' ? 'text-white' : 'text-black-rt'}
          `}
					content={title}
					style={{
						maxWidth: titleMaxWidth ? `${titleMaxWidth}px` : centered ? '878px' : '456px'
					}}
				/>
			) : null}

			{/* Revealable Description from Heros */}
			{description && hero ? (
				<>
					<div
						data-lcp-element
						className={clsx(
							'text-lg [&>p]:my-0 mt-2',
							centered ? 'text-center centered' : '',
							descriptionClassName || '',
							textContrast === 'light' ? 'text-gray-300 text-light' : 'text-g-500',
							'hero-description'
						)}
						style={{
							maxWidth: descriptionMaxWidth
								? `${descriptionMaxWidth}px`
								: centered
								? '878px'
								: '456px'
						}}>
						<article className="inline">
							{typeof description === 'string' ? description : description[0]?.text || ''}

							{/* Revealable Part of Description */}
							{isClient && descriptionHidden && descriptionHidden[0] && (
								<details className="description-details inline ml-1">
									<summary className="hyperlink font-normal text-inherit hover:text-black-rt relative inline ml-1 cursor-pointer">
										Read more
									</summary>
									<span>{descriptionHidden[0]?.text}</span>
								</details>
							)}
						</article>
					</div>
				</>
			) : null}

			{/* Rich Text Description from Other Components */}
			{description?.length != 0 && !hero ? (
				<RichText
					className={`text-lg [&>p]:my-0
            ${centered ? 'text-center max-w-[878px] centered' : 'max-w-[456px]'} 
						${descriptionClassName || ''}
						${textContrast === 'light' ? 'text-gray-300 text-light' : 'text-g-500'}
          `}
					content={description}
				/>
			) : null}

			{/* Buttons */}
			{primaryCta?.link || secondaryCta?.link ? (
				<div
					className={`buttons flex flex-wrap gap-2 mt-2 z-10 ${centered ? 'justify-center' : ''}`}>
					{primaryCta?.label && primaryCta.link ? (
						<PrismicLink
							withArrow={primaryCta.withArrow}
							className={`button button-md  ${
								textContrast == 'light' ? 'button-light' : 'button-dark'
							}`}
							link={primaryCta.link}
							label={primaryCta.label}
							globalData={globalData}
						/>
					) : null}

					{secondaryCta?.label && secondaryCta.link ? (
						<PrismicLink
							withArrow={secondaryCta.withArrow}
							className="button button-secondary"
							link={secondaryCta.link}
							label={secondaryCta.label}
							globalData={globalData}
						/>
					) : null}
				</div>
			) : null}
		</div>
	)
}
