import { memo, useEffect, useState } from 'react'
import Image from 'next/image'
import { ImageJsonLd } from 'next-seo'
import { useForm } from 'react-hook-form'
import { faArrowRight } from '@fortawesome/pro-regular-svg-icons'
import clsx from 'clsx'

import { sanitizeObj } from 'utils/sanitize'
import { isNotEmptyArray, isNotEmptyObject } from 'utils/client'
import { Error, Icon } from 'ui'
import { submitForm } from 'utils/forms/form'

import { FormFields, ConfirmationText, FormSkeleton } from 'ui/form'
import prismicClient from 'config/prismic.config'
import type { FormDocument } from 'types/types.generated'

interface Props {
	form: FormDocument
	textContrast?: 'light' | 'dark'
}

const LeadGenForm = ({ form, textContrast }: Props) => {
	const { data } = form || {}
	const [isSubmitted, setIsSubmitted] = useState(false)

	const [formFields, setFormFields] = useState(data?.body || [])
	const [endpoint, setEndpoint] = useState(data?.endpoint || null)
	const [form_cta_label, setFormCtaLabel] = useState(data?.form_cta_label || null)
	const [confirmation_text, setConfirmationText] = useState(data?.confirmation_text || null)
	const [form_image, setFormImage] = useState(data?.form_image || null)

	useEffect(() => {
		if (!data) {
			const getData = async () => {
				const { data } = await prismicClient().getByID(form?.id)

				setFormFields(data.body)
				setEndpoint(data.endpoint)
				setFormCtaLabel(data.form_cta_label)
				setConfirmationText(data.confirmation_text)
				setFormImage(data.form_image)
			}
			getData()
		}
	}, [form])

	const confirmationText =
		confirmation_text ?? "Thank you for contacting us. We'll be in touch shortly."

	// Check for valid data from Prismic
	const hasValidForm = !!isNotEmptyArray(formFields)

	// Form logic
	const { handleSubmit, register, reset, watch, control } = useForm()

	const [loading, setLoading] = useState(false)
	const [error, setError] = useState(null)

	const onSubmit = async (data: FormData) => {
		setLoading(true)
		if (isNotEmptyObject(data)) {
			try {
				setError(null)
				await submitForm(data, {
					endpoint: endpoint.url,
					useRecaptcha: true,
					onSuccess: () => {
						reset()
						setIsSubmitted(true)
					},
					onError: (errorMessage) => {
						console.log('Error submitting form', errorMessage)
						setError(errorMessage)
					}
				})
			} catch (e) {
				console.log('Error submitting form', e)
				setError('Error submitting form. Please try again.')
			} finally {
				setLoading(false)
			}
		}
	}

	return (
		<div className="lead-gen-form flex flex-col md:flex-row items-center -mt-20">
			{form_image && (
				<div className="relative lg:-translate-x-20 md:scale-110 lg:scale-125 md:w-1/2">
					<Image
						className="object-cover"
						src={form_image?.url}
						alt={form_image?.alt ?? 'lead-gen-form-image'}
						width={form_image?.dimensions?.width}
						height={form_image?.dimensions?.height}
						sizes="(max-width: 768px) 90vw, (max-width: 1600px) 50vw, 760px"
						quality={70}
					/>
					<ImageJsonLd
						contentUrl={form_image.url}
						images={[form_image]}
						creator="Real Thread"
						creditText="Real Thread"
					/>
				</div>
			)}
			<form className="form w-full md:w-1/2 sm:px-10 xl:px-32" onSubmit={handleSubmit(onSubmit)}>
				{hasValidForm ? (
					<FormFields
						className="form-grid"
						formFields={formFields}
						control={control}
						register={register}
						watch={watch}
						disabled={loading || isSubmitted}
					/>
				) : (
					<FormSkeleton />
				)}

				{confirmation_text && isSubmitted ? (
					<ConfirmationText text={confirmationText} textContrast={textContrast} />
				) : (
					<button
						type="submit"
						className={clsx(
							'inline-flex items-center gap-2 !w-full button',
							textContrast === 'light' ? 'button-light' : 'button-dark'
						)}
						disabled={loading}>
						{form_cta_label ?? 'Submit'}
						<Icon icon={faArrowRight} className="w-3 h-3 mb-1 right-arrow-hover" />
					</button>
				)}

				{error && <Error>{error}</Error>}
			</form>
		</div>
	)
}

export default memo(LeadGenForm)
