import { forwardRef, useState, useEffect, useRef, MutableRefObject } from 'react'
import { Icon, PrismicLink } from 'ui'
import { faX } from '@fortawesome/pro-solid-svg-icons'
import clsx from 'clsx'
import type { PageDocumentDataHeroAnnouncementBarSlice } from 'types/types.generated'

const AnnouncementBar = forwardRef(
	(
		props: PageDocumentDataHeroAnnouncementBarSlice,
		_ref: MutableRefObject<HTMLHeadingElement | HTMLParagraphElement>
	) => {
		const { primary, globalData } = props || {}
		const [isClosed, setIsClosed] = useState(false)
		const announcementBarRef = useRef(null)

		const [barCtaLink, setBarCtaLink] = useState(primary.bar_cta_link)

		const handleResize = () => {
			if (announcementBarRef.current) {
				let totalHeight = isClosed ? 88 : announcementBarRef.current.clientHeight + 88
				document.documentElement.style.setProperty('--nav-height', `${totalHeight}px`)
			}
		}

		useEffect(() => {
			window.addEventListener('resize', handleResize)
			return () => {
				window.removeEventListener('resize', handleResize)
			}
		}, [isClosed])

		return (
			<div
				ref={announcementBarRef}
				id="announcement-bar"
				className={clsx('announcement-bar container pt-4', isClosed ? 'hidden' : '')}>
				<div
					className={clsx('w-full rounded-lg z-[9999]', {
						['bg-white border']: primary.theme === 'light',
						['bg-gradient-to-r from-[#F7F7F3] to-[#F7EFD1]']: primary.theme === 'gradient',
						['bg-black-rt text-white text-light']: primary.theme === 'dark'
					})}>
					<div className="flex items-start justify-between gap-2 pt-2.5 pb-1 px-4">
						<div className="mx-auto">
							{/* Highlight Statement */}
							{primary.highlight_statement[0] ? (
								<span className="font-medium brushstroke-2 mr-2">
									{primary.highlight_statement[0].text}
								</span>
							) : null}

							{/* Bar Description */}
							{primary.bar_description[0] ? (
								<span className="mr-2">{primary.bar_description[0].text}</span>
							) : null}

							{/* Link */}
							{barCtaLink ? (
								<PrismicLink
									className="hyperlink"
									link={primary.bar_cta_link}
									label={primary.bar_cta_label}
									globalData={globalData}
								/>
							) : null}
						</div>

						{/* X Button */}
						<button onClick={() => setIsClosed(true)} aria-label="Close Announcement Bar">
							<Icon icon={faX} className="text-xs mb-px" />
						</button>
					</div>
				</div>
			</div>
		)
	}
)
AnnouncementBar.displayName = 'AnnouncementBar'

export default AnnouncementBar
