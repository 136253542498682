import dayjs from 'dayjs'

import { RUSH_FEE_PERCENT, calculateRushFee, findHoliday } from 'utils'

import type {
	Holiday,
	Order,
	ProductSpec,
	ProductsSpecsByOrder
} from 'types/graphql/app/api/__generated__/graphql'
import { ProductSpecs } from 'types/ProductSpecs.type'

export default function getDeliveryDatesAvailable(
	subtotal: number,
	holidays: Holiday[],
	estimatedDeliveryDate: Date,
	order: Order,
	productSpecs: ProductSpec[] | ProductSpecs | any,
	additionalCharges: ProductsSpecsByOrder['additionalCharges'] | ProductSpecs | any,
	currentRushPercent?: number,
	hasManualPrice?: boolean
) {
	const today = dayjs()

	let maxDate = dayjs(estimatedDeliveryDate).add(1, 'day')

	const estimatedDeliveryDateDay = dayjs(maxDate).day()

	switch (estimatedDeliveryDateDay) {
		case 2:
			maxDate = maxDate.add(5, 'day')
			break

		case 3:
			maxDate = maxDate.add(4, 'day')
			break

		case 4:
			maxDate = maxDate.add(3, 'day')
			break

		case 5:
			maxDate = maxDate.add(2, 'day')
			break

		case 6:
			maxDate = maxDate.add(1, 'day')

			break

		default:
			break
	}

	const minDate = today.add(3, 'day')

	const datesToDisplay = []
	let totalNumberOfRushDays = 0

	for (
		let currentCalendarDate = today;
		currentCalendarDate.isBefore(maxDate);
		currentCalendarDate = currentCalendarDate.add(1, 'day')
	) {
		const dateHoliday = findHoliday(currentCalendarDate.toDate(), holidays)
		const dayOfWeek = currentCalendarDate.day()
		// 0 = Sun | 6 = Sat
		const isWeekend = dayOfWeek === 0 || dayOfWeek === 6
		const isLessThatTwoDaysInFuture = currentCalendarDate.isBefore(minDate)

		const disabled = isLessThatTwoDaysInFuture || isWeekend || !!dateHoliday

		// Check if day is rush day
		const isRushDay =
			!isLessThatTwoDaysInFuture &&
			currentCalendarDate.isBefore(estimatedDeliveryDate) &&
			!isWeekend &&
			!dateHoliday

		if (isRushDay) {
			totalNumberOfRushDays++
		}

		const isNoRushDay =
			currentCalendarDate.isAfter(estimatedDeliveryDate) && !isWeekend && !dateHoliday

		const isStandardDeliveryDate = dayjs(estimatedDeliveryDate).isSame(
			currentCalendarDate.toDate(),
			'date'
		)
		const dateObject = {
			date: currentCalendarDate.toDate(),
			isRushDay,
			isNoRushDay,
			holiday: dateHoliday,
			isStandardDeliveryDate,
			disabled,
			rushPercent: null,
			rushFee: null,
			total: subtotal
		}

		datesToDisplay.push(dateObject)
	}

	let standardRushPercent = totalNumberOfRushDays * RUSH_FEE_PERCENT
	let numberOfDayAfterEstimatedDeliveryDate = 0

	// Add rush percent to dates
	datesToDisplay.forEach((date) => {
		if (date.isRushDay) {
			const rushFee = calculateRushFee(productSpecs, additionalCharges, order, standardRushPercent)

			date.total = subtotal + rushFee
			date.daysRush = totalNumberOfRushDays

			date.rushFee = rushFee
			date.rushPercent = standardRushPercent

			standardRushPercent -= RUSH_FEE_PERCENT
			totalNumberOfRushDays--
		} else if (date.isNoRushDay) {
			date.daysRush = -numberOfDayAfterEstimatedDeliveryDate
			numberOfDayAfterEstimatedDeliveryDate++
		}
	})

	// current selected date needs to have rush fee manual price and rush percent
	// other dates should have rush fee and rush percent times the rush fee manual price percentage

	if (order?.rushFeeManualPrice && currentRushPercent > 0 && hasManualPrice) {
		datesToDisplay.forEach((date) => {
			if (date.rushPercent === currentRushPercent) {
				date.rushFee = order.rushFeeManualPrice
			}
		})

		// Once the rush percent is set, we need to set the rush fee and rush percent for the other dates
		datesToDisplay.forEach((date) => {
			if (date.rushPercent !== currentRushPercent) {
				const rushFeeManualPrice = calculateRushFee(
					productSpecs,
					additionalCharges,
					order,
					currentRushPercent
				)

				const rushPercentWithManualPrice =
					order?.rushFeeManualPrice && rushFeeManualPrice >= 0
						? order?.rushFeeManualPrice / rushFeeManualPrice
						: 0

				const rushFeeWithManualPrice =
					rushPercentWithManualPrice > 0 ? date.rushFee * rushPercentWithManualPrice : date.rushFee

				date.rushFee = rushFeeWithManualPrice
			}
		})
	}

	return datesToDisplay
}
