import Modal from './Modal'
import Search from '../algolia/Search'
import clsx from 'clsx'
import { useGlobalSearch } from 'context'
import { useEffect } from 'react'
import { useRouter } from 'next/router'

export default function ModalSearch() {
	const router = useRouter()

	const { openGlobalSearch, setOpenGlobalSearch } = useGlobalSearch()
	const onClose = () => {
		setOpenGlobalSearch(false)
	}

	// Detect user pressing command + k to open search
	const handleKeyDown = (event: KeyboardEvent) => {
		if (event.metaKey && event.key === 'k') {
			setOpenGlobalSearch(true)
		}
	}

	useEffect(() => {
		window.addEventListener('keydown', handleKeyDown)
		return () => {
			window.removeEventListener('keydown', handleKeyDown)
		}
	}, [])

	return (
		<Modal
			isOpen={openGlobalSearch}
			setIsOpen={setOpenGlobalSearch}
			animateFromTop={router.pathname !== '/help-center'}
			onClose={onClose}
			panelClassName="modal-panel modal--wide bg-transparent flex flex-col shadow-none p-0 !mt-0"
			modalClassName="!items-start"
			closeButtonClassName={clsx(
				'flex items-center justify-center',
				'absolute top-[23px] right-[22px]',
				'bg-white h-8 w-8 rounded-full'
			)}
			aria-label="Search">
			<Search setSearchOpen={setOpenGlobalSearch} />
		</Modal>
	)
}
