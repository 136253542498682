import Link from 'next/link'
import { isNotEmptyArray } from 'utils/client'
import { linkResolver } from 'utils/prismic'
import { getNavigationIcon } from 'utils'
import { Icon } from 'ui'

const VisuallyHidden = ({ children }) => <span className="sr-only">{children}</span>

export default function SocialIcons({ social_media_links, analyticalClickFactory = undefined }) {
	if (isNotEmptyArray(social_media_links)) {
		return (
			<div className="flex gap-3">
				{social_media_links.map((linkObj, i) => {
					const { icon, link } = linkObj || {}
					const platform = /fa-([^\s]+)/
						.exec(icon)[1]
						.split('-')
						.map((a) => a.charAt(0).toUpperCase() + a.slice(1))
						.join(' ')
					return icon && link?.url ? (
						<Link
							href={linkResolver(link)}
							key={i}
							{...(analyticalClickFactory ? { onClick: analyticalClickFactory(platform) } : {})}
							className={`h-fit my-auto group hover:text-yellow-mesh ${
								i !== 0 ? 'p-2' : 'py-2 pr-2'
							}`}
							aria-label={`Visit our ${platform} profile`}>
							<Icon
								icon={getNavigationIcon(icon)}
								className="w-4 h-4 fill-white transition-colors duration-300 ease-in-out group-hover:fill-gray-50"
							/>
							<VisuallyHidden>Visit our {platform} profile</VisuallyHidden>
						</Link>
					) : null
				})}
			</div>
		)
	}
}
