import { NextRouter } from 'next/router'

export const DASHBOARD = '/account'
export const ROOT = '/'

export const LOGIN = '/sign-in'
export const CREATE_ACCOUNT = '/sign-up'
export const FORGOT_PASSWORD = '/forgot-password'
export const RECOVER_ACCOUNT = '/recover-account'
export const ORDERS = '/orders'
export const ORDER_DETAILS = '/orders/[orderId]'
export const CHECKOUT = '/checkout'
export const CART = '/cart'

// routes only for non authenticated users
const NON_AUTHENTICATED_ROUTES = Object.freeze([
	LOGIN,
	CREATE_ACCOUNT,
	FORGOT_PASSWORD,
	RECOVER_ACCOUNT,
	ORDER_DETAILS
])

export const FULLFILLMENT = '/fullfillment'
export const PAYMENT = '/payment'
export const REFER = '/refer'
export const SETTINGS = '/settings'
export const VERIFY_EMAIL = '/verify-email'
export const EMAIL_NOT_VERIFIED = '/email-not-verified'

const PROTECTED_ROUTES = Object.freeze([
	DASHBOARD,
	FULLFILLMENT,
	PAYMENT,
	REFER,
	SETTINGS,
	ORDERS,
	VERIFY_EMAIL,
	ROOT,
	EMAIL_NOT_VERIFIED
])

/**
 * check if the current route is for non-authenticated users
 */
export const isNonAuthenticatedRoute = (pathname: NextRouter['pathname']) =>
	NON_AUTHENTICATED_ROUTES.includes(pathname)

export const isProtectedRoute = (pathname: NextRouter['pathname']) =>
	PROTECTED_ROUTES.includes(pathname)

// export const isPassthroughRoute = (pathname: NextRouter['pathname']) =>
//  pathname === '/auth/sign-out'

export const getLocationOrigin = () => {
	const { protocol, hostname, port } = window.location
	return `${protocol}//${hostname}${port ? ':' + port : ''}`
}
