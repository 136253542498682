import prismicClient from 'config/prismic.config'
import { globalQuery } from 'utils'
import { getDropdownDeepLinks } from 'utils/global'

const getGlobalDataLinks = async () => {
	let globalData = await prismicClient().getSingle('global', {
		graphQuery: globalQuery
	})

	globalData = await getDropdownDeepLinks(globalData)

	return globalData
}

export default getGlobalDataLinks

export const getGlobalChatData = async () => {
	const globalData = await prismicClient().getSingle('global', {
		fetch: ['global.chat_start_endpoint', 'global.chat_submit_endpoint']
	})

	return globalData
}
