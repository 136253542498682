import Link from 'next/link'
import { Dispatch, SetStateAction } from 'react'

import { Icon } from '../'
import { linkResolver } from 'utils/prismic'
import { isValidLink } from 'utils/client'
import { faArrowRight } from '@fortawesome/pro-solid-svg-icons'
import { getBaseUrl, getNavigationIcon } from 'utils'
import CategoryIcon from '../CategoryIcon'
import clsx from 'clsx'
import ChatNow from '../order/ChatNow'
import type { GlobalDocument, NavDropdownLinkGroupDocument } from 'types/types.generated'

type Props = {
	link: NavDropdownLinkGroupDocument
	setIsOpen: Dispatch<SetStateAction<boolean>>
	analyticalClickFactory?: (value: string) => () => void
	globalData?: GlobalDocument
}
export default function NavLinkOptions({
	link,
	setIsOpen,
	analyticalClickFactory,
	globalData
}: Props) {
	const { title, links, with_link_dividers, view_all_link, with_emphasis, link_rows } =
		link.links.data || {}

	// Function to check if the 'icon' field is present, for example for the Products dropdown
	function linksWithIcons(links) {
		return links[0]?.icon && links[0].icon_size !== 'Small' ? true : false
	}

	return title ? (
		<div className={`${!with_link_dividers ? 'space-y-6' : ''}`} role="presentation">
			{/* Title with 'View All' link */}
			{title?.[0]?.text || isValidLink(view_all_link) ? (
				<div
					className={`flex items-center pb-1 ${
						title?.[0]?.text && isValidLink(view_all_link) ? 'justify-between' : ''
					}`}>
					{/* Title */}
					{title?.[0]?.text ? <h3 className="text-black-rt text-[13px]">{title[0].text}</h3> : null}

					{isValidLink(view_all_link) ? (
						<Link
							href={getBaseUrl() + linkResolver(view_all_link)}
							onClick={() => {
								setIsOpen(false)
								if (analyticalClickFactory) {
									analyticalClickFactory('View All')()
								}
							}}
							className="group pl-1 items-center space-x-2 button button-secondary py-0"
							role="menuitem">
							<span>View All</span>
							<Icon
								icon={faArrowRight}
								className={`text-yellow-rt w-3 h-3 group-hover:translate-x-1 transition-transform duration-200 ease-out -translate-y-[2px]`}
							/>
						</Link>
					) : null}
				</div>
			) : null}

			<div
				className={clsx(
					'grid grid-flow-col ',
					linksWithIcons(links)
						? 'grid-rows-2 grid-cols-4 justify-center gap-10 '
						: 'grid-rows-6 gap-x-32 gap-y-3',
					with_link_dividers ? 'grid-rows-3 divide-y divide-gray-200' : '',
					{
						[`!grid-rows-2`]: link_rows === 2,
						[`!grid-rows-3`]: link_rows === 3,
						[`!grid-rows-4`]: link_rows === 4,
						[`!grid-rows-5`]: link_rows === 5,
						[`!grid-rows-6`]: link_rows === 6,
						[`!grid-rows-7`]: link_rows === 7,
						[`!grid-rows-8`]: link_rows === 8
					}
				)}>
				{Array.isArray(links)
					? links.map((linkObj, i) => {
							const {
								icon,
								icon_size,
								link,
								button_label,
								vertical_alignment,
								font_weight,
								font_size
							} = linkObj || {}
							return link.type === 'launch_chat' ? (
								<ChatNow
									key={i}
									text={button_label}
									textClassName="font-medium"
									className="flex items-center gap-2 font-medium"
									globalData={globalData}
									icon={
										icon && (
											<Icon icon={getNavigationIcon(icon)} className="text-black-rt w-4 h-4 mb-1" />
										)
									}
								/>
							) : (
								<Link
									key={`link-${i}`}
									href={link.url ?? getBaseUrl() + linkResolver(link)}
									className={clsx(
										'flex flex-col gap-2 whitespace-nowrap',
										with_link_dividers ? 'py-6' : '',
										with_link_dividers && i === 3 ? '!border-t-0' : '',
										with_emphasis ? 'uppercase' : '',
										vertical_alignment ? 'flex-col' : '',
										`font-${font_weight}`,
										font_size ? `text-${font_size}` : '',
										icon && icon_size !== 'Small'
											? 'items-center text-g-400 text-center whitespace-nowrap w-24'
											: ''
									)}
									onClick={() => {
										setIsOpen(false)
										if (analyticalClickFactory) {
											analyticalClickFactory(button_label)()
										}
									}}>
									{icon ? (
										icon_size === 'Small' ? (
											<div className="flex items-center gap-2 font-medium">
												<Icon
													icon={getNavigationIcon(icon)}
													className="text-black-rt w-4 h-4 mb-1"
												/>

												{button_label}
											</div>
										) : (
											<CategoryIcon
												category={{
													name: button_label,
													lineIcon: getNavigationIcon(icon),
													solidIcon: getNavigationIcon(icon)
												}}
												lineIconColor="text-g-400"
											/>
										)
									) : (
										<>{button_label}</>
									)}
								</Link>
							)
					  })
					: null}
			</div>
		</div>
	) : null
}
