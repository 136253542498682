import { useRef, useEffect } from 'react'
import { clearAllBodyScrollLocks } from 'body-scroll-lock'

const useScrollLock = (isOpen: boolean) => {
	const ref = useRef<HTMLDivElement>(null)

	useEffect(() => {
		if (typeof window !== 'undefined' && ref?.current) {
			const bodyEl = document?.documentElement

			if (bodyEl) {
				if (isOpen) {
					if (!bodyEl.classList.contains('overflow-hidden')) {
						bodyEl.classList.add('overflow-hidden')
					}
				} else {
					if (bodyEl.classList.contains('overflow-hidden')) {
						bodyEl.classList.remove('overflow-hidden')
					}
				}
			}
		}

		return () => typeof window !== 'undefined' && clearAllBodyScrollLocks()
	}, [isOpen])

	return { ref }
}

export default useScrollLock
