import { Fragment } from 'react'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'

import { DateCard, Spinner, Error } from 'ui'
import { Modal } from 'ui/modals'
import { formatCost, getDeliveryDatesAvailable } from 'utils'
import { useHolidays } from 'hooks'
import type { Order } from 'types/graphql/app/api/__generated__/graphql'
import clsx from 'clsx'
import { toCustomerLabsProperty, useAnalyticalDataContext, useCustomerLabsContext } from 'analytics'

// Add the UTC plugin to dayjs
dayjs.extend(utc)

type Props = {
	show: boolean
	setShow: (show: boolean) => void
	estimatedDeliveryDate: Date
	currentDeliveryOn: Date
	tentativeDate: { date: Date; rushFee: number; total: number }
	errorMessage: string
	loading: boolean
	handleSelectDate: ((date: { date: Date; rushFee: number }) => void) | ((_date: Date) => void)
	handleUpdateDeliveryDate: () => Promise<void>
	changesToOrder: any
	order?: Order
	isFromOrderPage?: boolean
	initialRushPercent?: number
	hasManualPrice?: boolean
}

export default function ModalDeliveryDate({
	show,
	setShow,
	estimatedDeliveryDate,
	currentDeliveryOn,
	tentativeDate,
	errorMessage,
	loading,
	handleSelectDate,
	handleUpdateDeliveryDate,
	changesToOrder,
	order,
	isFromOrderPage = false,
	initialRushPercent = null,
	hasManualPrice = false
}: Props) {
	const { holidays } = useHolidays()
	const isCurrentDeliveryDateSelected = dayjs
		.utc(currentDeliveryOn)
		.isSame(tentativeDate?.date, 'date')
	const trackDeliveryDateChanged = useAnalyticsDeliveryDateChange(currentDeliveryOn)
	const deliveryDatesAvailable = getDeliveryDatesAvailable(
		changesToOrder?.subtotalPrice,
		holidays,
		estimatedDeliveryDate,
		order,
		changesToOrder?.productSpecs,
		changesToOrder?.additionalCharges,
		initialRushPercent ?? changesToOrder?.rushPercent,
		hasManualPrice
	)

	const daysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']

	return (
		<Modal
			isOpen={show}
			setIsOpen={setShow}
			panelClassName="modalDeliveryDate modal--wide lg:h-[700px] flex max-md:flex-col p-0"
			aria-label="Change Estimated Delivery">
			{/* White Left Main Panel */}
			<div className="flex-grow py-20 px-4 md:px-16">
				{/* Header */}
				<h2 className="font-serif text-2xl text-center mb-2.5">Change Estimated Delivery</h2>
				<p className="text-center text-g-500 text-sm mb-10">
					Select any available date to change your delivery. Price will adjust if rush is selected.
				</p>

				{/* Days of the Week */}
				<ul className="max-sm:hidden grid max-sm:grid-cols-3 grid-cols-5 md:grid-cols-7 gap-1 lg:gap-4 text-[13px] pr-4 mb-3">
					{daysOfWeek.map((day, index) => (
						<li
							key={index}
							className={clsx('text-center font-medium', {
								['max-md:hidden']: index === 0 || index === 6 // Sunday or Saturday
							})}>
							{day}
						</li>
					))}
				</ul>

				{/* Calendar Grid */}
				<ul className="grid max-sm:grid-cols-3 grid-cols-5 md:grid-cols-7 gap-1 lg:gap-3 modal-scrollable-area lg:max-h-[433px] md:pr-4">
					{deliveryDatesAvailable?.map((date, index) => {
						const blankSpaces = []
						if (index === 0) {
							const dayOfWeek = dayjs(date.date).day()
							for (let i = 0; i < dayOfWeek; i++) {
								blankSpaces.push(<li className="block min-h-24" key={i} />)
							}
						}

						const matchesCurrentDeliveryOn = dayjs.utc(currentDeliveryOn).isSame(date.date, 'date')
						const matchesTentativeDate = dayjs.utc(tentativeDate?.date).isSame(date.date, 'date')

						return (
							<Fragment key={date.date.toString()}>
								{blankSpaces}

								<li className="min-h-24">
									<DateCard
										date={date}
										onSelectDate={handleSelectDate}
										highlighted={tentativeDate ? matchesTentativeDate : matchesCurrentDeliveryOn}
									/>
								</li>
							</Fragment>
						)
					})}
				</ul>

				{errorMessage && <Error>{errorMessage}</Error>}
			</div>

			{/* Gray Right Sidebar */}
			<div className="flex flex-col items-start bg-g-100 pt-20 pb-12 px-4 md:px-10 md:w-[350px]">
				{/* Top Section */}
				<div className="text-left">
					<span className="text-[13px]">Current Delivery</span>
					<h3
						className={`font-serif text-2xl mb-5 ${
							tentativeDate && !isCurrentDeliveryDateSelected ? ' line-through' : ''
						}`}>
						{dayjs.utc(currentDeliveryOn).format('dddd, MMMM DD')}
					</h3>
					{tentativeDate && !isCurrentDeliveryDateSelected && (
						<>
							<span className="text-[13px]">New Delivery</span>
							<h3 className="text-2xl font-serif mb-10">
								{dayjs.utc(tentativeDate?.date).format('dddd, MMMM DD')}
							</h3>
						</>
					)}
				</div>

				{/* Bottom Section */}
				<div className="w-full flex flex-col mt-auto">
					{/* Receipt Section */}
					<table className="receipt-table [&_td]:py-[3px] [&_.receipt-left]:text-left [&_.receipt-right]:text-right w-full text-sm mb-5">
						<tbody>
							<tr className="font-medium">
								<td className="receipt-left">Subtotal</td>
								<td className="receipt-right">{formatCost(changesToOrder?.subtotalPrice)}</td>
							</tr>
							<tr className="text-g-500">
								<td className="receipt-left">Expedited turnaround</td>
								<td className="receipt-right">
									{formatCost(tentativeDate?.rushFee ?? changesToOrder?.rushFee)}
								</td>
							</tr>
							{changesToOrder?.discountValue < 0 && (
								<tr className="text-g-500">
									<td className="receipt-left">Discount</td>
									<td className="receipt-right">{formatCost(changesToOrder?.discountValue)}</td>
								</tr>
							)}
							<tr className="text-g-500">
								<td className="receipt-left">Shipping</td>
								<td className="receipt-right">
									{changesToOrder?.shippingCost
										? formatCost(changesToOrder?.shippingCost)
										: 'Free shipping'}
								</td>
							</tr>
							<tr className="text-g-500">
								<td className="receipt-left">Tax</td>
								<td className="receipt-right">{formatCost(changesToOrder?.salesTax)}</td>
							</tr>
						</tbody>
					</table>

					<hr className="border-warm-gray-500 mb-7" />

					{/* Order Total */}
					<div className="flex justify-between font-medium mb-10">
						<span>Order total</span>
						<span>
							{formatCost(
								tentativeDate?.total && !isFromOrderPage
									? tentativeDate.total + changesToOrder?.salesTax
									: changesToOrder?.totalPrice
							)}
						</span>
					</div>

					{/* Buttons */}
					<button
						className="button button-dark button-lg mb-4"
						onClick={(e) => {
							trackDeliveryDateChanged(tentativeDate.date)
							handleUpdateDeliveryDate()
						}}
						disabled={!tentativeDate || loading}>
						{loading && <Spinner />}
						Change date
					</button>
					<button className="hyperlink w-fit mx-auto" onClick={() => setShow(false)}>
						Cancel
					</button>
				</div>
			</div>
		</Modal>
	)
}

/**
 * Missing Data
 *
 * order_subtotal
 * order_shipping
 * order_sales_tax
 * order_value
 * order_quantity
 * cart_subtotal
 * cart_shipping
 * cart_sales_tax
 * value
 * cart_order_count
 * cart_order_quantity
 */

function useAnalyticsDeliveryDateChange(order_delivery_date_prior: Date) {
	const { trackClick } = useCustomerLabsContext()
	const {
		page_url,
		page_title,
		order_name,
		user_id,
		user_type,
		productProperties,
		order_delivery_type,
		currency,
		cart_subtotal,
		cart_shipping,
		cart_sales_tax,
		cart_order_count,
		cart_order_quantity,
		value,
		order_subtotal,
		order_shipping,
		order_sales_tax,
		order_value,
		order_quantity
	} = useAnalyticalDataContext()

	return (order_delivery_date: Date) => {
		trackClick('Delivery Change', {
			customProperties: toCustomerLabsProperty({
				page_url,
				page_title,
				order_name,
				currency,
				user_id,
				user_type,
				order_delivery_date_prior: dayjs.utc(order_delivery_date_prior).toISOString(),
				order_delivery_date: dayjs.utc(order_delivery_date).toISOString(),
				order_delivery_type,
				cart_subtotal,
				cart_shipping,
				cart_sales_tax,
				cart_order_count,
				cart_order_quantity,
				value,
				order_subtotal,
				order_shipping,
				order_sales_tax,
				order_value,
				order_quantity
			}).v,
			productProperties
		})
	}
}
