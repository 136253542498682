import { gql, useMutation } from '@apollo/client'
import { apolloClientNewServer } from 'utils/clients/apolloClientNewServer'

const payWithPurchaseOrderMutation = gql(`
    mutation payWithPurchaseOrder(
        $orderSfid: String!,
        $customerPo: String!
    ) {
        payWithPurchaseOrder(
            orderSfid: $orderSfid,
            customerPo: $customerPo
        ) {
            sfid
        }
    }
`)

export const usePayWithPurchaseOrder = () => {
	const [payWithPurchaseOrder] = useMutation(payWithPurchaseOrderMutation, {
		client: apolloClientNewServer
	})

	return {
		payWithPurchaseOrder
	}
}
