import { gql, useQuery } from '@apollo/client'
import { useMemo } from 'react'
import { apolloClientNewServer } from 'utils/clients/apolloClientNewServer'

export const GET_HOLIDAYS = gql`
	query Holidays($dateOrderPlaced: DateTime) {
		holidays(dateOrderPlaced: $dateOrderPlaced) {
			__typename
			sfid
			name
			activityDate
		}
	}
`

export const useHolidays = (fromDate?: Date) => {
	const queryDate = useMemo(() => {
		if (!fromDate) {
			return new Date()
		} else {
			return fromDate
		}
	}, [])

	const { loading, error, data } = useQuery(GET_HOLIDAYS, {
		variables: { dateOrderPlaced: queryDate },
		client: apolloClientNewServer
	})

	const holidays = useMemo(() => data ? data.holidays : error ? [] : undefined, [data, error])

	return { holidays, loading, error }
}
