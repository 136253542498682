import { Dispatch, SetStateAction } from 'react'
import Link from 'next/link'
import { Icon } from 'ui'
import { faArrowRight } from '@fortawesome/pro-regular-svg-icons'
import { getBaseUrl, linkResolver } from 'utils'
import type { GlobalDocument, NavDropdownLinkGroupDocument } from 'types/types.generated'

type MobileDrawerProps = {
	dropdown: NavDropdownLinkGroupDocument
	setIsMobileNavOpen: Dispatch<SetStateAction<boolean>>
	setOpenNavDrawer: Dispatch<SetStateAction<number>>
	navTextContrast?: 'light' | 'dark'
	analyticalClickFactory?: (value: string) => () => void
}
export default function MobileNavOptions({
	dropdown,
	setIsMobileNavOpen,
	setOpenNavDrawer,
	navTextContrast,
	analyticalClickFactory
}: MobileDrawerProps) {
	const {
		button_label,
		link: linkData,
		open_dropdown_drawer,
		link_groups
	} = dropdown.dropdown.data || {}

	return button_label?.[0]?.text ? (
		open_dropdown_drawer ? (
			<button
				className={`font-medium text-2xl ${
					navTextContrast === 'light' ? 'text-white' : 'text-black-rt'
				}`}
				onClick={() => {
					setOpenNavDrawer(dropdown.dropdown.id)
					if (analyticalClickFactory) {
						analyticalClickFactory(button_label[0].text)()
					}
				}}>
				{button_label[0].text}
				<Icon className="text-lg text-g-500 ml-3" icon={faArrowRight} />
			</button>
		) : (
			<Link
				href={getBaseUrl() + linkResolver(link_groups?.[0]?.links?.data?.view_all_link)}
				onClick={() => {
					setOpenNavDrawer(null)
					setIsMobileNavOpen(false)
					if (analyticalClickFactory) {
						analyticalClickFactory(button_label[0].text)()
					}
				}}
				className={`font-medium text-2xl ${
					navTextContrast === 'light' ? 'text-white' : 'text-black-rt'
				}`}>
				{button_label[0].text}
			</Link>
		)
	) : null
}
