import { faArrowLeft, faArrowRight } from '@fortawesome/pro-regular-svg-icons'
import { Icon } from 'ui'
import clsx from 'clsx'

type ScrollButtonsProps = {
	nextSlide: () => void
	previousSlide: () => void
	isTransitioning?: boolean
	className?: string
}
export default function ScrollButtons({
	nextSlide,
	previousSlide,
	isTransitioning,
	className
}: ScrollButtonsProps) {
	const buttonClass =
		'flex items-center justify-center h-10 w-10 bg-white border border-g-100 rounded-full hover:bg-gray-50 transition duration-300 ease-in-out'

	const iconClass = 'w-3.5 h-3.5 fill-gray-900 transition duration-300 ease-in-out'

	return (
		<div className={clsx('flex gap-2.5 mt-auto pb-12 z-10', className)}>
			<button
				className={buttonClass}
				onClick={previousSlide}
				disabled={isTransitioning}
				aria-label="Previous Slide">
				<Icon icon={faArrowLeft} className={iconClass} />
			</button>

			<button
				className={buttonClass}
				onClick={nextSlide}
				disabled={isTransitioning}
				aria-label="Next Slide">
				<Icon icon={faArrowRight} className={iconClass} />
			</button>
		</div>
	)
}
