import { useState } from 'react'
import algoliaSearch from 'algoliasearch/lite'
import { InstantSearch } from 'react-instantsearch-dom'
import SearchBar from './SearchBar'
import Hits from './Hits'
import clsx from 'clsx'

const searchClient = algoliaSearch(
	process.env.NEXT_PUBLIC_ALGOLIA_APP_ID,
	process.env.NEXT_PUBLIC_ALGOLIA_SEARCH_API_KEY
)

const indexName = process.env.NEXT_PUBLIC_ALGOLIA_SEARCH_INDEX_NAME

interface Props {
	setSearchOpen?: (value: boolean) => void
	inlineSearch?: boolean
	className?: string
}

const Search = ({ setSearchOpen, inlineSearch, className }: Props ) => {
	const [isResultsOpen, setIsResultsOpen] = useState(false)

	return searchClient && indexName ? (
		<InstantSearch searchClient={searchClient} indexName={indexName}>
			<div className={clsx('p-4 rounded-[40px]', {
				['bg-g-50 border border-g-200 max-h-[500px] overflow-y-auto']:inlineSearch && isResultsOpen,
				['bg-g-50/20']: !inlineSearch,
				['max-md:w-full md:min-w-[709px]']: inlineSearch
			}, className)}>
				<SearchBar
					isResultsOpen={isResultsOpen}
					setIsResultsOpen={setIsResultsOpen}
					className="outline-none ring-0"
					width={968} 
				/>
				<Hits 
					isResultsOpen={isResultsOpen} 
					setIsResultsOpen={setIsResultsOpen} 
					setSearchOpen={setSearchOpen}
					inlineSearch={inlineSearch}
				/>
			</div>
		</InstantSearch>
	) : null
}

export default Search
