import dayjs from 'dayjs'
import type { Holiday } from 'types/graphql/app/api/__generated__/graphql'

// Validate if date is a holiday
export default function findHoliday(date: Date, holidays: Holiday[]): Holiday | undefined {
	return holidays?.find((holiday) => {
		const dateToCompare = dayjs(dayjs(date).format('YYYY-MM-DD'))
		const holidayDate = dayjs(holiday.activityDate).format('YYYY-MM-DD')

		return dateToCompare.isSame(holidayDate, 'date')
	})
}
