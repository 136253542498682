import { Control, useController } from 'react-hook-form'
import { PhoneInput } from 'react-international-phone'
import 'react-international-phone/style.css'
import { PhoneNumberUtil } from 'google-libphonenumber'
import clsx from 'clsx'
import { FormEvent } from 'react'

const phoneUtil = PhoneNumberUtil.getInstance()

const isPhoneValid = (phone: string) => {
	try {
		return phoneUtil.isValidNumber(phoneUtil.parseAndKeepRawInput(phone))
	} catch (error) {
		return false
	}
}

type Props = {
	inputClassName?: string
	name: string
	control?: Control<any>
	label?: string
	defaultValue?: string
	disabled?: boolean
	required?: boolean
	className?: string
	errored?: boolean
	onChange?: (e: FormEvent<HTMLInputElement> | string) => void
}

export default function InputPhone({
	inputClassName,
	name,
	control,
	label,
	defaultValue,
	disabled,
	required,
	className,
	errored,
	onChange
}: Readonly<Props>) {
	const {
		field: { onChange: handleChange, onBlur, name: fieldName, ref, value }
	} = useController({
		name,
		control,
		defaultValue: defaultValue ?? '',
		rules: { required: 'Phone number required' }
	})

	const characterCount = value ? value.length : 0
	const isValid = isPhoneValid(value)

	return (
		<div className={clsx('input-phone flex flex-col w-full items-start', className ?? '')}>
			<div
				className={clsx(
					'bg-white border rounded-lg border-g-200 w-full focus:border-black-rt',
					`input overflow-visible ${className ?? ''} 
					${value ? 'input--reset' : ''} 
					${!label ? 'input--noLabel' : ''}
					${(!isValid && characterCount > 2 && required) || errored ? 'input--error !border-error' : ''}
				`
				)}>
				{label ? (
					<label htmlFor={name} className="ml-12">
						{label}
					</label>
				) : null}

				<PhoneInput
					inputClassName={clsx(
						inputClassName ?? '',
						{
							'opacity-0 focus:opacity-100': !value
						},
						disabled && 'disabled'
					)}
					defaultCountry="us"
					onChange={(e) => {
						handleChange(e)
						onChange?.(e)
					}}
					value={defaultValue || ''}
					onBlur={onBlur}
					ref={ref}
					name={fieldName}
					disabled={disabled}
					required={required}
					disableDialCodeAndPrefix={true}
				/>
			</div>
			{characterCount > 2 && !isValid && required && (
				<div className="input-error-message">Phone number is not valid</div>
			)}
		</div>
	)
}
