import * as prismic from '@prismicio/client'
import { enableAutoPreviews } from '@prismicio/next'
import { AllDocumentTypes } from '../types/types.generated'

export const repositoryName = 'real-thread'

// Cache for storing Prismic client instances
let clientInstance: prismic.Client<AllDocumentTypes> | null = null
let previewClientInstance: prismic.Client<AllDocumentTypes> | null = null

// Cache for storing document responses
const documentCache = new Map<
	string,
	{
		data: any
		timestamp: number
		expiresIn: number
	}
>()

// Cache duration in milliseconds (5 minutes default)
const DEFAULT_CACHE_DURATION = 5 * 60 * 1000

/**
 * Creates or returns a cached Prismic client instance
 * @param options - Client configuration options
 * @returns Prismic client instance
 */
const prismicClient = (options = {}) => {
	// Check if we should use preview client
	const isPreview = options?.hasOwnProperty('req') || options?.hasOwnProperty('previewData')

	// Return cached instance if available
	if (isPreview && previewClientInstance) return previewClientInstance
	if (!isPreview && clientInstance) return clientInstance

	// Create new client instance
	const client = prismic.createClient<AllDocumentTypes>(repositoryName, {
		...options
	})

	// Enable previews if needed
	if (isPreview) {
		enableAutoPreviews({
			client,
			...options
		})
		previewClientInstance = client
	} else {
		clientInstance = client
	}

	// Add caching wrapper methods
	const originalGetByType = client.getByType.bind(client)
	const originalGetSingle = client.getSingle.bind(client)
	const originalGetByUID = client.getByUID.bind(client)
	const originalGetByID = client.getByID.bind(client)

	// Wrap getByType with caching
	client.getByType = async (type: string, options?: any) => {
		const cacheKey = `getByType:${type}:${JSON.stringify(options)}`

		// Skip cache in preview mode
		if (isPreview) return originalGetByType(type, options)

		const cached = documentCache.get(cacheKey)
		if (cached && Date.now() - cached.timestamp < cached.expiresIn) {
			return cached.data
		}

		const response = await originalGetByType(type, options)
		documentCache.set(cacheKey, {
			data: response,
			timestamp: Date.now(),
			expiresIn: DEFAULT_CACHE_DURATION
		})

		return response
	}

	// Wrap getSingle with caching
	client.getSingle = async (type: string, options?: any) => {
		const cacheKey = `getSingle:${type}:${JSON.stringify(options)}`

		// Skip cache in preview mode
		if (isPreview) return originalGetSingle(type, options)

		const cached = documentCache.get(cacheKey)
		if (cached && Date.now() - cached.timestamp < cached.expiresIn) {
			return cached.data
		}

		const response = await originalGetSingle(type, options)
		documentCache.set(cacheKey, {
			data: response,
			timestamp: Date.now(),
			expiresIn: DEFAULT_CACHE_DURATION
		})

		return response
	}

	// Wrap getByUID with caching
	client.getByUID = async (type: string, uid: string, options?: any) => {
		const cacheKey = `getByUID:${type}:${uid}:${JSON.stringify(options)}`

		// Skip cache in preview mode
		if (isPreview) return originalGetByUID(type, uid, options)

		const cached = documentCache.get(cacheKey)
		if (cached && Date.now() - cached.timestamp < cached.expiresIn) {
			return cached.data
		}

		const response = await originalGetByUID(type, uid, options)
		documentCache.set(cacheKey, {
			data: response,
			timestamp: Date.now(),
			expiresIn: DEFAULT_CACHE_DURATION
		})

		return response
	}

	// Wrap getByID with caching
	client.getByID = async (id: string, options?: any) => {
		const cacheKey = `getByID:${id}:${JSON.stringify(options)}`

		// Skip cache in preview mode
		if (isPreview) return originalGetByID(id, options)

		const cached = documentCache.get(cacheKey)
		if (cached && Date.now() - cached.timestamp < cached.expiresIn) {
			return cached.data
		}

		const response = await originalGetByID(id, options)
		documentCache.set(cacheKey, {
			data: response,
			timestamp: Date.now(),
			expiresIn: DEFAULT_CACHE_DURATION
		})

		return response
	}

	return client
}

export default prismicClient
