import { sanitizeObj } from 'utils/sanitize'

declare global {
	interface Window {
		grecaptcha: any
	}
}

interface FormSubmissionOptions {
	endpoint: string
	useRecaptcha?: boolean
	onSuccess?: (data: any) => void
	onError?: (error: string) => void
}

export const submitForm = async (formData: any, options: FormSubmissionOptions) => {
	const { endpoint, useRecaptcha = false, onSuccess, onError } = options
	const sanitizedData = sanitizeObj(formData)

	try {
		if (useRecaptcha) {
			const token = await new Promise((resolve, reject) => {
				window.grecaptcha.ready(function () {
					window.grecaptcha
						.execute('6Les66kUAAAAANyLrgkl7iuN4JUpNlB5upaMovI4', { action: 'submit' })
						.then(resolve)
						.catch(reject)
				})
			})

			sanitizedData['g-recaptcha-response'] = token
			sanitizedData['g-recaptcha-version'] = 'v3'
		}

		const response = await fetch(endpoint, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json'
			},
			body: JSON.stringify(sanitizedData)
		})

		if (!response.ok) {
			throw new Error('Network response was not ok')
		}

		onSuccess?.(sanitizedData)
		return sanitizedData
	} catch (error) {
		const errorMessage = 'Error submitting form. Please try again.'
		onError?.(errorMessage)
		throw new Error(errorMessage)
	}
}
