import { gql } from '@apollo/client'

import apolloClient from 'auth/client/apollo/apolloClient'

export const getStyleCategoriesDocument = gql`
	query getStyleCategories {
		styleCategories {
			sfid
			customCode
			headerH1
			headerH2
			headerImage
			name
			numberOfSubcategories
			pageDescription
			pageTitle
			slug
			sort
			url
			styleSubcategories {
				sfid
				customCode
				name
				numberOfStyles
				pageDescription
				pageTitle
				slug
				sort
				url
				__typename
			}
			__typename
		}
	}
`

export const getStyleCategories = async () => {
	return apolloClient
		.query({
			query: getStyleCategoriesDocument
		})
		.catch(() => null)
}
