import Link from 'next/link'
import { Icon } from 'ui'
import { faArrowRight } from '@fortawesome/pro-solid-svg-icons'
import { getBaseUrl } from 'utils'
import dynamic from 'next/dynamic'

type ErrorPageProps = { errorNum: number }

const SqueegeeEffect = dynamic(() => import('ui/SqueegeeEffect'), {
	ssr: false
})

export default function ErrorPage({ errorNum }: ErrorPageProps) {
	return (
		<section className="relative -mt-[80px] h-screen max-md:max-h-[700px] md:min-h-[800px]">
			<video
				src="https://rstr.in/real-thread/marketing/_PuklnlbfvF"
				className="absolute inset-0 z-0 object-cover object-top w-full mx-auto h-full"
				autoPlay
				playsInline
				loop
				muted
				controls={false}
			/>
			<div className="relative flex flex-col items-center pt-44 md:pt-56 3xl:pt-80 z-10">
				<span className="brushstroke-2 font-medium ">{errorNum} Error</span>
				<h1 className="desktop-h1 mt-6">Oh, shirt!</h1>
				<p className="desktop-body-lg text-g-500 text-center max-w-sm mt-8">
					Being lost can be frustrating. Let Threads keep you company while you collect yourself.
				</p>
				<Link href={`${getBaseUrl()}/`} className="button button-dark md:button-lg mt-5">
					Return home
					<Icon icon={faArrowRight} className="right-arrow-hover mb-1 text-sm" />
				</Link>
			</div>
			<SqueegeeEffect color={'white'} squished bottomAligned />
		</section>
	)
}
