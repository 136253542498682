import { Dispatch, SetStateAction, useEffect } from 'react'
import { useCookie } from 'react-use'
import Image from 'next/image'
import { ImageJsonLd } from 'next-seo'

import { Modal } from 'ui/modals'
import { RichText } from '.'
import { LeadGenForm } from 'ui/form'
import Form from 'ui/form/Form'
import { useSession } from 'auth'
import type { PromotionPopupModalDocument } from 'types/types.generated'

type PrismicPromotionalPopupProps = {
	open: boolean
	setOpen: Dispatch<SetStateAction<boolean>>
	modalData: PromotionPopupModalDocument
}

export default function PrismicPromotionalPopup({
	open,
	setOpen,
	modalData
}: PrismicPromotionalPopupProps) {
	const [value, updateCookie] = useCookie(modalData.cookie_name)
	const [returningVisitorCookie] = useCookie('returning_visitor')

	const { isLoggedIn } = useSession()
	// Show modal if cookie doesn't exist and visitor matches criteria
	useEffect(() => {
		if (
			!value &&
			(modalData.visitors === 'All' ||
				// if visitor is returning and logged in or returning visitor cookie is true
				(modalData.visitors === 'Returning' && (isLoggedIn || returningVisitorCookie === 'true')) ||
				// if visitor is new and not logged in and returning visitor cookie is false
				(modalData.visitors === 'Unique' && !isLoggedIn && returningVisitorCookie !== 'true'))
		) {
			setTimeout(() => {
				setOpen(true)
				// set cookie to show again after a certain time
				updateCookie('showed', {
					expires:
						modalData.show_again === 'One week'
							? new Date(new Date().getTime() + 7 * 24 * 60 * 60 * 1000)
							: modalData.show_again === 'Two weeks'
							? new Date(new Date().getTime() + 14 * 24 * 60 * 60 * 1000)
							: modalData.show_again === 'One month'
							? new Date(new Date().getTime() + 30 * 24 * 60 * 60 * 1000)
							: new Date(new Date().getTime() + 60 * 24 * 60 * 60 * 1000)
				})
			}, modalData.time_on_page * 1000)
		} else {
			setOpen(false)
		}
	}, [isLoggedIn, modalData, returningVisitorCookie])

	return (
		<Modal
			isOpen={open}
			setIsOpen={setOpen}
			panelClassName="promotional-modal modal--wide flex max-md:flex-col p-0 text-left"
			aria-label="Promotional popup">
			{/* Main content */}
			<div className="flex max-md:flex-col w-full">
				{/* Image */}
				<div className="relative md:w-1/3 md:h-full">
					{modalData?.modal_image && Object.keys(modalData.modal_image).length > 0 && (
						<>
							<Image
								src={modalData.modal_image.url}
								alt={modalData.modal_image.alt}
								fill
								className="object-cover"
								sizes="(max-width: 768px) 90vw, 400px"
								quality={70}
								priority
							/>
							<ImageJsonLd
								contentUrl={modalData.modal_image.url}
								images={[modalData.modal_image]}
								creator="Real Thread"
								creditText="Real Thread"
							/>
						</>
					)}
				</div>

				{/* Form Side */}
				<div className="md:w-2/3 md:overflow-y-scroll max-md:px-4 px-20 py-10 md:py-20">
					{modalData?.modal_rich_text && (
						<RichText className="prose" content={modalData.modal_rich_text} />
					)}

					{modalData?.modal_form &&
						(modalData.modal_form.type === 'lead_gen_form' ? (
							<LeadGenForm form={modalData.modal_form} />
						) : (
							<Form form={modalData.modal_form} alignButtonOnLeft />
						))}
					{modalData?.modal_note_rich_text_below_form && (
						<RichText
							className="prose mt-10 text-center sm:w-96 mx-auto"
							content={modalData.modal_note_rich_text_below_form}
						/>
					)}
				</div>
			</div>
		</Modal>
	)
}
