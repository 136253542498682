export default function getBaseUrl() {
	let url =
		process?.env?.NEXT_PUBLIC_SITE_URL ?? // Set this to your site URL in production env.
		process?.env?.NEXT_PUBLIC_VERCEL_URL ?? // Automatically set by Vercel.
		'http://localhost:3333'

	// Make sure to include `https://` when not localhost.
	url = url.includes('http') ? url : `https://${url}`

	// Make sure to exclude a trailing `/`.
	url = url.endsWith('/') ? url.substring(0, -1) : url
	return url
}
