import { connectSearchBox } from 'react-instantsearch-dom'
import { faMagnifyingGlass } from '@fortawesome/pro-regular-svg-icons'
import { Icon } from 'ui'
import { ChangeEvent, useRef, useEffect } from 'react'

interface Props {
	refine?: (search: string) => void
	setIsResultsOpen?: (value: boolean) => void
	width: number
	className?: string
}

const SearchBar = ({ refine, setIsResultsOpen, width, className }: Props) => {
	const debounceRef = useRef<ReturnType<typeof setTimeout> | null>(null)

	// Debouced search input
	const handleOnSearchChange = async (event: ChangeEvent<HTMLInputElement>) => {
		const search = event.target.value

		if (typeof search === 'string' && search.length > 0) {
			if (debounceRef.current !== null) {
				clearTimeout(debounceRef.current)
			}

			debounceRef.current = setTimeout(() => {
				refine(search)
			}, 300)

			setIsResultsOpen(true)
		} else {
			setIsResultsOpen(false)
		}
	}

	const inputRef = useRef<HTMLInputElement>(null)
	useEffect(() => {
		inputRef.current.focus()
	}, [])

	return (
		<label
			className={`search-bar border border-g-400 w-[${width}px] rounded-full pt-1 pl-4 bg-white text-black-rt flex items-center ${className}`}>
			<Icon icon={faMagnifyingGlass} className="pb-1 text-black-rt" />
			<input
				ref={inputRef}
				id="search"
				className="border-none w-full bg-transparent focus:ring-0 placeholder:text-g-300"
				type="text"
				placeholder="Search products, services, articles, and resources"
				onChange={handleOnSearchChange}
				autoComplete="off"
				autoFocus
			/>
		</label>
	)
}

export default connectSearchBox(SearchBar)
