import { faSpinnerThird } from '@fortawesome/pro-regular-svg-icons'

import Icon from './Icon'

interface Props {
	className?: string
}

export default function Loading({ className }: Props) {
	return (
		<div className={`flex justify-center items-center ${className ?? ''}`}>
			<Icon icon={faSpinnerThird} className="fa-sharp fa-spin ml-8 text-3xl mt-1.5" />
		</div>
	)
}
