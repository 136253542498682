import { faPhone } from '@fortawesome/pro-light-svg-icons'
import Icon from 'ui/Icon'

type OrderSummaryProps = {
	children: React.ReactNode
	className?: string
}

export default function OrderSummary({ children, className }: OrderSummaryProps) {
	return (
		<div
			className={`order-summary @container 
			relative md:sticky md:top-6 md:bottom-6 md:right-6 xl:right-10 
			md:w-[350px] xl:w-[432px] md:ml-6 lg:ml-10
			md:h-[845px] md:max-h-[calc(100vh_-_50px)] md:overflow-y-auto
			max-md:h-max 
			flex flex-col justify-between shrink-0
			z-10 bg-warm-gray-100 flex flex-col justify-between rounded-lg
			bg-gradient-1
			${className}
		`}>
			{/* Help Box for Printed Reciept */}
			<div className="help-box hidden border rounded-lg py-10 px-12 h-fit w-1/2">
				<p className="font-serif text-xl mb-4">Need Help?</p>
				<p>Have Questions about this order?</p>
				<p className="mb-4">Contact us anytime.</p>
				<span>
					<Icon icon={faPhone} />
					<span className="font-medium ml-2">407.392.9462</span>
				</span>
			</div>

			{/* Order Summary Content */}
			<div className="order-summary-content px-6 py-10 @sm:px-10 flex flex-col h-full print:max-w-sm print:ml-auto">
				{children}
			</div>
		</div>
	)
}
