import { Dispatch, SetStateAction, useEffect, useState } from 'react'

import { countries, countriesIds } from '../../utils/forms/shipping'
import { Input, Select, FormOptions, InputPhone, Icon } from 'ui'
import { useGlobalData } from 'hooks'
import { Control, useFormContext } from 'react-hook-form'
import clsx from 'clsx'
import ShippingFormField from './ShippingFormFields'
import { Fulfillment } from 'types/graphql/app/api/__generated__/graphql'
import Image from 'next/image'
import { faArrowTurnRight } from '@fortawesome/pro-regular-svg-icons'

type Props = {
	isCheckout: boolean
	shippingAddress?: Fulfillment
	control: Control
	allowsPickup: boolean
	showShipping: boolean
	setShowShipping: Dispatch<SetStateAction<boolean>>
	setShippingAlternativeOption?: Dispatch<SetStateAction<string>>
	shippingAlternativeOption?: string
	isAdding?: boolean
	showFulfillment?: boolean
	setShowFulfillment?: Dispatch<SetStateAction<boolean>>
	countryCode?: string
}

export default function Form({
	isCheckout,
	shippingAddress,
	control,
	allowsPickup,
	showShipping,
	setShowShipping,
	setShippingAlternativeOption,
	shippingAlternativeOption,
	isAdding,
	showFulfillment,
	setShowFulfillment,
	countryCode
}: Readonly<Props>) {
	const { data } = useGlobalData()

	const currentCompleteCountry = countries.find((country) => country.code === countryCode)

	const [showMultipleCopy, setShowMultipleCopy] = useState(false)
	const [showIndividualCopy, setShowIndividualCopy] = useState(false)

	const [currentCountry, setCurrentCountry] = useState(
		currentCompleteCountry ? currentCompleteCountry.id : '24'
	)

	const { setValue } = useFormContext()

	const handleSelectedCountry = (event) => {
		setValue('addressCountryCode', countries[event.target.value].code)
		setCurrentCountry(event.target.value)
	}

	useEffect(() => {
		if (shippingAlternativeOption === 'Multiple addresses') {
			setShowMultipleCopy(true)
		} else if (shippingAlternativeOption === 'Individual people') {
			setShowIndividualCopy(true)
		} else if (shippingAddress?.recordType?.sfid === '012E00000005nXyIAI') {
			setShowShipping(false)
		} else if (shippingAddress?.recordType?.sfid === '0120y000000IBMWAA4') {
			setShowFulfillment(true)
			setShowShipping(false)
		} else {
			setShowShipping(true)
		}
	}, [shippingAlternativeOption])

	useEffect(() => {
		if (shippingAddress?.addressCountryCode) {
			setValue('addressCountryCode', shippingAddress?.addressCountryCode)
		} else {
			setValue('addressCountryCode', 'US')
		}
	}, [shippingAddress?.addressCountryCode])

	return (
		<div className={clsx(isCheckout ? 'overflow-hidden' : '', 'space-y-8')}>
			{/* Options for single address, multiple or pickup*/}
			{(shippingAddress?.recordType?.sfid === '012E00000005nXyIAI' ||
				shippingAddress?.recordType?.sfid === '0120y000000IBMWAA4') && (
				<>
					<FormOptions
						allowsPickup={allowsPickup}
						showShipping={showShipping}
						showFulfillment={showFulfillment}
						setShowShipping={setShowShipping}
						setShowMultipleCopy={setShowMultipleCopy}
						setShowIndividualCopy={setShowIndividualCopy}
						shippingAlternativeOption={shippingAlternativeOption}
					/>

					<hr className="border-g-200" />
				</>
			)}

			{showMultipleCopy && (
				<div className="flex flex-col">
					<p className="text-sm font-sans leading-6 text-g-500 mb-4">
						Need to ship to more than 1 address? No problem. When we send you your mockup for final
						approval, you can add each shipping address at that time. No need to add any of the
						addresses now, simply click continue below.
					</p>
					<button
						type="submit"
						onClick={() => {
							setShippingAlternativeOption('Multiple addresses')
						}}
						className="button button-dark button-sm w-fit">
						Continue
					</button>
				</div>
			)}

			{showIndividualCopy && (
				<div className="flex flex-col">
					<p className="text-sm font-sans leading-6 text-g-500 mb-4">
						Want to ship to individual people? No problem. Just download and complete our{' '}
						<a href={data?.download_shipping_template?.url ?? '#'} className="underline">
							shipping template
						</a>
						. Your account manager will be in touch as they review your order and will discuss
						shipping options with you.
					</p>
					<button
						type="submit"
						onClick={() => {
							setShippingAlternativeOption('Individual people')
						}}
						className="button button-dark button-sm w-fit">
						Continue
					</button>
				</div>
			)}

			{showShipping && !showIndividualCopy && !showMultipleCopy ? (
				<div className="grid grid-cols-8 gap-4 items-start">
					<Input
						className="col-span-8 sm:col-span-4"
						name="company"
						label="Company/Name"
						defaultValue={shippingAddress ? shippingAddress['company'] : null}
						control={control}
						rules={{ required: 'Company/Name is required' }}
					/>
					<Input
						className="col-span-8 sm:col-span-4"
						name="attn"
						label="Attention (optional)"
						defaultValue={
							shippingAddress &&
							shippingAddress['attn'] !== 'Multiple addresses' &&
							shippingAddress['attn'] !== 'Individual people'
								? shippingAddress['attn']
								: null
						}
						control={control}
					/>

					<Select
						className="col-span-8 sm:col-span-4"
						name="addressCountryCode"
						label="Country"
						value={currentCountry}
						control={control}
						onChange={handleSelectedCountry}
						rules={{ required: false }}>
						{countriesIds.map((country) => (
							<option key={country.name} value={country.id}>
								{country.name}
							</option>
						))}
					</Select>

					<InputPhone
						name="phone"
						control={control}
						defaultValue={shippingAddress ? shippingAddress['phone'] : null}
						className="col-span-8 sm:col-span-4"
						label="Phone"
						required
					/>

					{countries[currentCountry]?.fields.map((field) => (
						<ShippingFormField
							key={field.name}
							field={field}
							shippingAddress={shippingAddress}
							control={control}
						/>
					))}

					{isAdding && (
						<div className="col-span-8 text-sm text-g-500 text-left mt-8">
							<p>Need to ship to more than 5 addresses?</p>
							Let your Account Manager know and we'll make it happen.
						</div>
					)}
				</div>
			) : !showFulfillment && !showShipping ? (
				<div className="flex items-center border border-g-200 rounded-lg py-3 px-4 justify-between">
					<div className="flex flex-col text-left">
						<p className="desktop-h5"> Real Thread - {shippingAddress?.fromAddressCity}</p>
						<div className="desktop-body-sm flex flex-col text-g-500 mt-1">
							<span>{shippingAddress.fromAddressStreet}</span>
							<span>
								{shippingAddress?.fromAddressCity}, {shippingAddress?.fromAddressStateCode}{' '}
								{shippingAddress?.fromAddressPostalCode}
							</span>
							<span>(407) 679-3895</span>
						</div>
					</div>
					<a
						className="button button-dark cursor-pointer"
						type="submit"
						href={`https://www.google.com/maps/search/?api=1&query=${shippingAddress?.fromAddressStreet}, ${shippingAddress?.fromAddressCity}, ${shippingAddress?.fromAddressPostalCode}`}
						target="_blank">
						<Icon icon={faArrowTurnRight} className="mr-2 -mt-1 text-xs" />
						Google Maps
					</a>
				</div>
			) : showFulfillment && !showShipping ? (
				<div className="flex flex-col items-center">
					<div className="relative w-full max-w-80 h-52 mt-2">
						<Image
							src="https://rstr.in/real-thread/marketing/irPRxQPOOAe"
							fill
							alt=""
							className="object-contain"
						/>
					</div>
					<p className="desktop-h4 text-g-500 max-w-md">
						Your order will be added to inventory & ready to ship out as needed.
					</p>
				</div>
			) : null}
		</div>
	)
}
