import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { useRouter } from 'next/router'

import { Icon, Loading } from 'ui'

import { VerificationFlow } from '@ory/client'
import { getCsrfToken, getOryVerificationFlow, sendVerificationEmail, useSession } from 'auth'
import { useUserInfo, useCreateUser } from 'hooks'
import clsx from 'clsx'
import { faArrowRight } from '@fortawesome/pro-solid-svg-icons'

type Props = {
	isFromCheckout?: boolean
	setVerificationEmailSent?: Dispatch<SetStateAction<boolean>>
}

const EmailNotVerified = ({ isFromCheckout, setVerificationEmailSent }: Props) => {
	const { userInfo, isLoading } = useUserInfo()
	const { createUser } = useCreateUser()

	const [csrfToken, setCsrfToken] = useState<string>(null)
	const { verifyEmail, currentUser } = useSession()

	const router = useRouter()

	const [verificationFlow, setVerificationFlow] = useState<VerificationFlow>()

	useEffect(() => {
		const verifiedAddress = currentUser?.verifiable_addresses[0]?.verified
		if (currentUser && verifiedAddress) {
			router.push('/settings')
		}
	}, [currentUser, router])

	useEffect(() => {
		getOryVerificationFlow(verificationFlow, setVerificationFlow, router)
		const token = getCsrfToken(verificationFlow)
		setCsrfToken(token)
	}, [router, verificationFlow])

	const handleSendVerificationEmail = () => {
		sendVerificationEmail(
			csrfToken,
			isFromCheckout ? currentUser.traits : userInfo ? userInfo : currentUser.traits,
			verificationFlow,
			setVerificationFlow,
			router,
			verifyEmail
		)
		if (isFromCheckout) {
			setVerificationEmailSent(true)
		}
		if (!userInfo && currentUser) {
			createUser({
				variables: {
					email: currentUser.traits.email,
					firstName: currentUser.traits.firstName,
					lastName: currentUser.traits.lastName,
					authMethod: 'Google'
				}
			})
		}
	}

	if (isLoading) {
		return <Loading />
	}

	return (
		<div className={clsx(isFromCheckout ? 'md:w-full' : 'md:w2/6', 'gap-4')}>
			<p>To continue using our services, please verify your email address.</p>
			<div className="flex flex-col gap-4 items-center">
				<button
					className="button button-dark button-lg mt-20 w-full"
					onClick={handleSendVerificationEmail}>
					Send verification email
					<Icon icon={faArrowRight} className="right-arrow-hover mb-1" />
				</button>
			</div>
		</div>
	)
}

export default EmailNotVerified
