import { getLocationOrigin } from 'next/dist/shared/lib/utils'
import { NextRouter } from 'next/router'
import { Dispatch, SetStateAction } from 'react'
import {
	LoginFlow,
	RecoveryFlow,
	RegistrationFlow,
	SettingsFlow,
	UpdateRecoveryFlowBody,
	UpdateSettingsFlowBody,
	UpdateVerificationFlowBody,
	VerificationFlow
} from '@ory/client'

import { ory } from '../client/ory'

import { LOGIN, RECOVER_ACCOUNT, VERIFY_EMAIL } from '../utils/routes'
import { handleFlowError } from './errors'
import { RegistrationFlowData } from '../types/Session.type'

type StringType = string | string[] | undefined

export const getCsrfToken = (
	flow: LoginFlow | RegistrationFlow | SettingsFlow | VerificationFlow
) => {
	const csrfTokenElement = flow?.ui?.nodes.find(
		(node) => 'name' in node.attributes && node.attributes.name === 'csrf_token'
	)

	return csrfTokenElement && 'value' in csrfTokenElement.attributes
		? csrfTokenElement.attributes.value
		: null
}

export const getOryRegistrationFlow = async (
	flow: RegistrationFlow | null,
	flowId: StringType,
	setFlow: Dispatch<SetStateAction<RegistrationFlow>>,
	router: NextRouter,
	setLoading: Dispatch<SetStateAction<boolean>>
) => {
	setLoading(true)
	// If the router is not ready yet, or we already have a flow, do nothing.
	if (flow) {
		setLoading(false)
		return
	}

	try {
		// If ?flow=.. was in the URL, we fetch it
		if (flowId) {
			let { data: flowData }: RegistrationFlowData = await ory.getRegistrationFlow({
				id: String(flowId)
			})

			if (flowData.ui?.messages?.length > 0 && flowData.ui?.messages[0].id === 4000007) {
				await router.push(
					{
						pathname: LOGIN,
						query: { registerFlowId: flowId, email: flowData.ui?.nodes[4].attributes?.value }
					},
					LOGIN
				)
			}
			// We received the flow - let's use its data and render the form!
			setFlow(flowData)
			setLoading(false)
			return
		}

		// Otherwise we initialize it
		const { data: flowData } = await ory
			.createBrowserRegistrationFlow
			// TODO get current URL
			// returnTo ? `http://localhost:3339${String(returnTo)}` : undefined
			()

		setFlow(flowData)
	} catch (error) {
		await handleFlowError(error, router, 'create-account', setFlow)
	} finally {
		setLoading(false)
	}
}

export const getOryLoginFlow = async (
	flow: LoginFlow | null,
	flowId: StringType,
	setFlow: Dispatch<SetStateAction<LoginFlow>>,
	router: NextRouter,
	refresh: boolean,
	returnTo: StringType,
	authorizationAssuranceLevel: StringType
) => {
	// If the router is not ready yet, or we already have a flow, do nothing.
	if (flow) {
		return
	}

	try {
		// If ?flow=.. was in the URL, we fetch it
		if (flowId) {
			const { data: flowData } = await ory.getLoginFlow({ id: String(flowId) })

			setFlow(flowData)
			return
		}

		const returnToFullUrl =
			returnTo && String(returnTo).charAt(0) === '/'
				? `${getLocationOrigin()}${String(returnTo)}`
				: undefined

		// Otherwise we initialize it
		const { data: flowData } = await ory.createBrowserLoginFlow({
			refresh: Boolean(refresh),
			aal: authorizationAssuranceLevel ? String(authorizationAssuranceLevel) : undefined,
			returnTo: returnToFullUrl ? String(returnToFullUrl) : undefined
		})

		setFlow(flowData)
	} catch (error) {
		handleFlowError(error, router, 'login', setFlow)
	}
}

export const getOrySettingsFlow = async (
	flowId: StringType,
	setFlow: Dispatch<SetStateAction<SettingsFlow>>,
	router: NextRouter,
	flow?: SettingsFlow | null
) => {
	if (flow) {
		return
	}
	try {
		// If ?flow=.. was in the URL, we fetch it
		if (flowId) {
			const { data: flowData } = await ory.getSettingsFlow({ id: String(flowId) })

			// We received the flow - let's use its data and render the form!
			setFlow(flowData)
			return flowData
		}

		// Otherwise we initialize it
		const { data: flowData } = await ory.createBrowserSettingsFlow()

		setFlow(flowData)
		return flowData
	} catch (error) {
		await handleFlowError(error, router, 'settings', setFlow)
		setFlow(null)
		return null
	}
}

export const getOryVerificationFlow = async (
	flow: LoginFlow | RegistrationFlow | SettingsFlow | VerificationFlow,
	setFlow: Dispatch<SetStateAction<VerificationFlow>>,
	router: NextRouter,
	flowId?: StringType
) => {
	if (flow && flowId && flow.id === flowId) {
		return
	}
	try {
		// If ?flow=.. was in the URL, we fetch it
		if (flowId) {
			const { data: flowData } = await ory.getVerificationFlow({ id: String(flowId) })

			// We received the flow - let's use its data and render the form!
			setFlow(flowData)
			return
		}

		// Otherwise we initialize it
		const { data: flowData } = await ory.createBrowserVerificationFlow()

		setFlow(flowData)
	} catch (error) {
		await handleFlowError(error, router, 'verify-email', setFlow)
	}
}

export const getOryRecoveryFlow = async (
	flow: VerificationFlow | null,
	flowId: StringType,
	setFlow: Dispatch<SetStateAction<RecoveryFlow>>,
	router: NextRouter
) => {
	if (flow) {
		return
	}

	try {
		// If ?flow=.. was in the URL, we fetch it
		if (flowId) {
			const { data: flowData } = await ory.getRecoveryFlow({ id: String(flowId) })

			// We received the flow - let's use its data and render the form!
			setFlow(flowData)
			return
		}

		// Otherwise we initialize it
		const { data: flowData } = await ory.createBrowserRecoveryFlow()

		setFlow(flowData)
	} catch (error) {
		await handleFlowError(error, router, 'recover-account', setFlow)
	}
}

export const sendVerificationEmail = async (
	csrfToken: string,
	userInfo: any,
	flow: any,
	setFlow: Dispatch<SetStateAction<VerificationFlow>>,
	router: NextRouter,
	verifyEmail: any
) => {
	const values: UpdateVerificationFlowBody = {
		csrf_token: csrfToken,
		email: userInfo.email,
		method: 'code'
	}

	const isFromCheckout = router.pathname === '/checkout'

	try {
		await verifyEmail(values, flow, isFromCheckout)
		if (router.pathname !== '/checkout') {
			router.push(`${VERIFY_EMAIL}?flow=${flow?.id}`)
		} else {
			router.push(`/checkout?flow=${flow?.id}`)
		}
		setFlow(null)
	} catch (error) {
		try {
			await handleFlowError(error, router, 'verify-email', setFlow)
		} catch (err) {
			// If the previous handler did not catch the error it's most likely a form validation error
			if (err.response?.status === 400) {
				setFlow(err.response?.data)
				return
			}

			return Promise.reject(err)
		}
	}
}

export const updateSettings = async (
	flow: SettingsFlow | null,
	setFlow: Dispatch<SetStateAction<SettingsFlow>>,
	updateAccount: any,
	values: UpdateSettingsFlowBody,
	router: NextRouter,
	reset?: any,
	setSuccess?: Dispatch<SetStateAction<boolean>>
) => {
	try {
		await updateAccount(values, flow)
		reset && reset()
		setFlow(null)
		if (setSuccess) {
			setSuccess(true)
		}
	} catch (error) {
		try {
			await handleFlowError(error, router, 'settings', setFlow)
		} catch (err) {
			// If the previous handler did not catch the error it's most likely a form validation error
			if (err.response?.status === 400) {
				setFlow(err.response?.data)
				return
			}

			return Promise.reject(err)
		}
	}
}

export const sendRecoveryEmail = async (
	values: UpdateRecoveryFlowBody,
	flow: VerificationFlow | null,
	setFlow: Dispatch<SetStateAction<VerificationFlow>>,
	router: NextRouter,
	forgotPassword: any
) => {
	try {
		await forgotPassword(values, flow, false)
		router.push(`${RECOVER_ACCOUNT}?flow=${flow?.id}`)
	} catch (error) {
		try {
			await handleFlowError(error, router, 'recover-account', setFlow)
		} catch (err) {
			// If the previous handler did not catch the error it's most likely a form validation error
			if (err.response?.status === 400) {
				setFlow(err.response?.data)
				return
			}

			return Promise.reject(err)
		}
	}
}
