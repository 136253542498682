import React, { forwardRef } from 'react'
import { sanitize } from 'isomorphic-dompurify'
import { useInView } from 'react-intersection-observer'

type VideoEmbedProps = {
	html: string
	className?: string
}

const VideoEmbed = forwardRef<HTMLDivElement, VideoEmbedProps>(({ html, className = '' }, ref) => {
	const { ref: inViewRef, inView } = useInView({
		triggerOnce: true // Only trigger once when entering viewport
	})

	// Combine the refs
	const setRefs = (element: HTMLDivElement) => {
		// Forward the ref from props
		if (typeof ref === 'function') {
			ref(element)
		} else if (ref) {
			ref.current = element
		}
		// Set the inView ref
		inViewRef(element)
	}

	return (
		<div ref={setRefs} className={className}>
			{inView && (
				<div
					dangerouslySetInnerHTML={{
						__html: sanitize(html, {
							ALLOWED_TAGS: ['iframe'],
							ALLOWED_ATTR: ['framebordPproer', 'allow', 'allowfullscreen', 'title', 'src']
						})
					}}
					className={className}
				/>
			)}
		</div>
	)
})

VideoEmbed.displayName = 'VideoEmbed'

export default VideoEmbed
