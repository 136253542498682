import React, { useEffect, useMemo, useState } from 'react'

import { useRouter } from 'next/router'
import Image from 'next/image'

import { Nav, Footer, PrismicPromotionalPopup } from 'ui'
import chat from 'ui/images/svg/chat.svg'
import { AnalyticalDataProvider } from 'analytics'
import { GlobalSearchProvider } from 'context'
import useScrollLock from 'hooks/useScrollLock'
import { useCookie } from 'react-use'
import Head from 'next/head'
import dynamic from 'next/dynamic'
import { getCanonicalUrl } from '@/lib/utils/url'
import type {
	GlobalDocument,
	PromotionPopupModalDocument,
	PageDocument
} from 'types/types.generated'

const getOrigin = () => {
	if (typeof window === 'undefined') return ''
	return window.location.origin
}

const ExitPreview = dynamic(() => import('ui/prismic/ExitPreview'), {
	ssr: false
})

const ModalSearch = dynamic(() => import('ui/modals/ModalSearch'), {
	ssr: false
})

const ModalChat = dynamic(() => import('ui/modals/ModalChat'), {
	ssr: false
})

type LayoutProps = {
	children: React.ReactNode
	data: PageDocument
	globalData: GlobalDocument
	promotionalPopups: PromotionPopupModalDocument[]
}

export default function Layout({ children, data, globalData, promotionalPopups }: LayoutProps) {
	const [returningVisitorCookie, updateCookie] = useCookie('returning_visitor')

	const nav_options = useMemo(() => {
		return data?.hero?.find(
			(slice) => slice.slice_type === 'primary_hero' || slice.slice_type === 'breadcrumb_hero'
		)
	}, [data])

	const announcement_bar = useMemo(() => {
		return (
			data?.hero?.find((slice) => slice.slice_type === 'announcement_bar') ||
			data?.data?.body1?.find((slice) => slice.slice_type === 'announcement_bar') ||
			data?.body?.find((slice) => slice.slice_type === 'announcement_bar')
		)
	}, [data])

	const [openChat, setOpenChat] = useState(false)
	const [showModal, setShowModal] = useState(false)
	const router = useRouter()

	const canonicalUrl = getCanonicalUrl(router.asPath)

	const [isMobileNavOpen, setIsMobileNavOpen] = useState(false)
	const { ref: scrollLockRef } = useScrollLock(isMobileNavOpen)
	const title = useMemo(
		() => data?.seo_title?.[0]?.text ?? data?.data?.title?.[0]?.text,
		[data?.seo_title, data?.data?.title]
	)
	const analyticalData = useMemo(() => {
		if (!router?.isReady) return null

		const origin = getOrigin()
		const result: any = {
			page_url: router?.isReady ? `${origin}${router.asPath}` : '',
			ready: router?.isReady,
			modal: router?.query?.modal || null
		}
		if (title) result.page_title = title

		return result
	}, [title, router])

	// set returning visitor cookie on first visit
	useEffect(() => {
		if (!returningVisitorCookie) {
			updateCookie('true')
		}
	}, [returningVisitorCookie, updateCookie])

	return (
		<>
			<Head>
				{/* Set nav height server side to avoid layout shift */}
				<style>
					{`
						:root {
							--nav-height: ${announcement_bar ? 'calc(88px + 54px)' : '88px'};
						}
					`}
				</style>
				<link rel="canonical" href={canonicalUrl} />
			</Head>

			<AnalyticalDataProvider data={analyticalData}>
				<GlobalSearchProvider>
					{!(router.pathname === '/checkout' || router.pathname === '/cart') ? (
						<Nav
							isTransparentNav={
								nav_options?.primary?.transparent_nav ||
								data?.transparent_nav ||
								router.pathname.includes('/products') ||
								router.asPath.includes('/blog') ||
								false
							}
							navTextContrast={nav_options?.primary?.nav_text_contrast ?? null}
							navOptions={{ ...announcement_bar, globalData: globalData }}
							globalData={globalData}
							isMobileNavOpen={isMobileNavOpen}
							setIsMobileNavOpen={setIsMobileNavOpen}
						/>
					) : null}

					<main ref={scrollLockRef}>{children}</main>

					{/* Chat Button */}
					<button onClick={() => setOpenChat(true)}>
						<Image
							className="fixed bottom-4 right-4 z-50"
							src={chat}
							alt="Chat"
							width={72}
							height={72}
							quality={70}
							sizes="72px"
						/>
					</button>

					<ModalChat open={openChat} setOpen={setOpenChat} globalData={globalData} />

					{promotionalPopups?.length > 0 && (
						<PrismicPromotionalPopup
							open={showModal}
							setOpen={setShowModal}
							modalData={promotionalPopups?.[0]?.data}
						/>
					)}

					<ModalSearch />

					<Footer
						showMarketingSection={!(router.pathname === '/checkout' || router.pathname === '/cart')}
						showSearchAndLinks={!(router.pathname === '/checkout' || router.pathname === '/cart')}
						globalData={globalData}
					/>
					<ExitPreview />
				</GlobalSearchProvider>
			</AnalyticalDataProvider>
		</>
	)
}
