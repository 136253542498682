import { Fragment } from 'react'
import { RadioCard, Symbol } from 'ui'

type Props = {
	allowsPickup: boolean
	showShipping: boolean
	showFulfillment: boolean
	setShowShipping: (showShipping: boolean) => void
	setShowMultipleCopy: (showMultipleCopy: boolean) => void
	setShowIndividualCopy: (showIndividualCopy: boolean) => void
	shippingAlternativeOption?: string
}

export default function FormOptions({
	allowsPickup,
	showShipping,
	showFulfillment,
	setShowShipping,
	setShowMultipleCopy,
	setShowIndividualCopy,
	shippingAlternativeOption
}: Props) {
	return (
		<Fragment>
			{allowsPickup && !showFulfillment && (
				<div className="grid grid-cols-2 gap-2">
					<RadioCard
						id="delivery-pickup"
						name="delivery"
						checked={!showShipping}
						onChange={(e) => {
							if (e.target.checked) {
								setShowShipping(false)
							}
						}}
						className="flex items-center"
						labelClassName="w-full">
						<Symbol id="storeFront" className="mr-1" /> Pickup at Real Thread
					</RadioCard>
					<RadioCard
						id="delivery-ship"
						name="delivery"
						checked={showShipping}
						onChange={(e) => {
							if (e.target.checked) {
								setShowShipping(true)
							}
						}}
						className="flex items-center"
						labelClassName="w-full">
						<Symbol id="boxShipping" className="mr-1" /> Ship to me
					</RadioCard>
				</div>
			)}

			{showFulfillment && allowsPickup && (
				<div className="grid grid-cols-2 gap-2">
					<RadioCard
						id="delivery-ship"
						name="delivery"
						checked={!showShipping}
						onChange={(e) => {
							if (e.target.checked) {
								setShowShipping(false)
							}
						}}
						className="flex items-center"
						labelClassName="w-full">
						<Symbol id="boxShipping" className="mr-1" /> Fulfillment
					</RadioCard>
					<RadioCard
						id="delivery-pickup"
						name="delivery"
						checked={showShipping}
						onChange={(e) => {
							if (e.target.checked) {
								setShowShipping(true)
							}
						}}
						className="flex items-center"
						labelClassName="w-full">
						<Symbol id="boxShipping" className="mr-1" /> Ship to me
					</RadioCard>
				</div>
			)}

			{!allowsPickup && (
				<div className="grid max-sm:grid-cols-1 grid-cols-3 gap-2">
					<RadioCard
						id="delivery-ship"
						name="delivery"
						defaultChecked={!shippingAlternativeOption}
						labelClassName="w-full text-center"
						onChange={(e) => {
							if (e.target.checked) {
								setShowShipping(true)
								setShowMultipleCopy(false)
								setShowIndividualCopy(false)
								// setShippingAlternativeOption(null)
							}
						}}>
						<Symbol id="location" className="mr-1" /> Ship to single address
					</RadioCard>
					<RadioCard
						id="delivery-multiple"
						name="delivery"
						labelClassName="w-full text-center"
						defaultChecked={shippingAlternativeOption === 'Multiple addresses'}
						onChange={(e) => {
							if (e.target.checked) {
								setShowShipping(false)
								setShowMultipleCopy(true)
								setShowIndividualCopy(false)
								// setShippingAlternativeOption('Multiple addresses')
							}
						}}>
						<Symbol id="multipleLocation" className="mr-1" /> Ship to multiple addresses
					</RadioCard>
					<RadioCard
						id="delivery-individual"
						name="delivery"
						labelClassName="w-full text-center"
						defaultChecked={shippingAlternativeOption === 'Individual people'}
						onChange={(e) => {
							if (e.target.checked) {
								setShowShipping(false)
								setShowMultipleCopy(false)
								setShowIndividualCopy(true)
								// setShippingAlternativeOption('Individual people')
							}
						}}>
						<Symbol id="people" className="mr-1" /> Ship to individual people
					</RadioCard>
				</div>
			)}
		</Fragment>
	)
}
