import {
	// Product Icons
	faShirt,
	faShirtLongSleeve,
	faShirtTankTop,
	faPersonDress,
	faHatCowboy,
	// Other Icons
	faBars,
	faBagShopping,
	faBook,
	faComment,
	faCommentQuestion,
	faEnvelope,
	faMessageSms,
	faPhone
} from '@fortawesome/pro-thin-svg-icons'

import {
	// Product Icons
	faBars as faBarsSolid,
	faBook as faBookSolid,
	faShirt as faShirtSolid,
	faShirtTankTop as faShirtTankTopSolid,
	faShirtLongSleeve as faShirtLongSleeveSolid,
	faPersonDress as faPersonDressSolid,
	faBagShopping as faBagShoppingSolid,
	faHatCowboy as faHatCowboySolid,
	// Other Icons
	faComment as faCommentSolid,
	faCommentQuestion as faCommentQuestionSolid,
	faPhone as faPhoneSolid,
	faMessageSms as faMessageSmsSolid,
	faEnvelope as faEnvelopeSolid
} from '@fortawesome/pro-solid-svg-icons'

import {
	faFacebook,
	faSquareInstagram,
	faXTwitter,
	faSquareYoutube
} from '@fortawesome/free-brands-svg-icons'

export default function getNavigationIcon(icon: string) {
	if (!icon) return null

	const iconValues = icon?.split(' ')
	const iconStyle = iconValues[iconValues.length - 2]
	const iconName = iconValues[iconValues.length - 1]

	const iconMapping = {
		// Product Icons
		'fa-hat-cowboy': { light: faHatCowboy, solid: faHatCowboySolid },
		'fa-person-dress': { light: faPersonDress, solid: faPersonDressSolid },
		'fa-shirt-long-sleeve': { light: faShirtLongSleeve, solid: faShirtLongSleeveSolid },
		'fa-shirt': { light: faShirt, solid: faShirtSolid },
		'fa-bag-shopping': { light: faBagShopping, solid: faBagShoppingSolid },
		'fa-shirt-tank-top': { light: faShirtTankTop, solid: faShirtTankTopSolid },

		// Other Navigation Icons
		'fa-bars': { light: faBars, solid: faBarsSolid },
		'fa-book': { light: faBook, solid: faBookSolid },
		'fa-comment': { light: faComment, solid: faCommentSolid },
		'fa-comment-question': { light: faCommentQuestion, solid: faCommentQuestionSolid },
		'fa-phone': { light: faPhone, solid: faPhoneSolid },
		'fa-message-sms': { light: faMessageSms, solid: faMessageSmsSolid },
		'fa-envelope': { light: faEnvelope, solid: faEnvelopeSolid },
		'fa-facebook': { light: faFacebook },
		'fa-instagram': { light: faSquareInstagram },
		'fa-x-twitter': { light: faXTwitter },
		'fa-youtube': { light: faSquareYoutube },

		// Custom Kit Icons
		'fa-hat-1': {
			light: { prefix: 'fa-kit', iconName: 'fa-hat-1' },
			solid: { prefix: 'fa-kit', iconName: 'fa-hat-1' }
		},
		'fa-hat-2': {
			light: { prefix: 'fa-kit', iconName: 'fa-hat-2' },
			solid: { prefix: 'fa-kit', iconName: 'fa-hat-2-solid' }
		},
		'fa-hoodie': {
			light: { prefix: 'fa-kit', iconName: 'fa-hoodie' },
			solid: { prefix: 'fa-kit', iconName: 'fa-hoodie' }
		},
		'fa-pants': {
			light: { prefix: 'fa-kit', iconName: 'fa-pants' },
			solid: { prefix: 'fa-kit', iconName: 'fa-pants-solid' }
		},
		'fa-pants-multiple': {
			light: { prefix: 'fa-kit', iconName: 'fa-pants-multiple' },
			solid: { prefix: 'fa-kit', iconName: 'fa-pants-multiple' }
		},
		'fa-polo': {
			light: { prefix: 'fa-kit', iconName: 'fa-polo' },
			solid: { prefix: 'fa-kit', iconName: 'fa-polo' }
		},
		'fa-shorts': {
			light: { prefix: 'fa-kit', iconName: 'fa-shorts' },
			solid: { prefix: 'fa-kit', iconName: 'fa-shorts-solid' }
		}
	}

	return iconStyle === 'fa-solid' ? iconMapping[iconName]?.solid : iconMapping[iconName]?.light
}
