import clsx from 'clsx'

import { SqueegeeEffect } from 'ui'

type Props = {
	background_color: string
	backgroundHeight?: string
	backgroundSqueegeeOption: 'none' | 'split' | 'contained'
}

export default function DynamicBackground({
	background_color,
	backgroundHeight = 'full',
	backgroundSqueegeeOption = 'none'
}: Props) {
	return (
		<div
			className={clsx('dynamic-background absolute inset-x-0 -bottom-12 -z-10', {
				['top-32']: backgroundHeight === '3/4',
				['-top-12']: backgroundHeight === 'full'
			})}
			style={{ backgroundColor: background_color }}
			suppressHydrationWarning>
			{backgroundSqueegeeOption !== 'none' ? (
				<div className="relative h-full w-full">
					{backgroundSqueegeeOption === 'split' ? (
						<SqueegeeEffect color={'white'} topAligned />
					) : null}

					{backgroundSqueegeeOption === 'contained' ? (
						<>
							<SqueegeeEffect color={'white'} topAligned />
							<SqueegeeEffect color={'white'} bottomAligned />
						</>
					) : null}
				</div>
			) : null}
		</div>
	)
}
