import { ComponentPropsWithoutRef } from 'react'
import { Control, useController } from 'react-hook-form'

type SelectHTMLProps = Omit<ComponentPropsWithoutRef<'select'>, 'defaultValue'>

export interface SelectType extends SelectHTMLProps {
	errorMessage?: string
	hideLabel?: boolean
	errored?: boolean
	name: string
	label: string
	control?: Control<any>
	rules?: any
	defaultValue?: string | number
}

export default function Select({
	className,
	children,
	errored,
	name,
	hideLabel,
	label,
	control,
	rules,
	defaultValue,
	...props
}: SelectType) {
	const {
		field: { onChange, onBlur, name: fieldName, value },
		fieldState: { error }
	} = useController({
		name,
		control,
		rules,
		defaultValue: defaultValue ?? ''
	})

	const errorClass = errored || !!error?.message

	let initialValueForClassname = value

	if (fieldName === 'addressCountryCode' && !value) {
		initialValueForClassname = 'US'
	}

	return (
		<div
			className={`input ${className ?? ''} 
				${initialValueForClassname ? ' input--reset' : ''} 
				${errorClass ? 'input--error' : ''}
			`}>
			<label htmlFor={fieldName} className={hideLabel ? 'srt' : ''}>
				{label}
			</label>
			<select
				className="select"
				id={fieldName}
				name={fieldName}
				onChange={onChange}
				onBlur={onBlur}
				value={value ?? defaultValue ?? ''}
				{...props}>
				<option></option>
				{children}
			</select>

			{!!error?.message && <p className="input-error-message">{error?.message}</p>}
		</div>
	)
}
