// Modal query

export const modalQuery = () => `
  {
    modal {
        ...modalFields
        primary_cta_link {
            ...on product_category {
                ...product_categoryFields
            }
        }
        secondary_cta_link {
            ...on product_category {
                ...product_categoryFields
            }
        }
        form {
            ...on form {
                ...formFields
            }
        }
    }
  }
`
