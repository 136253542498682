import { ReactNode, useRef, useState } from 'react'
import { useIntersection, useEvent } from 'react-use'
import { faChevronLeft } from '@fortawesome/pro-solid-svg-icons'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { useSession } from 'auth'
import { Icon, PrismicLink } from '../'
import { Dropdown, NavLinks } from '.'
import type { ScrollDirection } from 'types'
import Logo from './Logo'
import AnnouncementBar from './AnnouncementBar'
import { faSearch, faShoppingCart } from '@fortawesome/pro-regular-svg-icons'
import { useCart } from 'hooks/graphql/legacy-api'
import { getBaseUrl } from 'utils'
import { toCustomerLabsProperty, useAnalyticalDataContext, useCustomerLabsContext } from 'analytics'
import { useGlobalSearch } from 'context'
import MobileNavDropdown from './MobileNavDropdown'
import clsx from 'clsx'
import type { GlobalDocument, NavDropdownLinkGroupDocument } from 'types/types.generated'
interface NavType {
	isTransparentNav?: boolean
	navTextContrast?: 'light' | 'dark'
	accountMenu?: ReactNode
	orderActions?: ReactNode
	shouldToggleAccountSidebar?: boolean
	showAccountLinks?: boolean
	staticWide?: boolean
	navOptions?: NavDropdownLinkGroupDocument
	globalData?: GlobalDocument
	isMobileNavOpen?: boolean
	setIsMobileNavOpen?: (arg0: boolean) => void
}

export default function Nav({
	isTransparentNav = false,
	navTextContrast = 'dark',
	accountMenu = null,
	orderActions = null,
	shouldToggleAccountSidebar = false,
	showAccountLinks = false,
	staticWide = false,
	navOptions,
	globalData,
	isMobileNavOpen,
	setIsMobileNavOpen
}: NavType) {
	const { isLoggedIn } = useSession()
	const { cart } = useCart()
	const { primary_cta_link, primary_cta_button_label, light_logo, dark_logo, social_media_links } =
		globalData?.data || {}
	const { setOpenGlobalSearch } = useGlobalSearch()

	const [navDropdownIsOpen, setNavDropdownIsOpen] = useState(null)

	const { asPath } = useRouter()

	const analyticalClickFactory = useAnalyticsNavClickFactory()

	// 'Scrolled' check
	const scrollRef = useRef<HTMLDivElement>(null)
	const intersection = useIntersection(scrollRef, { rootMargin: '-5px', threshold: 0.1 })
	const isScrolled = intersection != null && !intersection.isIntersecting

	// Detect scroll direction
	const [lastScrollTop, setLastScrollTop] = useState(0)
	const [scrollDirection, setScrollDirection] = useState<ScrollDirection>('up')

	// Animate nav down into screen, when scrolling up
	useEvent('scroll', () => {
		if (typeof window !== 'undefined') {
			// If a modal is present, do not execute the scroll logic - to prevent nav from animating when modals open
			const modalIsPresent = document.querySelector('.modal') !== null
			if (modalIsPresent) {
				return
			}

			const st = window.pageYOffset || document.documentElement.scrollTop

			if (st > lastScrollTop) {
				setScrollDirection('down')
			} else {
				setScrollDirection('up')
			}

			setLastScrollTop(st <= 0 ? 0 : st)
		}
	})

	// Mobile Nav
	// ==========
	// Mobile nav drawers
	const [openNavDrawer, setOpenNavDrawer] = useState<number>(null)
	const [showAccountMenu, setShowAccountMenu] = useState<boolean>(false)

	// Open/close logic
	const showOrderActions = orderActions !== null

	return (
		<>
			{/* Scroll ref for scrolljacking */}
			<div
				className="w-0 h-16 absolute top-0 left-0 invisible"
				aria-hidden="true"
				ref={scrollRef}
			/>
			<nav
				className={clsx({
					[`nav ${isScrolled ? 'is-scrolled' : ''} scrolling-${scrollDirection}`]: true,
					['nav--staticWide']: staticWide,
					['!bg-white']:
						!isTransparentNav ||
						(navDropdownIsOpen && navTextContrast === 'dark') ||
						(scrollDirection === 'up' &&
							lastScrollTop > 50 &&
							(navTextContrast === 'dark' || navTextContrast === null)),
					['!bg-black-rt']:
						(navDropdownIsOpen && navTextContrast === 'light') ||
						(scrollDirection === 'up' && lastScrollTop > 50 && navTextContrast === 'light'),
					['bg-transparent']: isTransparentNav && !navDropdownIsOpen
				})}>
				{
					// Announcement Bar
					navOptions?.slice_type === 'announcement_bar' && !isMobileNavOpen ? (
						<AnnouncementBar {...navOptions} />
					) : null
				}

				{/*Contents of horizontal navbar*/}
				<div
					className={`relative ${
						navTextContrast === 'light' ? 'text-white' : 'text-black-rt'
					} z-50`}>
					<div
						className={clsx(
							`flex justify-between items-center py-5 z-[100]
								${showAccountLinks ? 'layout layout--pad layout--flex' : 'container'}
							`,
							{
								[`${navTextContrast === 'light' ? 'bg-black-rt' : 'bg-white'}`]: isMobileNavOpen
							}
						)}>
						<Logo navTextContrast={navTextContrast} light_logo={light_logo} dark_logo={dark_logo} />
						<div
							className={`hidden lg:flex
              ${
								showAccountLinks
									? `${
											showOrderActions
												? 'flex-grow justify-end ml-4'
												: 'flex-grow justify-between mx-4'
									  }`
									: 'justify-center mx-4'
							}
              `}>
							{showOrderActions ? (
								orderActions
							) : (
								// Desktop nav
								<div
									className={`flex items-center flex-grow ${
										showAccountLinks ? '' : 'xl:space-x-3 2xl:space-x-6'
									}`}>
									{Array.isArray(globalData?.data?.dropdowns)
										? globalData?.data?.dropdowns.map((dropdown, i) => {
												return (
													<Dropdown
														setNavDropdownIsOpen={setNavDropdownIsOpen}
														analyticalClickFactory={analyticalClickFactory}
														dropdown={dropdown}
														closeOnScroll={scrollDirection === 'down' && isScrolled}
														key={i}
														tabClass={showAccountLinks ? 'tab' : 'tab'}
														globalData={globalData}
													/>
												)
										  })
										: null}
								</div>
							)}
						</div>

						{/* Right Side of Horizontal Nav Bar */}
						<div className="flex max-lg:gap-6 gap-2 items-center">
							{/* Search Icon / Button */}
							<button
								onClick={() => {
									setOpenGlobalSearch(true)
									analyticalClickFactory('Search')()
								}}
								aria-label="Search">
								<Icon icon={faSearch} className="w-5 h-5 mt-1" />
							</button>

							{showAccountLinks && (
								<NavLinks
									analyticalClickFactory={analyticalClickFactory}
									linkClass="tab"
									isLoggedIn={isLoggedIn}
								/>
							)}

							{/* My Account & Sign In Links */}
							<NavLinks
								analyticalClickFactory={analyticalClickFactory}
								linkClass="tab"
								isLoggedIn={isLoggedIn}
								loading={!isLoggedIn && typeof isLoggedIn !== 'boolean'}
								className="max-lg:hidden"
							/>

							{/* Primary CTA */}

							{cart?.items?.length > 0 || asPath.startsWith('/products') ? (
								<Link
									href={`${getBaseUrl()}/cart`}
									onClick={analyticalClickFactory('Cart Icon')}
									aria-label="Cart"
									className={`button p-2.5 aspect-square mb-1
										${navTextContrast === 'light' ? 'button-light' : 'button-dark'}
										${cart?.items.length > 0 ? 'cart-with-content-indicator' : ''}
									`}>
									<Icon icon={faShoppingCart} className="w-4 h-4" />
								</Link>
							) : (
								!showOrderActions && (
									<PrismicLink
										onClick={analyticalClickFactory(primary_cta_button_label)}
										label={primary_cta_button_label}
										link={primary_cta_link}
										className={`max-sm:hidden button button-md ${
											navTextContrast === 'light' ? 'button-light' : 'button-dark'
										}`}
										globalData={globalData}
									/>
								)
							)}
							{/* Mobile nav button */}
							<button
								className={`hamburger-button
										${isMobileNavOpen ? 'toggled' : ''}
										${navTextContrast === 'light' ? 'text-contrast-light' : 'text-contrast-dark'}
									`}
								onClick={() => {
									setIsMobileNavOpen(!isMobileNavOpen)
									analyticalClickFactory('Mobile Menu', true)()
								}}
								aria-label="Toggle the nav drawer"
							/>
						</div>
					</div>
				</div>

				<MobileNavDropdown
					isMobileNavOpen={isMobileNavOpen}
					dropdowns={globalData?.data?.dropdowns}
					setOpenNavDrawer={setOpenNavDrawer}
					setIsMobileNavOpen={setIsMobileNavOpen}
					shouldToggleAccountSidebar={shouldToggleAccountSidebar}
					isLoggedIn={isLoggedIn}
					setShowAccountMenu={setShowAccountMenu}
					openNavDrawer={openNavDrawer}
					socialMediaLinks={social_media_links}
					analyticalClickFactory={(value: string) => analyticalClickFactory(value, true)}
					globalData={globalData}
				/>

				{/* Account nav drawer */}
				<div
					className={`fixed inset-0 translate-x-1/2 w-screen min-h-screen max-h-screen overflow-y-auto bg-white flex flex-col transition-transform ease-in-out ${
						showAccountMenu ? 'translate-x-0 duration-500' : 'translate-x-[105%] duration-300'
					}`}
					role="dialog"
					aria-label="Navigation menu"
					tabIndex={showAccountMenu ? 0 : -1}>
					<div className="flex justify-between items-center p-4">
						{/* 'Close Drawer' button */}
						<button
							onClick={() => {
								setShowAccountMenu(false)
								analyticalClickFactory('Back', true)()
							}}
							className="flex items-center space-x-4 font-semibold uppercase py-2 pr-2">
							<Icon icon={faChevronLeft} className="w-3 h-3 mr-4" />
							Back
						</button>

						<button
							className="nav-button toggled"
							onClick={() => {
								setShowAccountMenu(false)
								analyticalClickFactory('Toggle the nav drawer', true)()
							}}
							aria-label="Toggle the nav drawer"
						/>
					</div>
					<div className="flex-grow">{accountMenu}</div>
				</div>
			</nav>
		</>
	)
}

function useAnalyticsNavClickFactory() {
	const { trackClick } = useCustomerLabsContext()
	const { page_title, page_url } = useAnalyticalDataContext()
	const analyticalClickFactory =
		(click_value: string, mobile: boolean = false) =>
		() => {
			trackClick('Click', {
				customProperties: toCustomerLabsProperty({
					click_type: (mobile ? 'Mobile ' : '') + 'Header Menu Navigation',
					click_value,
					page_title,
					page_url
				}).v
			})
		}

	return analyticalClickFactory
}
