import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client'

const link = createHttpLink({
	uri: process.env.NEXT_PUBLIC_APOLLO_SERVER_URL,

	// in order to include the Ory cookie in the requests to the GraphQL server
	// 'include' when the server is on subdomain or different domain
	// 'same-origin' when the server is on the same domain
	credentials: 'include'
})

//Initialize Apollo Client
export const apolloClientNewServer = new ApolloClient({
	link,
	cache: new InMemoryCache()
})
