import { RichText as RichTextRenderer } from 'prismic-reactjs'
import { useEffect } from 'react'

import { linkResolver, htmlSerializer } from 'utils/prismic'
import type { HomeDocumentDataBodyRichTextSlice } from 'types/types.generated'

type RichTextProps = {
	content: HomeDocumentDataBodyRichTextSlice
	withAnchorIds?: boolean
	className?: string
	style?: any
}

export default function RichText({ content, withAnchorIds, className = '', style }: RichTextProps) {
	// Add number to headings if they are not unique
	useEffect(() => {
		if (!withAnchorIds) return

		content?.forEach((heading: any) => {
			const count = content.filter((h: any) => h.text === heading.text && !h.number).length
			if (count > 1) {
				heading.number = count
			}
		})
	}, [withAnchorIds, content])

	return content ? (
		<div className={`rich-text table-of-contents ${className}`} style={style}>
			<RichTextRenderer
				render={content}
				linkResolver={linkResolver}
				htmlSerializer={(...args) => htmlSerializer(...args, !!withAnchorIds)}
			/>
		</div>
	) : null
}
