import { FrontendApi, Configuration } from '@ory/client'
import { edgeConfig } from '@ory/integrations/next'

const config = {
	basePath: process.env.NEXT_PUBLIC_ORY_SDK_URL,
	baseOptions: {
		withCredentials: true
	}
}

const configOptions = process.env.NEXT_PUBLIC_VERCEL_ENV === 'preview' ? edgeConfig : config

export const ory = new FrontendApi(new Configuration(configOptions))