import { gql, useQuery } from '@apollo/client'
import { useMemo } from 'react'
import { apolloClientNewServer } from 'utils/clients/apolloClientNewServer'

export const GET_PRICE_CALCULATOR_BY_ID = gql`
	query PriceCalculatorById($priceCalculatorId: String) {
		priceCalculatorById(priceCalculatorId: $priceCalculatorId) {
			sfid
			calculatorId
			description
			quantity
			product
			productId
			price
			markup
			firstLocation
			extraLocations
			extraColors
			turnaround
			additionalStitches
			includedStitchCount
			additionalSquareInches
			includedSquareInches
			recordTypeId
			recordType {
				sfid
				name
			}
		}
	}
`

export const usePriceCalculatorById = (priceCalculatorId?: string) => {
	const { loading, error, data } = useQuery(GET_PRICE_CALCULATOR_BY_ID, {
		variables: { priceCalculatorId: priceCalculatorId ?? null },
		client: apolloClientNewServer
	})

	const value = useMemo(
		() => ({
			priceCalculators: data ? data.priceCalculatorById : error ? [] : undefined,
			loading,
			error
		}),
		[loading, error, data]
	)

	return value
}
