import { Fragment, useEffect } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { Icon } from 'ui'
import { faXmarkLarge } from '@fortawesome/pro-light-svg-icons'
import { Symbol } from 'ui'

export type ModalProps = {
	title?: string
	description?: string
	open: boolean
	onClose: React.Dispatch<React.SetStateAction<boolean>>
	initialFocusRef?: React.RefObject<any>
	maxWidth?: number
	panelClass?: string
	hideCloseIcon?: boolean
	children: React.ReactNode
}

export default function Modal({
	open,
	onClose,
	title,
	description,
	initialFocusRef,
	maxWidth,
	panelClass,
	hideCloseIcon,
	children
}: ModalProps) {
	// This prevents a headless UI bug where the body jumps to the top when opening a modal on mobile
	useEffect(() => {
		const handleScrollBehavior = () => {
			if (open) {
				document.documentElement.style.scrollBehavior = 'unset'
			} else {
				document.documentElement.style.scrollBehavior = 'auto'
			}
		}
		handleScrollBehavior()
		return () => {
			document.documentElement.style.scrollBehavior = 'auto'
		}
	}, [open])

	return (
		<Transition.Root show={open} as={Fragment}>
			<Dialog as="div" className="relative z-50" initialFocus={initialFocusRef} onClose={onClose}>
				<Transition.Child
					as={Fragment}
					enter="ease-out duration-300"
					enterFrom="opacity-0"
					enterTo="opacity-100"
					leave="ease-in duration-200"
					leaveFrom="opacity-100"
					leaveTo="opacity-0">
					<div className="fixed inset-0 bg-black-rt bg-opacity-75 transition-opacity" />
				</Transition.Child>

				<div className="fixed inset-0 z-10 overflow-y-auto">
					<div className="flex min-h-full justify-center p-4 text-center items-center sm:p-0">
						<Transition.Child
							as={Fragment}
							enter="ease-out duration-300"
							enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
							enterTo="opacity-100 translate-y-0 sm:scale-100"
							leave="ease-in duration-200"
							leaveFrom="opacity-100 translate-y-0 sm:scale-100"
							leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
							<Dialog.Panel
								style={{ maxWidth: maxWidth }}
								className={`
									relative transform overflow-hidden rounded-3xl bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6
									${panelClass ?? ''}
								`}>
								{title && (
									<Dialog.Title className="flex text-xl leading-6 text-black-rt font-serif pt-4 pb-8 border-b">
										<div className="w-4/5 flex justify-start">{title}</div>
										<button onClick={() => onClose(false)} className="w-1/5 flex justify-end">
											<Icon icon={faXmarkLarge} className="w-5 h-5" />
										</button>
									</Dialog.Title>
								)}
								{description && (
									<Dialog.Description className="mb-4 text-sm text-zinc-400">
										{description}
									</Dialog.Description>
								)}
								<div className="max-h-[550px] overflow-y-auto">{children}</div>
								{!hideCloseIcon ? (
									<button className="modal-close" onClick={() => onClose(false)}>
										<Symbol id="close" title="Close modal" />
									</button>
								) : null}
							</Dialog.Panel>
						</Transition.Child>
					</div>
				</div>
			</Dialog>
		</Transition.Root>
	)
}
