import { useEffect, useState } from 'react'
import { Input, InputPhone } from '../form'
import { Error } from 'ui'
import { getBaseUrl } from 'utils'
import { submitForm } from 'utils/forms/form'

const ChatForm = ({ setCurrentOption, control, getValues, chat_start_endpoint, watch }) => {
	const [showError, setShowError] = useState(false)
	const [error, setError] = useState(null)
	const emailRegx = new RegExp(/[a-zA-Z0-9]+@[a-zA-Z]+\.[a-zA-Z]{2,3}/)

	useEffect(() => {
		// validate fields and if they are filled up remove error
		const firstName = watch('first_name')
		const lastName = watch('last_name')
		const email = watch('email')
		const phone = watch('phone_mobile')

		if (firstName && lastName && email && phone && emailRegx.test(email)) {
			setShowError(false)
			setError(null)
		}
	}, [watch('first_name'), watch('last_name'), watch('email'), watch('phone_mobile')])

	const handleOnSubmit = async () => {
		if (
			getValues('first_name') === '' ||
			getValues('first_name') === null ||
			getValues('last_name') === '' ||
			getValues('email') === '' ||
			getValues('phone_mobile') === '' ||
			getValues('phone_mobile').length < 12 ||
			// Email validation
			!emailRegx.test(getValues('email'))
		) {
			setShowError(true)
			setError('Please fill out all fields before continuing')
			return
		}

		try {
			setError(null)
			// fetch basin endpoint
			await submitForm(
				{
					first_name: getValues('first_name'),
					last_name: getValues('last_name'),
					email: getValues('email'),
					phone_mobile: getValues('phone_mobile')
				},
				{
					endpoint: chat_start_endpoint,
					useRecaptcha: true
				}
			)

			setCurrentOption((prev) => prev + 1)
		} catch (e) {
			console.log('Error submitting form', e)
			setError('Error submitting form. Please try again.')
		}
	}

	return (
		<>
			<Input
				type="text"
				label="First name"
				name="first_name"
				control={control}
				errored={showError && getValues('first_name') === ''}
			/>
			<Input
				type="text"
				label="Last name"
				name="last_name"
				control={control}
				errored={showError && getValues('last_name') === ''}
			/>
			<InputPhone
				className="w-full"
				name="phone_mobile"
				control={control}
				label="Phone"
				errored={
					showError && (getValues('phone_mobile') === '' || getValues('phone_mobile')?.length < 12)
				}
			/>
			<Input
				type="email"
				label="Email"
				name="email"
				control={control}
				errored={showError && (getValues('email') === '' || !emailRegx.test(getValues('email')))}
			/>

			<p className="text-left text-sm mt-1">
				By clicking "Continue" you agree to receive a text from us. See our{' '}
				<a href={getBaseUrl() + '/terms'} className="hyperlink">
					Terms
				</a>{' '}
				and{' '}
				<a href={getBaseUrl() + '/privacy-policy'} className="hyperlink">
					Privacy Policy
				</a>
				. Rates may apply.
			</p>

			{error && <Error>{error}</Error>}

			<button
				type="button"
				className="button button-dark button-md w-fit ml-auto"
				onClick={handleOnSubmit}>
				Continue
			</button>
		</>
	)
}

export default ChatForm
