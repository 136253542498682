// Review Carousel
import { forwardRef, useEffect, useMemo, useState } from 'react'
import type { MutableRefObject } from 'react'

import Carousel from 'ui/Carousel'
import { TestimonialCard, DynamicBackground, HeaderContent } from 'ui'
import type { PageDocumentDataBodyReviewCarouselSlice } from 'types/types.generated'
import { getReviews } from 'hooks'

const ReviewCarousel = forwardRef(
	(props: PageDocumentDataBodyReviewCarouselSlice, ref: MutableRefObject<HTMLDivElement>) => {
		const { primary, items, globalData } = props || {}

		const {
			section_heading,
			heading_max_width,
			section_description,
			description_max_width,
			text_contrast_mode,
			background_color,
			background_squeegee_option,
			background_height,
			section_primary_cta_label,
			section_primary_cta_link,
			section_secondary_cta_label,
			section_secondary_cta_link,
			centered,
			cards_background_color
		} = primary || {}

		const [itemsData, setItemsData] =
			useState<PageDocumentDataBodyReviewCarouselSlice['items']>(items)

		useEffect(() => {
			if (items?.length === 0) {
				const fetchItemsData = async () => {
					const data = await getReviews()
					setItemsData(data.data.reviews)
				}
				fetchItemsData()
			}
		}, [items])

		const cards = useMemo(() => {
			return itemsData?.slice(0, 15).map((review, i) => {
				return (
					<TestimonialCard
						review={{
							data: {
								rating: review.rating,
								customer_name:
									review.contact.firstname +
									' ' +
									// first letter of the last name
									review.contact.lastname.charAt(0) +
									'.',
								customer_location:
									review.contact.city && review.contact.state
										? `${review.contact.city
												.toLowerCase()
												.replace(/\b\w/g, (char) => char.toUpperCase())}, ${review.contact.state}`
										: review.contact.city
										? review.contact.city
												.toLowerCase()
												.replace(/\b\w/g, (char) => char.toUpperCase())
										: review.contact.state ?? null,
								testimonial: review.comments,
								testimonial_title: null,
								created_at: review.createdAt,
								customer_profile_photo: review.contact.profilePhoto
							}
						}}
						key={i}
						bgColor={cards_background_color || ''}
					/>
				)
			})
		}, [itemsData])

		return (
			<section className="review-carousel relative section" ref={ref}>
				<DynamicBackground
					background_color={background_color}
					backgroundHeight={background_height}
					backgroundSqueegeeOption={background_squeegee_option}
				/>
				{/* Title, description, CTAs */}
				<HeaderContent
					title={section_heading}
					titleMaxWidth={heading_max_width}
					description={section_description}
					descriptionMaxWidth={description_max_width}
					primaryCta={{ label: section_primary_cta_label, link: section_primary_cta_link }}
					secondaryCta={{ label: section_secondary_cta_label, link: section_secondary_cta_link }}
					textContrast={text_contrast_mode}
					centered={centered}
					className="lg:-mb-20 container"
					globalData={globalData}
				/>
				{itemsData?.length > 0 && (
					<div className="container">
						<Carousel cards={cards} />
					</div>
				)}
			</section>
		)
	}
)
ReviewCarousel.displayName = 'ReviewCarousel'

export default ReviewCarousel
