import HelpToolTip from './HelpToolTip'
import snakeCase from 'lodash.snakecase'
import { RadioInput, RichText } from 'ui'
import clsx from 'clsx'

export default function RadioGroup({
	label,
	items,
	help_text,
	disabled,
	register,
	fieldName,
	textContrast,
	alignment
}) {
	return (
		<div className={clsx('flex flex-col gap-2', textContrast === 'light' && 'text-light')}>
			{label?.[0]?.text ? (
				<div className="flex items-start gap-2">
					<RichText
						className={clsx(
							'rich-text-label sm:col-span-2 font-medium',
							textContrast === 'light' ? 'text-white' : 'text-black-rt'
						)}
						content={label}
					/>
					{help_text ? (
						<HelpToolTip helpText={help_text} textContrast={textContrast} className="mt-0.5" />
					) : null}
				</div>
			) : null}
			<div
				className={clsx(
					'flex flex-wrap',
					alignment === 'horizontal' ? 'flex-row gap-4' : 'flex-col gap-2'
				)}>
				{items.map((item, i) => {
					const optionName = item?.label
						? snakeCase(item.label)
						: item?.value
						? snakeCase(item.value)
						: null
					return optionName ? (
						<RadioInput
							key={i}
							optionName={optionName}
							item={item}
							register={register}
							fieldName={fieldName}
							disabled={disabled}
							textContrast={textContrast}
						/>
					) : null
				})}
			</div>
		</div>
	)
}
