import { useEffect, useMemo, useState } from 'react'
import clsx from 'clsx'
import Image from 'next/image'
import { ImageJsonLd } from 'next-seo'

import { VideoEmbed } from 'ui'
import { Modal } from 'ui/modals'
import { getTabItems, getTabNames } from 'utils/tabs'
import { getModalWithLinks } from 'utils/prismic/modal'

import { PrismicLink, RichText } from '.'
import { LeadGenForm } from 'ui/form'
import Form from 'ui/form/Form'
import type { GlobalDocument, GlobalBuilderDocument } from 'types/types.generated'

type PrismicModalProps = {
	open: boolean
	setOpen: (open: boolean) => void
	modalId?: string
	globalData: GlobalDocument
	globalBuilderData?: GlobalBuilderDocument
}

export default function PrismicModal({
	open,
	setOpen,
	modalId = null,
	globalData,
	globalBuilderData = null
}: PrismicModalProps) {
	const [modalData, setModalData] = useState<any>(globalBuilderData)
	const [selectedIndex, setSelectedIndex] = useState(0)

	useEffect(() => {
		if (!modalId) return
		const getData = async () => {
			// Fetch modal data
			const data = await getModalWithLinks(modalId)

			setModalData(data?.data)
		}

		getData()
	}, [modalId])

	const tabNames = useMemo(() => {
		if (!modalData?.with_tabs) return []

		return getTabNames(modalData?.tabs, modalData?.tabs).reverse()
	}, [modalData?.tabs, modalData?.with_tabs])

	const tabItems = useMemo(() => {
		if (!modalData?.with_tabs) return modalData?.tabs

		return getTabItems(modalData?.tabs, modalData?.tabs, tabNames)
	}, [modalData?.tabs, tabNames, modalData?.with_tabs])

	return (
		<Modal
			isOpen={open}
			setIsOpen={setOpen}
			panelClassName="prismic-modal modal--wide md:max-h-[calc(100dvh_-_180px)] flex max-md:flex-col p-0 text-left"
			aria-label="Modal">
			{/* Main content */}
			<div
				className={clsx('flex-grow overflow-y-scroll pt-16 px-4 md:px-16 pb-20', {
					'md:pr-20': modalData?.with_sidebar
				})}>
				{modalData?.rich_text && (
					<RichText className="prose max-w-full" content={modalData.rich_text} />
				)}
				{modalData?.image && Object.keys(modalData.image).length > 0 && (
					<>
						<Image
							src={modalData.image.url}
							alt={modalData.image.alt}
							width={modalData?.image?.dimensions?.width || 800}
							height={modalData?.image?.dimensions?.height || 600}
							className="rounded-lg my-4"
							sizes="(max-width: 768px) 90vw, (max-width: 1600px) 50vw, 800px"
							quality={70}
						/>
						<ImageJsonLd
							contentUrl={modalData.image.url}
							images={[modalData.image]}
							creator="Real Thread"
							creditText="Real Thread"
						/>
					</>
				)}
				{modalData?.form?.id &&
					(modalData.form.type === 'lead_gen_form' ? (
						<div className="flex flex-col items-center w-full">
							<LeadGenForm form={modalData.form} />
						</div>
					) : (
						<Form form={modalData.form} alignButtonOnLeft />
					))}
				{modalData?.rich_text_below && (
					<RichText className="prose max-w-full" content={modalData.rich_text_below} />
				)}

				{modalData?.with_tabs && (
					<div className="flex flex-col gap-4">
						{/* Tabs */}
						<div className="top-tabs-container">
							{tabNames?.map((item, i) => {
								const isSelected = i === selectedIndex

								// Tab title
								return item ? (
									<button
										className={clsx('top-tab', isSelected && 'is-active')}
										onClick={() => setSelectedIndex(i)}
										key={i}>
										{item}
									</button>
								) : null
							})}
						</div>

						{tabItems?.map((items, i: number) => {
							const isSelected = i === selectedIndex

							return items && isSelected && Array.isArray(items) ? (
								<div key={i}>
									{items.map((item, i: number) => (
										<div key={i}>
											{item?.tab_label && (
												<h2 className="text-2xl font-serif pt-4">{item.tab_label}</h2>
											)}
											{item?.rich_text_area_above && (
												<RichText
													className="prose max-w-full"
													content={item.rich_text_area_above}
												/>
											)}
											{item?.video?.html ? (
												<div className="aspect-video w-full mx-auto border border-black-rt">
													<VideoEmbed html={item?.video.html} className="video-fifty-fifty" />
												</div>
											) : (
												item?.image && (
													<Image
														src={item.image.url}
														alt={item.image.alt}
														width={item.image.dimensions.width}
														height={item.image.dimensions.height}
														className="rounded-lg my-4"
														sizes="(max-width: 768px) 90vw, (max-width: 1600px) 50vw, 800px"
														quality={70}
													/>
												)
											)}
											{item?.rich_text_area_below && (
												<RichText
													className="prose max-w-full"
													content={item.rich_text_area_below}
												/>
											)}
										</div>
									))}
								</div>
							) : null
						})}
					</div>
				)}
			</div>

			{/* Sidebar */}
			{modalData?.with_sidebar && (
				<div
					className={
						'md:w-[350px] shrink-0 flex flex-col pt-16 pb-12 px-4 md:px-10 overflow-y-scroll'
					}
					style={{ backgroundColor: modalData?.background_color || '#f5f5f1' }}>
					{modalData?.sidebar_rich_text_area && (
						<RichText className="prose max-w-3xl" content={modalData.sidebar_rich_text_area} />
					)}

					{modalData?.video?.html ? (
						<div className="aspect-video mx-auto pb-2 w-full mt-auto">
							<VideoEmbed html={modalData?.video.html} className="video-fifty-fifty" />
						</div>
					) : null}

					{modalData?.primary_cta_label || modalData?.secondary_cta_label ? (
						<div
							className={clsx(
								'z-10 justify-center flex flex-col gap-4 text-center w-full',
								!modalData?.video?.html && 'mt-auto'
							)}>
							{modalData.primary_cta_label && modalData.primary_cta_link ? (
								<PrismicLink
									className="button button-lg button-dark"
									link={modalData.primary_cta_link}
									label={modalData.primary_cta_label}
									globalData={globalData}
								/>
							) : null}

							{modalData.secondary_cta_label ? (
								modalData.secondary_cta_link.link_type === 'Any' ? (
									<button className="hyperlink w-fit mx-auto" onClick={() => setOpen(false)}>
										{modalData.secondary_cta_label}
									</button>
								) : (
									<PrismicLink
										className="hyperlink w-fit mx-auto"
										link={modalData.secondary_cta_link}
										label={modalData.secondary_cta_label}
										globalData={globalData}
									/>
								)
							) : null}
						</div>
					) : null}
				</div>
			)}
		</Modal>
	)
}
