// Navigation Mega-Menu

import { useEffect, useRef, useState } from 'react'
import { useClickAway, useKey, useMedia } from 'react-use'
import Link from 'next/link'
import { AnimatePresence, motion } from 'framer-motion'

import { linkResolver } from 'utils/prismic'
import { Icon } from '../'
import { faChevronDown, faChevronUp } from '@fortawesome/pro-solid-svg-icons'
import { getBaseUrl } from 'utils'
import DropdownContent from './DropdownContent'
import type { GlobalDocument, NavDropdownLinkGroupDocument } from 'types/types.generated'

type DropdownProps = {
	setNavDropdownIsOpen: (value: boolean) => void
	dropdown: NavDropdownLinkGroupDocument
	closeOnScroll: boolean
	tabClass?: string
	analyticalClickFactory?: (value: string) => () => void
	globalData?: GlobalDocument
}

export default function Dropdown({
	setNavDropdownIsOpen,
	dropdown,
	closeOnScroll,
	analyticalClickFactory,
	tabClass = 'tab',
	globalData
}: DropdownProps) {
	const { button_label, bottom_link_grid, link_groups, open_dropdown_drawer, informational_card } =
		dropdown.dropdown.data || {}

	// Open/close logic
	const [isOpen, setIsOpen] = useState(false)

	// Close dropdown on click away
	const dropdownRef = useRef<HTMLDivElement>(null)
	useClickAway(dropdownRef, () => setIsOpen(false))

	// Close dropdown on escape key
	useKey('Escape', () => setIsOpen(false))

	// Close dropdown on mobile
	const isMobile = useMedia('( max-width: 512px )', false)
	useEffect(() => {
		isMobile && isOpen && setIsOpen(false)
	}, [isMobile, isOpen])

	// Close when scrolling down
	useEffect(() => {
		closeOnScroll && isOpen && setIsOpen(false)
	}, [closeOnScroll, isOpen])

	useEffect(() => {
		setNavDropdownIsOpen(isOpen)
	}, [isOpen])

	return button_label?.[0]?.text ? (
		<div ref={dropdownRef} className={`nav-dropdown-group ${isOpen ? 'is-current' : ''}`}>
			{/* Toggle button (in nav) */}
			{open_dropdown_drawer ? (
				<button
					onPointerDown={() => setIsOpen(!isOpen)}
					className={`flex gap-2 items-center ${tabClass}`}
					aria-expanded={isOpen}
					{...(analyticalClickFactory
						? { onClick: analyticalClickFactory(button_label[0].text) }
						: {})}>
					{button_label[0].text}
					<div className="chevron-animation-mask overflow-hidden h-3">
						<div className="chevron-animated-container flex flex-col gap-.5 -translate-y-4 transition-transform duration-200 ease-out">
							<Icon icon={faChevronUp} className="w-[12px] -mt-1" />
							<Icon icon={faChevronDown} className="w-[12px] -mt-1" />
						</div>
					</div>
				</button>
			) : (
				<Link
					href={getBaseUrl() + linkResolver(link_groups?.[0]?.links?.data?.view_all_link)}
					className={`flex gap-2 items-center ${tabClass}`}
					{...(analyticalClickFactory
						? { onClick: analyticalClickFactory(button_label[0].text) }
						: {})}>
					{button_label[0].text}
				</Link>
			)}

			<AnimatePresence>
				{isOpen && (
					<motion.div
						className="dropdown text-black-rt"
						role="menu"
						tabIndex={0}
						initial={{ opacity: 0 }}
						animate={{ opacity: 1 }}
						exit={{ opacity: 0 }}
						transition={{ duration: 0.2 }}>
						<DropdownContent
							dropdown={dropdown}
							setIsOpen={setIsOpen}
							globalData={globalData}
							analyticalClickFactory={analyticalClickFactory}
						/>
					</motion.div>
				)}
			</AnimatePresence>
		</div>
	) : null
}
