import { gql, useMutation } from '@apollo/client'
import { apolloClientNewServer } from 'utils/clients/apolloClientNewServer'

const SETUP_PAYMENT_INTENT = gql(`
    mutation SetupPaymentIntent(
        $orderSfid: String!
        $paymentMethodId: String!
        $userEmail: String!
        $userFirstName: String!
        $userLastName: String!
        ) {
        setupPaymentIntent(
            orderSfid: $orderSfid
            paymentMethodId: $paymentMethodId
            userEmail: $userEmail
            userFirstName: $userFirstName
            userLastName: $userLastName
        ) {
            sfid
            amount
            accountId
            orderId
            paymentDate
            paymentMethod
            paymentProcessor
            transactionId
            herokuConnectUuid
        }
    }
`)

export const useSetupPaymentIntent = () => {
	const [setupPaymentIntent] = useMutation(SETUP_PAYMENT_INTENT, {
		client: apolloClientNewServer
	})

	return {
		setupPaymentIntent
	}
}
