import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import Image from 'next/image'

import { Modal } from 'ui/modals'
import {
	ChatForm,
	ChatCustomerOptions,
	ChatQuantitiesOptions,
	ChatQuestionText,
	ChatSuccessMessage
} from 'ui/chat'
import { Error } from 'ui'
import type { GlobalDocument } from 'types/types.generated'
import { submitForm } from 'utils/forms/form'
import { getGlobalChatData } from 'hooks/prismic/getGlobalData'

type Props = {
	open: boolean
	setOpen: Dispatch<SetStateAction<boolean>>
	globalData: GlobalDocument
}

export default function ModalChat({ open, setOpen, globalData }: Props) {
	const [currentOption, setCurrentOption] = useState(1)
	const [isSubmitted, setIsSubmitted] = useState(false)
	const [error, setError] = useState(null)
	const [global, setGlobal] = useState(globalData)

	const { handleSubmit, control, setValue, getValues, reset, watch } = useForm()

	useEffect(() => {
		if (!globalData) {
			const fetchGlobalChatData = async () => {
				const data = await getGlobalChatData()
				setGlobal(data)
			}
			fetchGlobalChatData()
		}
	}, [globalData])

	const onSubmit = async (data) => {
		try {
			setError(null)
			// fetch basin endpoint
			await submitForm(data, {
				endpoint: global?.data?.chat_submit_endpoint?.url,
				useRecaptcha: true
			})

			// clear form fields
			reset()
			setIsSubmitted(true)
			setCurrentOption(1)
		} catch (e) {
			console.log('Error submitting chat', e)
			setError('Error submitting chat. Please try again.')
		}
	}

	const onClose = () => {
		setOpen(false)
		reset()
		setIsSubmitted(false)
		setCurrentOption(1)
	}

	return (
		<Modal
			isOpen={open}
			setIsOpen={setOpen}
			onClose={onClose}
			panelClassName="modal--content-width p-0 max-w-[424px] lg:w-[424px] h-[620px] lg:rounded-br-lg "
			modalClassName="lg:!items-end lg:!justify-end lg:pb-20 lg:pr-20"
			aria-label="Chat">
			{isSubmitted ? (
				<ChatSuccessMessage />
			) : (
				<div className="flex flex-col h-full">
					{/* Modal Header */}
					<div className="flex flex-wrap-reverse gap-6 bg-g-50 max-xs:p-4 py-10 px-6 lg:p-10 text-left">
						<div>
							<h2 className="desktop-h4">Have a question?</h2>
							<p className="text-sm">Text us, we’d love to help</p>
						</div>
						<div>
							<Image
								src="https://rstr.in/real-thread/marketing/uBVKq8YhIpN"
								alt="Chat Agents"
								width={93.5}
								height={39}
								sizes="93.5px"
								quality={70}
							/>
						</div>
					</div>

					{/* Modal Form */}
					<form
						onSubmit={handleSubmit(onSubmit)}
						className="flex flex-col gap-4 p-4 lg:p-10 flex-grow border">
						{currentOption === 1 && (
							<ChatForm
								setCurrentOption={setCurrentOption}
								control={control}
								getValues={getValues}
								watch={watch}
								chat_start_endpoint={global?.data?.chat_start_endpoint?.url}
							/>
						)}
						{currentOption === 2 && (
							<ChatCustomerOptions setCurrentOption={setCurrentOption} setValue={setValue} />
						)}
						{currentOption === 3 && (
							<ChatQuantitiesOptions setCurrentOption={setCurrentOption} setValue={setValue} />
						)}
						{currentOption === 4 && (
							<ChatQuestionText
								control={control}
								setCurrentOption={setCurrentOption}
								newCustomer={getValues('new_existing') === 'new'}
							/>
						)}
					</form>
					{error && <Error>{error}</Error>}
				</div>
			)}
		</Modal>
	)
}
