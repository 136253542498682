import { Dispatch, SetStateAction } from 'react'
import Link from 'next/link'
import { Icon, PlainText } from '..'
import { linkResolver } from 'utils/prismic'
import { isValidLink } from 'utils/client'
import { getBaseUrl, getNavigationIcon } from 'utils'
import clsx from 'clsx'
import ChatNow from '../order/ChatNow'
import type { GlobalDocument, NavDropdownLinkGroupDocument } from 'types/types.generated'

type Props = {
	link: NavDropdownLinkGroupDocument
	navTextContrast?: 'light' | 'dark'
	setOpenNavDrawer: Dispatch<SetStateAction<number>>
	setIsMobileNavOpen?: Dispatch<SetStateAction<boolean>>
	analyticalClickFactory?: (value: string) => () => void
	globalData?: GlobalDocument
}
export default function MobileNavLinkOption({
	link,
	navTextContrast,
	setOpenNavDrawer,
	setIsMobileNavOpen,
	analyticalClickFactory,
	globalData
}: Props) {
	const { title, links } = link.links.data || {}

	return title ? (
		<div>
			{/* Mobile Nav Section Title */}
			{title?.[0]?.text ? (
				<PlainText
					as="h3"
					content={title[0].text}
					className="uppercase font-medium text-gray-400 pl-6"
				/>
			) : null}

			<div
				className={clsx('grid grid-cols-1 gap-y-6 mt-4 max-w-lg', {
					['grid-cols-2 gap-y-10 pt-4']: links[0]?.icon
				})}>
				{links.map((linkObj, i) => {
					const { link, button_label, icon } = linkObj || {}

					const iconValues = icon?.split(' ') || ''
					const iconStyle = iconValues?.[iconValues?.length - 2]

					return isValidLink(link) && button_label ? (
						link.type === 'launch_chat' ? (
							<ChatNow
								key={i}
								text={button_label}
								textClassName="font-medium"
								className={clsx('flex flex-col gap-2 font-medium w-full', {
									['text-center items-center text-sm']: icon,
									['ml-12']: !icon,
									['text-white']: navTextContrast === 'light'
								})}
								globalData={globalData}
								icon={
									icon && (
										<Icon className="shrink-0 w-6 h-6 text-g-400" icon={getNavigationIcon(icon)} />
									)
								}
							/>
						) : (
							<Link
								href={link.url ?? getBaseUrl() + linkResolver(link)}
								onClick={() => {
									setOpenNavDrawer(null)
									setIsMobileNavOpen(false)
									if (analyticalClickFactory) {
										analyticalClickFactory(button_label)()
									}
								}}
								key={`link-${i}`}
								className={clsx('flex flex-col gap-2 font-medium', {
									['text-center items-center text-sm']: icon,
									['ml-12']: !icon,
									['text-white']: navTextContrast === 'light'
								})}>
								{icon ? (
									<Icon
										className={clsx(
											'shrink-0 text-g-400 h-7',
											iconStyle === 'fa-kit' ? '[&::before]:text-2xl' : 'w-6'
										)}
										icon={getNavigationIcon(icon)}
									/>
								) : null}
								{button_label}
							</Link>
						)
					) : null
				})}
			</div>
		</div>
	) : null
}
