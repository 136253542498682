import Image from 'next/image'

import { PlainText, PrismicLink } from '../prismic'
import type { GlobalDocument } from 'types/types.generated'

type PreFooterProps = { globalData: GlobalDocument }

export default function PreFooter({ globalData }: PreFooterProps) {
	const { cta_label, cta_link, cta_heading, show_prefooter_cta } = globalData?.data || {}

	return show_prefooter_cta ? (
		<section className="container relative px-10 md:px-20 py-20 lg:mt-16 -mb-32 z-10">
			<Image
				src="https://rstr.in/real-thread/marketing/K8Gp3SG0NGp"
				role="presentation"
				alt=""
				aria-hidden="true"
				width={4034}
				height={774}
				className="lg:container absolute w-full h-full inset-0 object-cover"
				sizes="(max-width: 1600px) 100vw, (max-width: 1024px) 90vw, 1600px"
				quality={70}
			/>
			<div className="flex max-sm:flex-col max-w-[885px] gap-6 items-center justify-between w-full mx-auto">
				<PlainText
					as="h2"
					content={cta_heading}
					className="desktop-h2 font-regular max-w-[350px] z-10 max-sm:text-center "
				/>
				<PrismicLink
					link={cta_link}
					label={cta_label}
					className="button button-dark button-md"
					withArrow
					globalData={globalData}
				/>
			</div>
		</section>
	) : null
}
