import { formatCost } from 'utils'
import Input, { InputType } from './Input'
import clsx from 'clsx'

interface InputNumberType extends InputType {
	surcharge?: number
	alignLeft?: boolean
	inputClassName?: string
}

type Props = {
	noPlaceholder?: boolean
}

export default function InputNumber({
	label,
	surcharge,
	alignLeft,
	noPlaceholder,
	inputClassName,
	...props
}: InputNumberType & Props) {
	return (
		<div>
			<Input
				className={clsx(alignLeft ? 'align-left' : '', 'input--reset input--number')}
				label={label}
				type="tel"
				min={0}
				placeholder={noPlaceholder ? null : '0'}
				onKeyDown={(evt) =>
					// allow only 0-9, and + and Tab
					!['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'Tab'].includes(evt.key) &&
					evt.key !== 'Backspace' &&
					evt.preventDefault()
				}
				maxLength={7}
				inputClassName={inputClassName}
				{...props}
			/>
			{surcharge && (
				<span className="block text-center lowercase text-sm text-g-500 mt-1">
					+{formatCost(surcharge)}
				</span>
			)}
		</div>
	)
}
