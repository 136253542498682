import { ComponentPropsWithoutRef } from 'react'

export interface CheckboxType extends ComponentPropsWithoutRef<'input'> {
	_errored?: boolean
	id: string
	label: string
	cardStyle?: boolean
	icon?: 'lock' | 'check'
	checked?: boolean
	onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
	onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void
	className?: string
}

export default function Checkbox({
	_errored,
	id,
	label,
	cardStyle,
	icon = 'check',
	checked,
	onChange,
	onKeyDown,
	className,
	...props
}: CheckboxType) {
	return (
		<div className={`checkbox ${cardStyle ? 'card-style' : ''} icon-${icon} ${className}`}>
			<input
				id={id}
				type="checkbox"
				checked={checked}
				onChange={onChange}
				onKeyDown={(e) => onKeyDown?.(e)}
				{...props}
			/>
			<label htmlFor={id}>{label}</label>
		</div>
	)
}
