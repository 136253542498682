import Link from 'next/link'

import Icon from '../Icon'
import ChatNow from './ChatNow'
import { getBaseUrl, getNavigationIcon, linkResolver } from 'utils'
import type { GlobalDocument } from 'types/types.generated'

type WrapperProps = {
	link: {
		type: string
		uid: string
		link_type: string
		url?: string
	}
	button_label: string
	globalData: GlobalDocument
	icon?: string
}

export default function Wrapper({
	children,
	link,
	button_label,
	globalData,
	icon
}: React.PropsWithChildren<WrapperProps>) {
	return link.type === 'launch_chat' || link.link_type === 'launch_chat' ? (
		<ChatNow
			text={button_label}
			className="space-x-2 flex items-center group py-1 text-white cursor-pointer hover:text-yellow-mesh"
			textClassName="font-medium -mb-1 whitespace-nowrap"
			globalData={globalData}
			icon={<Icon className="flex-shrink-0 w-3 mr-1" icon={icon && getNavigationIcon(icon)} />}
		/>
	) : link.link_type === 'Web' ? (
		<Link
			href={link.url}
			className="space-x-2 flex items-center group py-1 text-white cursor-pointer hover:text-yellow-mesh">
			{children}
		</Link>
	) : (
		<Link
			href={getBaseUrl() + linkResolver(link)}
			className="space-x-2 flex items-center group py-1 text-white cursor-pointer hover:text-yellow-mesh">
			{children}
		</Link>
	)
}
