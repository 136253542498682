import HelpToolTip from './HelpToolTip'
import snakeCase from 'lodash.snakecase'
import clsx from 'clsx'
import { RichText } from '../prismic'

export default function CheckboxGroup({
	label,
	items,
	help_text,
	disabled,
	register,
	fieldName,
	textContrast,
	alignment,
	hide_label
}) {
	return (
		<div className={clsx('flex flex-col gap-2', textContrast === 'light' && 'text-light')}>
			{label?.[0]?.text && !hide_label ? (
				<div className="flex items-start gap-2">
					<RichText
						className={clsx(
							'rich-text-label sm:col-span-2 font-medium',
							textContrast === 'light' ? 'text-white text-light' : 'text-black-rt'
						)}
						content={label}
					/>
					{help_text ? (
						<HelpToolTip
							helpText={help_text}
							className="mt-0.5"
							textContrast={textContrast}
							disabled={disabled}
						/>
					) : null}
				</div>
			) : null}
			<div
				className={clsx(
					'flex flex-wrap',
					alignment === 'horizontal' ? 'flex-row gap-4' : 'flex-col gap-2'
				)}>
				{items.map((item, i) => {
					const optionName = item?.label
						? snakeCase(item.label)
						: item?.value
						? snakeCase(item.value)
						: null

					return optionName ? (
						<div className="checkbox icon-check" key={i}>
							<input
								className={clsx('', disabled && 'disabled')}
								id={`${fieldName}_${i}`}
								type="checkbox"
								value={item.value}
								{...register(fieldName)}
								disabled={disabled}
							/>
							<label htmlFor={`${fieldName}_${i}`}>
								<RichText
									className={`rich-text-label ${
										textContrast === 'light' ? 'text-white' : 'text-black-rt'
									}`}
									content={item.label}
								/>
							</label>
						</div>
					) : null
				})}
			</div>
		</div>
	)
}
