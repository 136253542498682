import { ReactNode } from 'react'
import Image from 'next/image'
import { ImageJsonLd } from 'next-seo'
import { placeholderProps } from 'utils/client'
import { Icon, PlainText, PrismicLink } from 'ui'
import { faArrowRight } from '@fortawesome/pro-solid-svg-icons'
import type { ArticleDocument } from 'types/types.generated'

type Props = {
	resourceObj: ArticleDocument
	largeImage?: boolean
	roundedImage?: boolean
	className?: string
}

export default function HorizontalCard({ resourceObj, roundedImage, className }: Props) {
	const {
		card_description,
		card_image,
		card_title,
		card_cta_label,
		resource,
		background_color,
		card_text_contrast,
		card_cta_link
	} = resourceObj || {}

	let backgroundColor = background_color
	if (backgroundColor == null || backgroundColor == undefined) {
		backgroundColor = '#F5F5F1'
	}

	// Check for valid prismic data
	const hasLink = resource?.url || resource?.uid
	const hasCTAButton = hasLink && card_cta_label

	// Handle internal vs. external links (resources vs. downloadables)
	const classes = `horizontal-card group w-full relative rounded-lg pr-4 @container
									${card_text_contrast === 'light' ? 'text-white' : 'text-black-rt'}
									${className}
								`

	const LinkWrapper = ({ children }: { children: ReactNode }) =>
		resource?.url ? (
			<a
				className={classes}
				href={resource.url}
				target="_blank"
				style={{ background: backgroundColor }}
				rel="noopener noreferrer">
				{children}
			</a>
		) : (
			<PrismicLink
				link={resource ?? card_cta_link}
				className={classes}
				label={card_cta_label}
				withArrow={card_cta_label ?? false}>
				{children}
			</PrismicLink>
		)

	return (
		<LinkWrapper>
			<div className="flex gap-4 items-center">
				{card_image?.url ? (
					<div
						className={`relative min-w-[8rem] overflow-hidden w-32 h-32 ${
							roundedImage ? 'rounded-lg' : 'rounded-l-lg'
						}`}>
						<Image
							src={`${card_image.url}&w=350&h=350&fit=crop`}
							alt={card_image.alt || 'Image'}
							fill
							sizes="250px"
							className="object-cover scale-image-animation"
							quality={70}
							{...placeholderProps(card_image)}
						/>
						<ImageJsonLd
							contentUrl={card_image.url}
							images={[card_image]}
							creator="Real Thread"
							creditText="Real Thread"
						/>
					</div>
				) : null}

				<div className="space-y-2 w-full py-4">
					<PlainText
						className="desktop-h5 line-clamp-1 animated-underline group-hover:animate"
						content={card_title}
					/>
					<PlainText
						className="desktop-body-sm text-gray-500 line-clamp-2"
						content={card_description}
					/>
					{hasCTAButton ? (
						<button className="button button-secondary pl-0 mb-0 pb-0 text-sm text-left">
							{card_cta_label}
							<Icon
								icon={faArrowRight}
								className="w-3 ml-3 -mt-1 fill-black-rt transition-transform duration-300 ease-in-out group-hover:translate-x-1 w-2.5"
							/>
						</button>
					) : null}
				</div>
			</div>
		</LinkWrapper>
	)
}
