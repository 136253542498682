import { MouseEvent, useEffect, useState } from 'react'
import { useHeadsObserver } from 'hooks/useHeadsObserver'

export default function TableOfContents({ resource }) {
	const [headings, setHeadings] = useState([])
	const { activeId, setActiveId } = useHeadsObserver()

	useEffect(() => {
		const elements = Array.from(document.querySelectorAll('.rich-text .table-of-contents h2')).map(
			(elem) => {
				if (!elem.textContent) return null

				const textToDisplay = elem.textContent?.replace(/:$/, '') ?? ''
				return {
					id: elem.id,
					text: textToDisplay
				}
			}
		)

		setHeadings(elements)
	}, [resource])

	const handleOnCLick = (e: MouseEvent<HTMLAnchorElement, globalThis.MouseEvent>, id: string) => {
		e.preventDefault()
		const target = document.getElementById(id)
		target.scrollIntoView({ behavior: 'smooth', block: 'start' })
		setActiveId(id)
	}

	return headings.length ? (
		<nav className="pb-2 max-w-[350px] border-t border-g-200 pt-12 overflow-y-auto">
			<span className="text-sm block -mb-1 text-g-500">Table of </span>
			<span className="text-sm block mb-10 text-g-500">Contents </span>
			<ol>
				{headings.map((heading, idx) => (
					<li key={heading.id}>
						<a
							href={`#{heading.id}`}
							className={`font-serif text-lg block mb-8 hover:text-black-rt transition-colors duration-300 ease-in-out
										${activeId === heading.id ? 'text-black-rt' : 'text-g-300'}
									`}
							onClick={(e) => {
								handleOnCLick(e, heading.id)
							}}>
							<span className="absolute">
								{idx + 1}
								{'. '}
							</span>
							<p className="pl-5">{heading.text}</p>
						</a>
					</li>
				))}
			</ol>
		</nav>
	) : null
}
