import { useContext } from 'react'

import { SessionContext } from '../context'

const useSession = () => {
	const context = useContext(SessionContext)

	if (context === null) {
		throw new Error('useSession must be used within an SessionProvider')
	}

	return context
}

export default useSession
