import { useRouter } from 'next/router'
import Link from 'next/link'

export default function ExitPreview() {
	const { isPreview } = useRouter()

	return (
		<>
			{isPreview ? (
				<Link
					href="/api/exit-preview"
					passHref
					className="fixed bottom-8 right-16 inline-flex w-44 pt-4 pb-3 px-3 box-border items-baseline 
					bg-yellow-rt text-gray-900 justify-center leading-4 font-bold 
					no-underline rounded-md cursor-pointer z-[60]">
					Exit Preview
				</Link>
			) : null}
		</>
	)
}
