import { memo, useCallback, useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { faArrowRight } from '@fortawesome/pro-solid-svg-icons'

declare global {
	interface Window {
		grecaptcha: any
	}
}

import { isNotEmptyArray, isNotEmptyObject } from 'utils/client'
import { Error, Icon } from 'ui'

import { FormFields, ConfirmationText, FormSkeleton } from 'ui/form'
import clsx from 'clsx'
import { processFormData, useAnalyticalDataContext, useCustomerLabsContext } from 'analytics'
import prismicClient from 'config/prismic.config'
import type { FormDocument } from 'types/types.generated'
import { submitForm } from 'utils/forms/form'

type Props = {
	form: FormDocument
	alignButtonOnLeft?: boolean
	textContrast?: 'light' | 'dark'
	formWidth?: 'small' | 'medium'
}

const Form = ({ form, alignButtonOnLeft = false, textContrast, formWidth }: Props) => {
	const { data } = form || {}
	const [formFields, setFormFields] = useState(data?.body || [])
	const [endpoint, setEndpoint] = useState(data?.endpoint || null)
	const [form_cta_label, setFormCtaLabel] = useState(data?.form_cta_label || null)
	const [confirmation_text, setConfirmationText] = useState(data?.confirmation_text || null)

	useEffect(() => {
		if (!data) {
			const getData = async () => {
				const { data } = await prismicClient().getByID(form?.id)

				setFormFields(data.body)
				setEndpoint(data.endpoint)
				setFormCtaLabel(data.form_cta_label)
				setConfirmationText(data.confirmation_text)
			}
			getData()
		}
	}, [form])

	const confirmationText =
		confirmation_text ?? "Thank you for contacting us. We'll be in touch shortly."

	// Check for valid data from Prismic
	const hasValidForm = !!isNotEmptyArray(formFields)

	// Form logic
	const { handleSubmit, register, reset, watch, control } = useForm()
	const [isSubmitted, setIsSubmitted] = useState(false)

	const [loading, setLoading] = useState(false)
	const [error, setError] = useState(null)
	const track = useAnalyticsFormIdentify(form)

	const onSubmit = async (data: FormData) => {
		setLoading(true)
		if (isNotEmptyObject(data)) {
			try {
				setError(null)
				await submitForm(data, {
					endpoint: endpoint.url,
					useRecaptcha: true,
					onSuccess: (sanitizedData) => {
						reset()
						setIsSubmitted(true)
						track(sanitizedData)
					},
					onError: (errorMessage) => {
						console.log('Error submitting form', errorMessage)
						setError(errorMessage)
					}
				})
			} catch (e) {
				console.log('Error submitting form', e)
				setError('Error submitting form. Please try again.')
			} finally {
				setLoading(false)
			}
		}
	}

	return (
		<form
			className={clsx('form w-full mx-auto', {
				'max-w-[480px]': formWidth === 'small',
				'max-w-[708px]': formWidth === 'medium'
			})}
			onSubmit={handleSubmit(onSubmit)}>
			{hasValidForm ? (
				<FormFields
					className="form-grid"
					formFields={formFields}
					control={control}
					register={register}
					watch={watch}
					textContrast={textContrast}
					disabled={loading || isSubmitted}
				/>
			) : (
				<FormSkeleton />
			)}

			{confirmation_text && isSubmitted ? (
				<ConfirmationText text={confirmationText} textContrast={textContrast} />
			) : (
				<button
					type="submit"
					className={clsx(
						'submit inline-flex items-center pb-[6px] button',
						textContrast === 'light' ? 'button-light' : 'button-dark',
						alignButtonOnLeft ? 'self-start' : 'self-end'
					)}
					disabled={loading}>
					{form_cta_label ?? 'Submit'}
					<Icon icon={faArrowRight} className="mb-1 right-arrow-hover text-sm" />
				</button>
			)}

			{error && <Error>{error}</Error>}
		</form>
	)
}

function useAnalyticsFormIdentify(form: FormDocument) {
	const { identify, trackSubmit } = useCustomerLabsContext()
	const { page_url, page_title } = useAnalyticalDataContext()
	const { data, type, slug } = form

	const [form_name, form_type] = useMemo(() => {
		let name: string = 'Form'
		if (data?.form_name && data?.form_name.length > 0) name = data.form_name[0]?.text
		else name = slug || 'Form'
		return [name, type]
	}, [data, type, slug])

	const cb = useCallback(
		(sanitizedData: any) => {
			try {
				const { identifyData, submitData } = processFormData(sanitizedData, {
					form_name,
					form_type,
					page_title,
					page_url
				})
				try {
					identify(identifyData)
				} catch (err) {
					console.error(err)
				}
				try {
					trackSubmit('Form Submit', submitData)
				} catch (err) {
					console.error(err)
				}
			} catch (error) {
				console.error('Form Submit Tracking Fail', error)
			}
		},
		[identify, trackSubmit, page_url, page_title, form_name, form_type]
	)

	return cb
}

export default memo(Form)
