import { getStyleCategories } from 'hooks/graphql/legacy-api/queries/getStyleCategories'
const getProductCategoryLinks = async (cta_link) => {
	const { data: categoriesResult } = (await getStyleCategories()) || { data: null }
	const paths = []

	if (categoriesResult?.styleCategories) {
		categoriesResult.styleCategories.forEach((category) => {
			if (category?.slug) {
				paths.push({ params: { slug: [category.slug] } })

				// Add subcategories
				if (Array.isArray(category.styleSubcategories)) {
					category.styleSubcategories.forEach((subcategory) => {
						if (category.slug && subcategory?.slug) {
							paths.push({ params: { slug: [category.slug, subcategory.slug] } })
						}
					})
				}
			}
		})
	}

	const ctaUidFormatted = cta_link.uid.split('-')

	// find the category in the paths array
	const category = paths.find((path) => {
		return (
			(path.params.slug.length === 1 && cta_link.uid === path.params.slug[0]) ||
			(path.params.slug.length >= 1 &&
				ctaUidFormatted.length >= 2 &&
				((path.params.slug[0] === ctaUidFormatted[0] &&
					ctaUidFormatted[1] === path.params.slug[1] &&
					ctaUidFormatted.length === 2) ||
					(ctaUidFormatted.length >= 4 &&
						((path.params.slug[0] === ctaUidFormatted[0] + '-' + ctaUidFormatted[1] &&
							path.params.slug[1] === ctaUidFormatted[2] + '-' + ctaUidFormatted[3]) ||
							(path.params.slug[0] === ctaUidFormatted[0] &&
								path.params.slug[1] ===
									ctaUidFormatted[1] + '-' + ctaUidFormatted[2] + '-' + ctaUidFormatted[3]))) ||
					(ctaUidFormatted.length === 3 &&
						((path.params.slug[0] === ctaUidFormatted[0] &&
							path.params.slug[1] === ctaUidFormatted[1] + '-' + ctaUidFormatted[2]) ||
							(path.params.slug[0] === ctaUidFormatted[0] + '-' + ctaUidFormatted[1] &&
								path.params.slug[1] === ctaUidFormatted[2])))))
		)
	})

	return {
		data: {
			salesforce_category: {
				slug: category?.params.slug
					? category.params.slug.length === 1
						? category.params.slug[0]
						: category.params.slug.join('/')
					: null
			}
		}
	}
}

export default getProductCategoryLinks
