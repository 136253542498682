import { useEffect, useState } from 'react'
import { Icon } from 'ui'
import clsx from 'clsx'

type Props = {
	category: {
		name: string
		lineIcon: {
			prefix: string
			iconName: string
		}
		solidIcon: {
			prefix: string
			iconName: string
		}
	}
	lineIconColor?: string
	selected?: boolean
	className?: string
}

export default function CategoryIcon({
	category,
	lineIconColor = 'text-black-rt',
	selected = false,
	className
}: Props) {
	const [clicked, setClicked] = useState(false)
	const { name, lineIcon, solidIcon } = category || {}

	useEffect(() => {
		setClicked(false)
	}, [selected])

	return (
		<div
			className={clsx(
				className ?? '',
				'relative flex flex-col justify-center items-center gap-x-3 gap-y-2 group hover:cursor-pointer'
			)}>
			{/* Outline Icon */}
			<Icon
				icon={lineIcon}
				className={clsx(
					'text-center',
					!selected
						? 'transition ease-in-out duration-300 group-hover:-translate-y-2 group-hover:text-black-rt text-g-400'
						: '',
					lineIconColor,
					'h-7  text-3xl -mt-1 z-10',
					lineIcon?.prefix === 'fa-kit' ? 'pb-[30px]' : 'w-8',
					'transition-bg ease-in-out duration-500'
				)}
			/>
			{/* Solid Yellow Icon */}
			<Icon
				icon={solidIcon}
				className={clsx(
					'absolute top-0 text-center',
					'transition-bg ease-in-out duration-500',
					selected || clicked ? 'opacity-1' : 'opacity-0',
					'h-7  text-3xl -mt-1 text-yellow-mesh',
					solidIcon?.prefix === 'fa-kit' ? 'pb-[30px]' : 'fa-solid w-8',
					!selected
						? 'transition-transform ease-in-out duration-300 group-hover:-translate-y-2'
						: ''
				)}
			/>
			<p
				className={`font-medium whitespace-nowrap 
					animated-underline animated-underline-black group-hover:animate
					${selected ? 'text-black-rt' : 'text-g-400 group-hover:text-black-rt'}
				`}>
				{name}
			</p>
		</div>
	)
}
