import { faQuestionCircle } from '@fortawesome/pro-solid-svg-icons'
import { Icon } from 'ui'
import clsx from 'clsx'

type Props = {
	helpText: string
	disabled?: boolean
	textContrast?: 'light' | 'dark'
	className?: string
	shiftLeft?: boolean
}

export default function HelpToolTip({
	helpText,
	disabled,
	textContrast,
	shiftLeft,
	className
}: Props) {
	return (
		<div className={`group relative flex ${className ?? ''} ${disabled && 'opacity-50'}`}>
			{/* Question Icon */}
			<Icon icon={faQuestionCircle} className="text-g-400 cursor-help" />
			{/* Tooltip */}
			<div
				className={clsx(
					'help-tooltip max-w-[150px]',
					'hover-tooltip',
					textContrast === 'dark' && 'dark',
					shiftLeft && 'help-tooltip-shift-left'
				)}>
				{helpText}
			</div>
		</div>
	)
}
