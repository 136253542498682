import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useRouter } from 'next/router'
import { LoginFlow } from '@ory/client'

import { Error, Spinner, InputPassword } from 'ui'
import { Modal } from 'ui/modals'

import { useSession, getOryLoginFlow, handleFlowError } from 'auth'
import { SignInWithGoogle } from 'ui/user'

export default function ModalRefreshSession({
	show,
	setShow,
	userInfo,
	settingsValues,
	csrfToken,
	handleUpdateProfile
}) {
	const [loginFlow, setLoginFlow] = useState<LoginFlow>()
	const [loading, setLoading] = useState<boolean>(false)

	const router = useRouter()
	const {
		return_to: returnTo,
		// Authorization Assurance Level. This implies that we want to upgrade the AAL, meaning that we want
		// to perform two-factor authentication/verification.
		authorizationAssuranceLevel
	} = router.query

	const { control, handleSubmit } = useForm()

	const { loginWithEmailAndPassword, authenticationMethods } = useSession()

	useEffect(() => {
		getOryLoginFlow(
			loginFlow,
			loginFlow?.id,
			setLoginFlow,
			router,
			true,
			returnTo,
			authorizationAssuranceLevel
		)
	}, [router, authorizationAssuranceLevel, returnTo, loginFlow])

	const onSubmit = async (values: { password: string }) => {
		setLoading(true)
		try {
			await loginWithEmailAndPassword(
				{
					password: values.password,
					identifier: userInfo.email,
					csrf_token: csrfToken,
					method: 'password'
				},
				loginFlow
			)
			handleUpdateProfile(settingsValues)
			setShow(false)
			setLoginFlow(null)
		} catch (error) {
			try {
				await handleFlowError(error, router, 'login', setLoginFlow)
			} catch (error) {
				// If the previous handler did not catch the error it's most likely a form validation error
				if (error.response?.status === 400) {
					// Yup, it is!
					setLoginFlow(error.response?.data)
					return
				}

				return Promise.reject(error)
			}
		} finally {
			setLoading(false)
		}
	}

	return (
		<Modal
			isOpen={show}
			setIsOpen={setShow}
			panelClassName="max-w-[630px] bg-warm-gray-100 p-10"
			aria-label="Refresh Session">
			<h2 className="text-2xl sm:text-3xl md:text-4xl text-left md:text-center font-serif mb-4 md:mb-6 mr-10 md:ml-10">
				Refresh Session
			</h2>
			{authenticationMethods?.[0]?.method === 'oidc' ? (
				<div className="flex flex-col space-y-6">
					<p className="pb-4 text-sm">Please login to refresh your session.</p>
					<SignInWithGoogle
						action={loginFlow?.ui?.action}
						csrfToken={csrfToken}
						label="Log in with Google"
					/>
				</div>
			) : (
				<div className="mb-6 md:mb-10">
					<form onSubmit={handleSubmit(onSubmit)}>
						<div className="space-y-6 my-6">
							<p className="pb-4 text-sm">Please enter your password to refresh your session.</p>
							<InputPassword
								name="password"
								label="Password"
								control={control}
								rules={{ required: 'Password is required' }}
							/>

							<button type="submit" className="button button-dark w-full" disabled={loading}>
								{loading && <Spinner />}
								Submit
							</button>

							{loginFlow?.ui.messages?.map((message) => (
								<Error key={message.id}>
									{message.id === 4000006 ? 'The provided password is incorrect' : message.text}
								</Error>
							))}
						</div>
					</form>
				</div>
			)}
		</Modal>
	)
}
