import { createElement } from 'react'
import { Elements, Link } from 'prismic-reactjs'
import kebabCase from 'lodash.kebabcase'

import { sanitize, linkResolver } from '..'
import VideoEmbed from 'ui/VideoEmbed'
import PrismicLink from 'ui/prismic/PrismicLink'
import getProductCategoryLinks from 'hooks/prismic/getProductCategoryLinks'

// Function to add unique key to props
const propsWithUniqueKey = (props, key) => Object.assign(props || {}, { key })

// Headings that will have IDs added for anchor tag purposes
export const anchorTagHeadings = [1, 2, 3]

export const htmlSerializer = (type, element, _, children, key, withAnchorIds) => {
	let props = {}
	let parsedChildren = children

	if (withAnchorIds) {
		if (element?.type && element.type.includes('heading') && element.text) {
			const num = element.type.replace('heading', '')

			const id = element?.number
				? `${kebabCase(element.text)}-${element?.number}`
				: kebabCase(element.text)

			props = { id }

			if (anchorTagHeadings.includes(num)) {
				// Add anchor tag to heading
				const anchor = createElement('a', {
					className: 'anchor',
					href: `#${kebabCase(element.text)}`,
					'aria-hidden': 'true',
					tabIndex: -1
				})

				parsedChildren = [anchor, parsedChildren]
			}

			// Create heading element with principal

			return createElement(`h${num}`, propsWithUniqueKey(props, key), parsedChildren)
		}
	}

	switch (type) {
		//  Images
		case Elements.image:
			const linkUrl = element.linkTo ? Link.url(element.linkTo, linkResolver) : null
			const linkTarget =
				element.linkTo && element.linkTo.target
					? { target: element.linkTo.target, rel: 'noopener' }
					: {}
			const wrapperClassList = [element.label || '', 'block-img']
			const img = element?.url ? (
				// eslint-disable-next-line
				<img
					src={element?.url || ''}
					alt={element?.alt || ''}
					copyright={element?.copyright || ''}
				/>
			) : null

			props = { className: wrapperClassList.join(' ') }
			parsedChildren = linkUrl ? (
				<a href={linkUrl} {...linkTarget}>
					{img}
				</a>
			) : (
				img
			)

			return createElement('p', propsWithUniqueKey(props, key), parsedChildren)

		case Elements.embed:
			if (element.oembed.type === 'video') {
				return createElement(
					VideoEmbed,
					propsWithUniqueKey(
						{
							html: element.oembed.html,
							className: 'video'
						},
						key
					)
				)
			}

			props = {
				'data-oembed': element.oembed.embed_url,
				'data-oembed-type': element.oembed.type,
				'data-oembed-provider': element.oembed.provider_name,
				dangerouslySetInnerHTML: { __html: sanitize(element.oembed.html) }
			}

			return createElement('div', propsWithUniqueKey(props, key))

		case Elements.hyperlink:
			const target = element.data.target ? { target: element.data.target, rel: 'noopener' } : {}

			let hyperLinkUrl = Link.url(element.data, linkResolver)

			if (element.data.type === 'product_category') {
				// Create a unique ID for this link
				const linkId = `category-link-${element.data.id}`

				props = {
					target: '_self',
					'data-category-id': element.data.id,
					className: 'product-category-link',
					id: linkId,
					href: '#' // temporary href
				}

				// Only run client-side DOM manipulation in browser environment
				if (typeof window !== 'undefined') {
					getProductCategoryLinks(element.data).then((productCategory) => {
						const linkElement = document.getElementById(linkId)
						if (linkElement) {
							linkElement.href = `/products/${productCategory?.data?.salesforce_category?.slug}`
						}
					})
				}

				return createElement('a', propsWithUniqueKey(props, key), parsedChildren)
			} else if (element.data.type === 'modal' || element.data.type === 'launch_chat') {
				return createElement(
					PrismicLink,
					propsWithUniqueKey(
						{
							link: element.data,
							label: parsedChildren,
							className: `hyperlink`,
							withArrow: false,
							children: parsedChildren
						},
						key
					)
				)
			}

			props = { target, href: hyperLinkUrl }

			if (element.data.link_type === 'Document') {
				// make this a open in same tab
				props.target = '_self'
			}

			return createElement('a', propsWithUniqueKey(props, key), parsedChildren)

		case Elements.label:
			if (element?.data?.label === 'blockquote') {
				return createElement('blockquote', propsWithUniqueKey(props, key), parsedChildren)
			} else {
				props = element.data.label ? { className: element.data.label } : {}

				return createElement('span', propsWithUniqueKey(props, key), parsedChildren)
			}

		case Elements.paragraph:
			if (element?.text === '') {
				return createElement('br', propsWithUniqueKey(props, key))
			}

			return null

		default:
			return null
	}
}
