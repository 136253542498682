import { ReactNode } from 'react'
import Image from 'next/image'
import { ImageJsonLd } from 'next-seo'
import { placeholderProps } from 'utils/client'
import { Icon, PlainText, PrismicLink } from 'ui'
import { faArrowRight } from '@fortawesome/pro-solid-svg-icons'
import Label from 'ui/Label'
import clsx from 'clsx'
import type { ArticleDocument } from 'types/types.generated'

type Props = {
	resourceObj: ArticleDocument
	largeImage?: boolean
	twoColSpanLayout?: boolean
	shadow?: boolean
	className?: string
	analyticalClickFactory?: (value: string) => () => void
	sizes?: string
	onClick?: () => void
}

export default function VerticalCard({
	resourceObj,
	largeImage = true,
	shadow,
	analyticalClickFactory,
	sizes,
	className,
	onClick
}: Props) {
	const {
		card_label,
		card_label_theme,
		card_description,
		card_image,
		card_title,
		card_cta_label,
		resource,
		background_color,
		card_text_contrast,
		card_cta_link
	} = resourceObj || {}

	// Check for valid prismic data
	const hasLink = resource?.url || resource?.uid || card_cta_link
	const hasCTAButton = hasLink && card_cta_label

	// Handle internal vs. external links (resources vs. downloadables)
	const classes = `vertical-card group relative flex flex-col group rounded-lg overflow-hidden
									${className || ''}
									${shadow ? 'shadow-card' : ''}
									${card_text_contrast === 'light' ? 'text-white' : 'text-black-rt'}
									`
	const LinkWrapper = ({ children, onClick }: { children: ReactNode; onClick?: () => void }) =>
		hasLink ? (
			resource?.url ? (
				<a
					className={classes}
					href={resource.url}
					target="_blank"
					style={{ background: background_color }}
					rel="noopener noreferrer"
					onClick={onClick}>
					{children}
				</a>
			) : (
				<PrismicLink
					link={resource ?? card_cta_link}
					className={classes}
					label={card_cta_label}
					withArrow={card_cta_label ?? false}
					onClick={onClick}>
					{children}
				</PrismicLink>
			)
		) : (
			<div className={classes} style={{ background: background_color }}>
				{children}
			</div>
		)

	return (
		<LinkWrapper
			{...(analyticalClickFactory ? { onClick: analyticalClickFactory(card_label) } : {})}
			onClick={onClick}>
			{card_image?.url ? (
				<div
					className={`relative min-w-[8rem] overflow-hidden bg-g-100 mb-4 min-h-[12.5rem] rounded-lg w-full
						${largeImage ? 'h-72' : 'h-44'}
					`}>
					<Image
						src={card_image.url}
						alt={card_image.alt || ''}
						fill
						className={clsx('object-cover', hasLink && 'scale-image-animation')}
						sizes={sizes}
						{...placeholderProps(card_image)}
					/>
					<ImageJsonLd
						contentUrl={card_image.url}
						images={[card_image]}
						creator="Real Thread"
						creditText="Real Thread"
					/>
				</div>
			) : null}

			{card_label ? (
				<Label text={card_label} theme={card_label_theme} className="absolute top-4 left-4" />
			) : null}

			<div className="space-y-2 w-full">
				<PlainText
					className={clsx('desktop-h5', hasLink && 'animated-underline group-hover:animate')}
					content={card_title}
				/>
				<PlainText
					className={`${card_text_contrast === 'light' ? 'text-g-300' : 'text-g-500'}`}
					content={card_description}
				/>
				{hasCTAButton ? (
					<button className="button button-secondary pl-0 mb-0 pb-0">
						{card_cta_label}
						<Icon
							icon={faArrowRight}
							className="w-3 ml-3 -mt-1 fill-black-rt transition-transform duration-300 ease-in-out group-hover:translate-x-1"
						/>
					</button>
				) : null}
			</div>
		</LinkWrapper>
	)
}
