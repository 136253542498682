import { Dialog, Transition } from '@headlessui/react'
import { Fragment, ReactNode, useEffect } from 'react'
import type { Dispatch, SetStateAction } from 'react'
import { Symbol } from 'ui'
import clsx from 'clsx'
import { submitForm } from 'utils/forms/form'

type DataForSubmit = {
	email?: string
	quantity_est?: string
	order_name?: string
	product_label?: string
	cart_id?: string
	product_name?: string
	url?: string
}

type ModalProps = {
	children: ReactNode | string
	isOpen: boolean
	setIsOpen: Dispatch<SetStateAction<boolean>>
	animateFromTop?: boolean
	onClose?: () => void
	afterLeave?: () => void
	closeButtonClassName?: string
	modalClassName?: string
	panelClassName?: string
	restrictClose?: boolean
	hideCloseButton?: boolean
	isSaveForLater?: boolean
	saveForLaterData?: DataForSubmit
	shouldSubmit?: boolean
}
export default function Modal({
	children,
	isOpen,
	setIsOpen,
	animateFromTop,
	onClose,
	afterLeave,
	closeButtonClassName,
	modalClassName,
	panelClassName,
	restrictClose,
	hideCloseButton,
	isSaveForLater,
	saveForLaterData,
	shouldSubmit
}: ModalProps) {
	// This prevents a headless UI bug where the body jumps to the top when opening a modal on mobile
	useEffect(() => {
		const handleScrollBehavior = () => {
			if (isOpen) {
				document.documentElement.style.scrollBehavior = 'unset'
			} else {
				document.documentElement.style.scrollBehavior = 'auto'
			}
		}
		handleScrollBehavior()
		return () => {
			document.documentElement.style.scrollBehavior = 'auto'
		}
	}, [isOpen])

	const handleSaveForLater = async () => {
		await submitForm(
			{
				email: saveForLaterData.email,
				quantity_est: saveForLaterData.quantity_est,
				order_name: saveForLaterData.order_name,
				product_label: saveForLaterData.product_label,
				cart_id: saveForLaterData.cart_id,
				product_name: saveForLaterData.product_name,
				url: saveForLaterData.url
			},
			{
				endpoint: 'https://usebasin.com/f/10d08d5c3b4e',
				useRecaptcha: true
			}
		)
	}

	return (
		<Transition show={isOpen} as={Fragment} afterLeave={afterLeave}>
			<Dialog
				onClose={() => {
					if (restrictClose) return
					if (isSaveForLater && shouldSubmit) {
						handleSaveForLater()
					}
					setIsOpen(false)
				}}
				className="fixed inset-0 layout sm:px-[var(--s)] items-center z-50">
				<Transition.Child
					as={Fragment}
					enter="transition ease-out duration-300 transform"
					enterFrom="-translate-x-full"
					enterTo="translate-x-0"
					leave="transition ease-in duration-300 transform"
					leaveFrom="translate-x-0"
					leaveTo="-translate-x-full">
					<Dialog.Overlay
						className="fixed inset-0 bg-black-rt bg-opacity-75 transition-opacity"
						onClick={() => {
							if (!restrictClose) {
								onClose?.()
								setIsOpen(false)
							}
						}}
					/>
				</Transition.Child>
				{/* Overlay */}
				<div className="fixed inset-0 z-10">
					{/* Modal Container */}
					<div
						className={`modal-container flex h-full justify-center items-center text-center px-2 py-10 sm:px-4 ${modalClassName}`}>
						<Transition.Child
							as={Fragment}
							enter="transition ease-out duration-200 transform"
							enterFrom={`${animateFromTop ? '-translate-y-10 ' : 'translate-y-10'} opacity-0`}
							enterTo="translate-y-0 opacity-100"
							leave="transition ease-in duration-200 transform"
							leaveFrom="translate-y-0 opacity-100"
							leaveTo={`${animateFromTop ? '-translate-y-10 ' : 'translate-y-10'} opacity-0`}>
							{/* Modal Panel */}
							<Dialog.Panel className={`modal modal-panel ${panelClassName || ''}`}>
								<button
									className={clsx('modal-close', hideCloseButton && 'hidden', closeButtonClassName)}
									onClick={() => {
										if (isSaveForLater && shouldSubmit) {
											handleSaveForLater()
										}
										onClose?.()
										setIsOpen(false)
									}}>
									<Symbol id="close" title="Close modal" />
								</button>
								<div className={clsx(!hideCloseButton && '-mt-12', panelClassName)}>{children}</div>
							</Dialog.Panel>
						</Transition.Child>
					</div>
				</div>
			</Dialog>
		</Transition>
	)
}
