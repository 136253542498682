import { Dialog, Transition } from '@headlessui/react'

import { Fragment } from 'react'
import type { Dispatch, SetStateAction } from 'react'

import TestimonialCard from './TestimonialCard'
import type { TestimonialDocument } from 'types/types.generated'
type TestimonialModalProps = {
	isOpen: boolean
	setIsOpen: Dispatch<SetStateAction<boolean>>
	review: TestimonialDocument
}
export default function TestimonialModal({ isOpen, setIsOpen, review }: TestimonialModalProps) {
	return (
		<Transition show={isOpen} as={Fragment}>
			<Dialog onClose={() => setIsOpen(false)}>
				<Transition.Child
					as={Fragment}
					enter="ease-out duration-300"
					enterFrom="opacity-0"
					enterTo="opacity-100"
					leave="ease-in duration-200"
					leaveFrom="opacity-100"
					leaveTo="opacity-0">
					<Dialog.Overlay
						className="bg-black-rt/30 fixed inset-0 z-50"
						onClick={() => setIsOpen(false)}
					/>
				</Transition.Child>

				<Transition.Child
					as={Fragment}
					enter="ease-out duration-300"
					enterFrom="opacity-0 scale-95"
					enterTo="opacity-100 scale-100"
					leave="ease-in duration-200"
					leaveFrom="opacity-100 scale-100"
					leaveTo="opacity-0 scale-95">
					<Dialog.Panel
						className={`bg-white rounded-xl fixed 
						top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2 
						max-w-lg flex flex-col w-[95%] md:w-4/5 lg:w-auto max-h-[90vh] overflow-y-auto z-50`}>
						<TestimonialCard asModal setIsOpen={setIsOpen} review={review} bgColor="#FFF" />
					</Dialog.Panel>
				</Transition.Child>
			</Dialog>
		</Transition>
	)
}
