import { ReactNode } from 'react'
import { linkResolver } from 'utils/prismic'
import { PlainText } from '../'
import NavLinkOptions from './NavLinkOptions'
import VerticalCard from '../VerticalCard'
import type { GlobalDocument, NavDropdownLinkGroupDocument } from 'types/types.generated'

type DropdownContentProps = {
	dropdown: NavDropdownLinkGroupDocument
	setIsOpen: (value: boolean) => void
	globalData?: GlobalDocument
	analyticalClickFactory?: (value: string) => () => void
}

export default function DropdownContent({
	dropdown,
	setIsOpen,
	globalData,
	analyticalClickFactory
}: DropdownContentProps) {
	const { bottom_link_grid, link_groups, informational_card } = dropdown.dropdown.data || {}

	return (
		<>
			{/* Upper section */}
			<div className="container flex justify-between space-x-16 pt-8 pb-11">
				{/* Right-side links */}
				{Array.isArray(link_groups)
					? link_groups.map((group, i) => (
							<NavLinkOptions
								link={group}
								setIsOpen={setIsOpen}
								key={i}
								globalData={globalData}
								analyticalClickFactory={analyticalClickFactory}
							/>
					  ))
					: null}

				{informational_card?.map((card, i) => {
					return (
						<VerticalCard
							key={i}
							resourceObj={card}
							largeImage={card.large_image}
							className="w-1/3 max-w-[366px]"
							analyticalClickFactory={analyticalClickFactory}
							sizes="385px"
							onClick={() => setIsOpen(false)}
						/>
					)
				})}
			</div>

			{/* Bottom text CTAs */}
			{Array.isArray(bottom_link_grid) &&
			bottom_link_grid.some((field) => !!(field?.heading?.[0]?.text && field.text?.[0]?.text)) ? (
				<div className="grid grid-cols-3 gap-12 container py-8">
					{bottom_link_grid.map((field, i) => {
						const { heading, text, link } = field || {}

						const Wrapper = ({ children }: { children: ReactNode }) =>
							link?.url || link?.uid ? (
								<a
									href={linkResolver(link)}
									className="active:bg-white transition-colors duration-300 rounded-lg py-4 max-w-sm"
									{...(link?.target === '_blank'
										? { target: '_blank', rel: 'noopener noreferrer' }
										: {})}>
									{children}
								</a>
							) : (
								<div className="rounded-lg py-4 max-w-sm">{children}</div>
							)

						return heading?.[0]?.text && text?.[0]?.text ? (
							<Wrapper key={i}>
								<PlainText as="h4" content={heading} className="font-medium text-lg mb-2" />
								<PlainText content={text} className="text-sm text-gray-600" />
							</Wrapper>
						) : null
					})}
				</div>
			) : null}
		</>
	)
}
