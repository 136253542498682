import { useRef, Fragment } from 'react'
import { connectStateResults } from 'react-instantsearch-dom'
import { Transition } from '@headlessui/react'
import { useClickAway } from 'react-use'
import Link from 'next/link'
import Image from 'next/image'
import { ImageJsonLd } from 'next-seo'

import linkResolver from '../../utils/prismic/link-resolver'
import Label from '../Label'

const Hits = ({
	searchState,
	searchResults,
	isResultsOpen,
	setIsResultsOpen,
	setSearchOpen,
	className = ''
}) => {
	// Specific states related to search query
	const isEmptyQuery = !searchState?.query
	const noResults = !isEmptyQuery && searchResults?.hits.length === 0

	// Close results drawer on click outside
	const drawerRef = useRef<HTMLDivElement>(null)

	useClickAway(drawerRef, (e) => {
		// Get the clicked element
		const clickedElement = e.target as HTMLElement

		// Check if click is inside the search input or drawer
		const isSearchInput = clickedElement.closest('input[id="search"]')
		const isInsideDrawer = drawerRef.current?.contains(clickedElement)

		// Only close if click is outside both the search input and drawer
		if (!isSearchInput && !isInsideDrawer) {
			setIsResultsOpen(false)
		}
	})

	const getResultType = (type: string) => {
		switch (type) {
			case 'product':
				return 'Products'
			case 'page':
				return 'Pages'
			case 'help_category':
			case 'help_subcategory':
			case 'help_center':
				return 'Help Center'
			case 'article':
				return 'Blog Post'
			default:
				return 'Unknown'
		}
	}

	return (
		<Transition
			as={Fragment}
			show={isResultsOpen && !isEmptyQuery}
			enter="transition ease-in-out duration-300 transform"
			enterFrom="opacity-0"
			enterTo="opacity-100"
			leave="transition ease-in-out duration-300 transform"
			leaveFrom="opacity-100"
			leaveTo="opacity-0">
			<div
				className={`bg-warm-gray-200  p-3 relative z-0 w-full max-h-full overflow-y-auto 
				  mt-4 transition ease-out text-black-rt flex flex-col flex-grow 
					overflow-scroll rounded-3xl space-y-4 scroll-bar-invisible ${className}`}
				ref={drawerRef}>
				{noResults ? (
					<p className={`p-4 bg-g-50`}>No results found!</p>
				) : Array.isArray(searchResults?.hits) ? (
					<>
						{searchResults.hits.map((hit: any, i: number) => {
							const { objectID, uid, url, type, title, summary, image, color } = hit || {}

							return (uid || url) && type ? (
								// Search Result Item
								<Link
									key={objectID}
									href={linkResolver({
										uid,
										type,
										data: {
											category: {
												uid:
													type === 'article'
														? 'blog'
														: type === 'help_category' ||
														  type === 'help_center' ||
														  type === 'help_subcategory'
														? 'help-center'
														: type
											},
											color: color || undefined
										}
									})}
									className="flex flex-col sm:flex-row shrink-0
										bg-white rounded-[22px] border border-g-200/50 hover:border-black-rt text-black-rt 
										space-x-none sm:space-x-4 inline-block 
										overflow-hidden
										transition-colors duration-300 ease-in-out"
									tabIndex={i}
									onClick={(e) => {
										if (type === 'product') {
											// Force page refresh for product navigation
											window.location.href = linkResolver({
												uid,
												type,
												data: {
													category: { uid: type },
													color: color || undefined
												}
											})
											e.preventDefault()
										}
										setSearchOpen(false)
									}}>
									{/* Image */}
									<div className="relative w-full sm:w-24 h-32 sm:h-24 bg-gray-300 shrink-0">
										{image ? (
											<>
												<Image
													src={image}
													alt={title || summary || 'Image'}
													width={100}
													height={100}
													className="object-cover w-full sm:w-24 h-32 sm:h-24"
													sizes="96px"
													quality={70}
												/>
												<ImageJsonLd
													contentUrl={image}
													images={[image]}
													creator="Real Thread"
													creditText="Real Thread"
												/>
											</>
										) : null}
									</div>

									{/* Title, Summary & Label*/}
									<div className="flex flex-col sm:flex-row sm:justify-between items-end sm:items-center w-full p-4 sm:pr-4">
										<div className="text-left w-full sm:w-3/4">
											<h3 className="text-sm font-medium font-serif mb-1">
												{Array.isArray(title) ? title[0].text : title}
											</h3>
											<p className="text-[13px] line-clamp-1">{summary || 'See more...'}</p>
										</div>
										<Label text={getResultType(type)} className="mt-2 sm:mt-0" />
									</div>
								</Link>
							) : null
						})}
					</>
				) : null}
			</div>
		</Transition>
	)
}

export default connectStateResults(Hits)
