import { Symbol } from 'ui'

const SignInWithGoogle = ({ csrfToken, label, action }) => {
	return (
		<form method="POST" action={action}>
			{csrfToken && <input type="hidden" name="csrf_token" value={csrfToken} />}

			<button
				type="submit"
				className="button button-outline button-lg w-full space-x-2"
				name="provider"
				value="google">
				<Symbol id="google" className="w-6 h-6 -mt-1" />
				<span>{label ?? 'Sign up with Google'}</span>
			</button>
		</form>
	)
}

export default SignInWithGoogle
