export default function RadioInput({
	optionName,
	item,
	register,
	fieldName,
	disabled,
	textContrast
}) {
	return (
		<div className="group flex items-center gap-1">
			<input
				id={`${optionName}_${item.label}`}
				type="radio"
				value={item.value}
				disabled={disabled}
				{...register(fieldName)}
				className="w-5 h-5 focus:ring-0 focus-visible:ring text-black-rt transition-colors duration-300 cursor-pointer group-hover:bg-g-100/75 group-focus:bg-g-100/75 checked:bg-black-rt disabled:opacity-50"
			/>
			<label
				htmlFor={`${optionName}_${item.label}`}
				className={`${
					textContrast === 'light' ? 'text-white' : 'text-black-rt mt-1 cursor-pointer'
				}`}>
				{item.label}
			</label>
		</div>
	)
}
