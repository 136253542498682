import Link from 'next/link'

import MobileNavOptions from './MobileNavOptions'
import NavLinks from './NavLinks'
import { Icon } from 'ui'
import { faArrowRight, faSearch } from '@fortawesome/pro-solid-svg-icons'
import MobileDrawer from './MobileDrawer'
import { useGlobalSearch } from 'context/GlobalSearchContext'
import { getNavigationIcon, isNotEmptyArray, linkResolver } from 'utils'
import { Dispatch, SetStateAction } from 'react'
import type {
	GlobalDocument,
	GlobalDocumentDataSocialMediaLinksItem,
	NavDropdownLinkGroupDocument
} from 'types/types.generated'

type MobileNavDropdownProps = {
	isMobileNavOpen: boolean
	dropdowns: NavDropdownLinkGroupDocument[]
	setOpenNavDrawer: Dispatch<SetStateAction<number>>
	setIsMobileNavOpen: Dispatch<SetStateAction<boolean>>
	shouldToggleAccountSidebar: boolean
	isLoggedIn: boolean
	setShowAccountMenu: Dispatch<SetStateAction<boolean>>
	openNavDrawer: number
	socialMediaLinks: GlobalDocumentDataSocialMediaLinksItem[]
	analyticalClickFactory?: (_: string) => () => void
	globalData: GlobalDocument
}

export default function MobileNavDropdown({
	isMobileNavOpen,
	dropdowns,
	setOpenNavDrawer,
	setIsMobileNavOpen,
	shouldToggleAccountSidebar,
	isLoggedIn,
	setShowAccountMenu,
	openNavDrawer,
	socialMediaLinks,
	analyticalClickFactory,
	globalData
}: MobileNavDropdownProps) {
	const { setOpenGlobalSearch } = useGlobalSearch()

	return (
		<>
			<div
				className={`mobile-nav bg-g-50
      ${isMobileNavOpen ? 'is-current' : ''}
    `}>
				<div className="container flex flex-col items-start gap-6 !pl-6">
					{/* Search */}
					<button
						type="button"
						onClick={() => {
							setOpenGlobalSearch(true)
							if (analyticalClickFactory) {
								analyticalClickFactory('Search')
							}
						}}
						className="flex gap-3 text-black-rt/40 font-serif text-lg text-left border-b-2 border-black-rt/10 w-full pt-6 pb-5 mb-5">
						<Icon icon={faSearch} className="mt-1.5 text-black-rt" />
						Search for products, services, or resources...
					</button>

					{Array.isArray(dropdowns)
						? dropdowns.map((dropdown, i) => {
								return (
									<MobileNavOptions
										key={i}
										dropdown={dropdown}
										setOpenNavDrawer={setOpenNavDrawer}
										setIsMobileNavOpen={setIsMobileNavOpen}
										navTextContrast="dark"
										analyticalClickFactory={analyticalClickFactory}
									/>
								)
						  })
						: null}

					{shouldToggleAccountSidebar ? (
						<button className="text-left" onClick={() => setShowAccountMenu(true)}>
							<NavLinks linkClass="font-medium text-2xl" isLoggedIn={isLoggedIn} />
						</button>
					) : (
						<NavLinks isLoggedIn={isLoggedIn} linkClass="font-medium text-2xl" />
					)}
				</div>

				<div>
					{/* Mobile Nav Social Icons */}
					{isNotEmptyArray(socialMediaLinks) ? (
						<div className="flex w-full justify-between max-w-xs px-6 py-6 mx-auto mt-auto mb-0">
							{socialMediaLinks.map((linkObj, i) => {
								const { icon, link } = linkObj || {}
								const platform = /fa-([^\s]+)/
									.exec(icon)[1]
									.split('-')
									.map((a) => a.charAt(0).toUpperCase() + a.slice(1))
									.join(' ')

								return icon && link?.url ? (
									<Link
										href={linkResolver(link)}
										key={i}
										className={`h-fit my-auto ${i !== 0 ? 'p-2' : 'py-2 pr-2'}`}
										aria-label={platform}
										{...(analyticalClickFactory
											? { onClick: analyticalClickFactory(platform) }
											: {})}>
										{icon && (
											<Icon
												icon={getNavigationIcon(icon)}
												className="w-6 h-6 fill-white transition-colors duration-300 ease-in-out"
											/>
										)}
									</Link>
								) : null
							})}
						</div>
					) : null}

					{/* <MobileNavFooter
						supportDropdown={supportDropdown}
						ctaLink={primary_cta_link}
						ctaButtonLabel={primary_cta_button_label}
						globalData={globalData}
            			analyticalClickFactory={analyticalClickFactory}
					/> */}

					{/* "Get Started" Link */}
					<a
						href="/products"
						className={`bg-[#000] text-center text-white w-screen block font-medium text-lg py-4 mt-6 
          ${isMobileNavOpen ? 'opacity-100' : 'opacity-0'}
          transition-opacity duration-300
        `}>
						Get Started <Icon icon={faArrowRight} className="ml-2 mb-px text-sm" />
					</a>
				</div>
			</div>

			{/* Mobile nav drawers */}
			{Array.isArray(dropdowns)
				? dropdowns.map((dropdown, i) => {
						return (
							<MobileDrawer
								dropdown={dropdown}
								isOpen={isMobileNavOpen && dropdown.dropdown.id === openNavDrawer}
								setIsMobileNavOpen={setIsMobileNavOpen}
								setOpenNavDrawer={setOpenNavDrawer}
								analyticalClickFactory={analyticalClickFactory}
								key={i}
								globalData={globalData}
							/>
						)
				  })
				: null}
		</>
	)
}
