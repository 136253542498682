import prismicClient from 'config/prismic.config'
import { modalQuery } from 'utils/queries/modal'

export const getModalWithLinks = async (modalId) => {
	let data = await prismicClient()
		.getByID(modalId, {
			graphQuery: modalQuery()
		})
		.catch(() => null)

	if (!data) return null

	return data
}
