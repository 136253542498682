const colors = require('tailwindcss/colors')

module.exports = {
	mode: 'jit',
	content: ['./src/**/*.{js,ts,jsx,tsx}'],
	theme: {
		fontFamily: {
			sans: ['Franklin Gothic Pro', 'sans-serif'],
			serif: ['RZA', 'serif'],
			compressed: ['Franklin Gothic Pro Compressed', 'sans-serif'],
			condensed: ['Franklin Gothic Pro Condensed', 'sans-serif']
		},
		extend: {
			fontSize: {
				xxxs: '10px',
				xxs: '11px'
			},
			colors: {
				yellow: {
					rt: '#f9d248',
					mesh: '#F7FF54'
				},
				green: {
					rt: '#64CF10',
					light: '#eff9e5',
					900: '#2E570F'
				},
				black: {
					rt: '#14191F'
				},
				gray: {
					...colors.stone
				},
				g: {
					50: '#F5F5F1',
					100: '#F3F4F4',
					200: '#D3D4D4',
					300: '#A9A9A9',
					400: '#7E7E7E',
					500: '#545353',
					600: '#4F4F4F',
					900: '#0D1115'
				},
				'warm-gray': {
					100: '#FAFAF9',
					200: '#F5F5F1',
					300: '#EDEDE7',
					400: '#E5E5DE',
					500: '#DBDBD0'
				},
				error: '#EF2D30',
				mockupBg: '#e7e5e5',
				success: '#128157',
				'skeleton-gray': '#F5F5F1',
			},
			backgroundImage: {
				'gradient-1': 'linear-gradient(180deg, #F6F6F2 57%, #F6EED0 100%);',
				'gradient-2': 'linear-gradient(90deg, #FFFFFF 15.9%, rgba(255, 255, 255, 0) 50.04%);'
			},
			container: {
				center: true,
				screens: {
					sm: '640px',
					md: '768px',
					lg: '1024px',
					xl: '1280px',
					'2xl': '1440px',
					'3xl': '1728px'
				}
			},
			screens: {
				'max-xl': { max: '1279px' },
				// => @media (max-width: 1279px) { ... }

				'max-lg': { max: '1023px' },
				// => @media (max-width: 1023px) { ... }

				'max-md': { max: '767px' },
				// => @media (max-width: 767px) { ... }

				'max-sm': { max: '639px' },
				// => @media (max-width: 639px) { ... }

				'max-xs': { max: '374px' },
				// => @media (max-width: 374px) { ... }
				'2xl': '1440px',
				'3xl': '1728px'
			},
			maxWidth: {
				'3xs': '235px',
				'2xs': '280px'
			},
			boxShadow: {
				card: '0px 5px 20px rgba(0, 0, 0, 0.15)',
				select: '0px 4px 4px 0px #14191F0D'
			},
			content: { empty: '""' },
			transitionDuration: {
				smooth: '300ms'
			},
			transitionTimingFunction: {
				smooth: 'cubic-bezier(0.4, 0, 0.2, 1)'
			}
		}
	},
	variants: {
		extend: {}
	},
	plugins: [
		require('@tailwindcss/forms'),
		require('@tailwindcss/typography'),
		require('@headlessui/tailwindcss'),
		require('@tailwindcss/container-queries')
	],
	content: [
		'./pages/**/*.{js,ts,jsx,tsx}',
		'./src/pages/**/*.{js,ts,jsx,tsx}',
		'./src/components/**/*.{js,ts,jsx,tsx}',
		'../../packages/ui/**/*.{js,ts,jsx,tsx}'
	],
	safelist: [
		'col-span-1',
		'col-span-2',
		'col-span-3',
		'col-span-4',
		'col-span-5',
		'col-span-6',
		'col-span-7',
		'col-span-8',
		'col-span-9',
		'col-span-10',
		'col-span-11',
		'col-span-12',

		'brushstroke',

		'scroll-margin-top'
	]
}
