export const slugifyColorName = (colorString: string) => {
	return colorString?.toLowerCase().replace('-', '_').replace(/\s+/g, '-')
}

type Link = {
	uid?: string
	url?: string
	type?: string
	data?: { category?: { uid?: string }; salesforce_category?: { slug?: string }; color: string }
}

export const linkResolver = (doc: Link) => {
	const { url, uid, type, data } = doc || {}

	if (url) {
		return url
	}

	if (uid) {
		if (
			(type === 'article' ||
				type === 'downloadable' ||
				type === 'help_center' ||
				type === 'help_category') &&
			data?.category?.uid
		) {
			return `/${data.category.uid}/${uid}`
		} else if (type === 'article' && !data?.category?.uid) {
			return `/blog/${uid}`
		} else if (type === 'home' || doc.uid === '/') {
			return '/'
		} else if (type === 'product_category') {
			return `/products/${data?.salesforce_category.slug}`
		} else if (type === 'product_spec' || type === 'product') {
			const colorParam = data?.color ? `?color=${slugifyColorName(data.color)}` : ''
			return `/products/${uid}${colorParam}`
		}

		return `/${doc.uid}`
	}

	return '/'
}

export default linkResolver
