import { faClipboardList, faCreditCard } from '@fortawesome/pro-solid-svg-icons'
import { Dispatch, Fragment, SetStateAction } from 'react'
import { Icon, RadioCard } from 'ui'

type Props = {
	creditCardSelected: boolean
	setCreditCardSelected: Dispatch<SetStateAction<boolean>>
}

export default function PaymentOptions({ creditCardSelected, setCreditCardSelected }: Props) {
	return (
		<Fragment>
			<div className="flex flex-wrap gap-2">
				<RadioCard
					id="credit-card"
					name="pay-with-card"
					defaultChecked={creditCardSelected}
					checked={creditCardSelected}
					onChange={(e) => {
						if (e.target.checked) {
							setCreditCardSelected(true)
						}
					}}
					className="flex items-center">
					<Icon icon={faCreditCard} />
					<span className="ml-2">Pay with credit card</span>
				</RadioCard>
				<RadioCard
					id="purchase-order"
					name="pay-with-purchase-order"
					defaultChecked={!creditCardSelected}
					checked={!creditCardSelected}
					onChange={(e) => {
						if (e.target.checked) {
							setCreditCardSelected(false)
						}
					}}
					className="flex items-center">
					<Icon icon={faClipboardList} />
					<span className="ml-2">Pay with purchase order</span>
				</RadioCard>
			</div>
		</Fragment>
	)
}
