import { gql, useQuery } from '@apollo/client'
import apolloClient from 'auth/client/apollo/apolloClient'

const getCart = gql`
	query getCart {
		cart {
			token
			designToken
			address
			address2
			attention
			canvasState
			city
			company
			contactCompany
			country
			currentCartItemQuantity
			email
			firstName
			foil
			foldPoly
			hemTags
			items {
				id
				canvasState
				deliveryOptions
				decorationMethod
				foil
				foldPoly
				hemTags
				inkType
				isLightning
				isRush
				daysRush
				itemStyles {
					style {
						sfid
						category
						fullName
						label
						name
						price
						colors {
							sfid
							hex
							fabricThumbnail
							name
						}
						slug
						tagStyle
					}
					color {
						sfid
						name
					}
					products {
						sfid
						quantity
						sizeId
						unitCost
					}
					productImage {
						sfid
						altText
						styleId
						colorId
						name
						smallImageUrl
						mediumImageUrl
						largeImageUrl
					}
					quantity
					token
				}
				mockupImages {
					id
					location
					name
					url
					transparentUrl
					visible
				}
				name
				notesFront
				numColorsBack
				numColorsFront
				numColorsSleeveLeft
				numColorsSleeveRight
				quantity
				shippingSpeed
				tagPrinting
				threadType
				token
			}
			lastName
			mockupImages {
				id
				location
				name
				url
				transparentUrl
				visible
			}
			phone
			state
			tagPrinting
			zip
		}
	}
`

export const useCart = () => {
	const { data, loading, error, refetch } = useQuery(getCart, {
		client: apolloClient
	})

	return {
		cart: data?.cart,
		loading,
		error,
		refetch
	}
}

export default getCart
