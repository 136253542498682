import { ComponentPropsWithoutRef, FormEvent, ReactElement } from 'react'
import { Control, useController } from 'react-hook-form'

type InputHTMLProps = Omit<ComponentPropsWithoutRef<'input'>, 'defaultValue'>

export interface InputType extends InputHTMLProps {
	inputClassName?: string
	errorMessage?: string
	errored?: boolean
	hideLabel?: boolean
	name: string
	label?: string
	control?: Control<any>
	rules?: any
	pattern?: string
	type?: string
	statusIndicator?: ReactElement
	onChange?: (e: FormEvent<HTMLInputElement>) => void
	maxLength?: number
	defaultValue?: string | number
}

export default function Input({
	className,
	inputClassName,
	errorMessage,
	errored,
	hideLabel,
	name,
	label,
	control,
	rules,
	pattern,
	type,
	statusIndicator,
	onChange,
	maxLength,
	defaultValue,
	...props
}: InputType) {
	const {
		field: { onChange: handleChange, onBlur, name: fieldName, value, ref },
		fieldState: { error }
	} = useController({
		name,
		control,
		rules,
		defaultValue: defaultValue ?? ''
	})

	const errorClass = errored || !!error?.message || !!errorMessage

	return (
		<div
			className={`input ${className ?? ''} 
				${value ? 'input--reset' : ''} 
				${errorClass ? 'input--error' : ''} 
				${!label ? 'input--noLabel' : ''}
			`}>
			{label ? (
				<label htmlFor={name} className={hideLabel ? 'srt' : ''}>
					{label}
					{statusIndicator}
				</label>
			) : null}

			<input
				type={type ? type : 'text'}
				id={name}
				name={fieldName}
				ref={ref}
				onChange={(e) => {
					handleChange(e)
					onChange?.(e)
				}}
				onBlur={onBlur}
				pattern={pattern}
				className={inputClassName}
				maxLength={maxLength}
				value={value === 0 ? '' : value}
				{...props}
			/>

			{(!!error?.message || errorMessage || errored) && !className?.includes('input--boxStyle') && (
				<p className="input-error-message">{error?.message ?? errorMessage}</p>
			)}
		</div>
	)
}
