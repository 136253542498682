import { gql, useMutation } from '@apollo/client'
import { UpdateUserDocument } from 'types/graphql/app/api/__generated__/graphql'
import { apolloClientNewServer } from 'utils/clients/apolloClientNewServer'
import { GET_USER_INFO } from '../queries/useUserInfo'

export const useUpdateUser = () => {
	const [updateUser] = useMutation(UpdateUserDocument, {
		client: apolloClientNewServer,
		refetchQueries: [GET_USER_INFO]
	})

	return {
		updateUser
	}
}

gql(`
	mutation UpdateUser (
		$firstName: String!, 
		$lastName: String!, 
        $authMethod: String,
		$shirtSize: String, 
		$mobilePhone: String, 
		$birthdate: String
		$company: String,
		$surveyResponse: String,
		$estAnnualQuantity: String,
		$use: String,
		$website: String
	) {
		updateUser (
			firstName: $firstName,
			lastName: $lastName,
            authMethod: $authMethod, 
			shirtSize: $shirtSize, 
			mobilePhone: $mobilePhone, 
			birthdate: $birthdate
			company: $company,
			surveyResponse: $surveyResponse,
			estAnnualQuantity: $estAnnualQuantity,
			use: $use
			website: $website
		) {
			__typename
			sfid
			email
			firstname
			lastname
			authMethod
			mobilePhone
			shirtSize
			birthdate
			company
			surveyResponse
			estAnnualQuantity
			use
			website
		}
	}
`)
