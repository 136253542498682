import { ReactNode, ComponentPropsWithoutRef } from 'react'

export interface RadioType extends ComponentPropsWithoutRef<'input'> {
	children?: ReactNode | string
	className?: string
	labelClassName?: string
	id: string
	label?: string
}

export default function RadioCard({
	children,
	className,
	labelClassName,
	id,
	label,
	disabled,
	...props
}: RadioType) {
	return (
		<div className={`radio-card ${className ?? ''} ${disabled ? 'disabled' : ''}`}>
			<input id={id} type="radio" {...props} />
			<label htmlFor={id} className={labelClassName ?? ''}>
				<span>{label || children}</span>
			</label>
		</div>
	)
}
