import { TextareaHTMLAttributes, useRef, useEffect } from 'react'
import { Control, useController } from 'react-hook-form'

interface TextareaType extends TextareaHTMLAttributes<HTMLTextAreaElement> {
	errored?: boolean
	hideLabel?: boolean
	label: string
	name: string
	control?: Control<any>
	rules?: any
	rows?: number
}

export default function Textarea({
	className,
	errored,
	hideLabel,
	label,
	name,
	control,
	rules,
	placeholder,
	rows,
	...props
}: TextareaType) {
	const {
		field: { onChange, name: fieldName, ref, value },
		fieldState: { error }
	} = useController({
		name,
		control,
		rules,
		defaultValue: props.defaultValue ?? ''
	})

	const errorClass = errored || !!error?.message

	const textAreaRef = useRef<HTMLTextAreaElement>(null)

	const resizeTextArea = (textArea: HTMLTextAreaElement | null) => {
		if (textArea) {
			textArea.style.height = 'inherit'
			const scrollHeight = textArea.scrollHeight
			textArea.style.height = scrollHeight + 'px'
		}
	}

	useEffect(() => {
		resizeTextArea(textAreaRef.current)
	}, [value])

	return (
		<div
			className={`input ${className ?? ''} 
				${props.defaultValue || value ? ' input--reset' : ''} 
				${errorClass ? ' input--error' : ''}
			`}>
			<label className={`bg-white pr-2 ${hideLabel ? 'srt' : ''}`} htmlFor={name}>
				{label}
			</label>
			<textarea
				id={name}
				name={fieldName}
				ref={(e) => {
					ref(e)
					textAreaRef.current = e
				}}
				onChange={(e) => {
					onChange(e)
					resizeTextArea(textAreaRef.current)
				}}
				placeholder={placeholder}
				{...props}
				className="-mb-2"
				rows={rows}
			/>
			{!!error?.message && <p className="input-error-message">{error?.message}</p>}
		</div>
	)
}
