import clsx from 'clsx'

type Props = {
	text: string
	theme?: 'dark' | 'light' | 'accent'
	className?: string
}

export default function Label({ text, theme = 'dark', className }: Props) {
	return (
		<span
			className={clsx(
				'rounded-full px-2 pt-1 pb-.5 uppercase text-xs font-semibold font-condensed tracking-[1.25px] w-fit',
				{
					['bg-white text-black-rt']: theme === 'light',
					['bg-black-rt text-white']: theme === 'dark',
					['bg-yellow-mesh text-black-rt']: theme === 'accent'
				},
				className
			)}>
			{text}
		</span>
	)
}
