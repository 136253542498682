import { memo } from 'react'
import snakeCase from 'lodash.snakecase'
import clsx from 'clsx'
import { FormProviderProps } from 'react-hook-form'

import { HelpToolTip, Input, InputPhone, Select, Textarea, RadioGroup, CheckboxGroup } from 'ui'
import { isNotEmptyArray } from 'utils/client'

type Props = {
	className?: string
	formFields: {
		primary: {
			placeholder: string
			alignment: string
			name: string
			required: boolean
			label: {
				text: string
			}
			max_height: string
			type: string
			help_text: string
			hide_label: boolean
		}
		slice_label: string
		slice_type: string
		items: {
			label: string
			value: string
		}[]
	}[]
	control: FormProviderProps['control']
	register: FormProviderProps['register']
	watch: FormProviderProps['watch']
	textContrast?: 'light' | 'dark'
	disabled?: boolean
}

// This component is also used on Form component
const FormFields = ({
	className,
	formFields,
	control,
	register,
	watch,
	textContrast,
	disabled
}: Props) => {
	return (
		<div className={className}>
			{formFields.map((field, i) => {
				const { primary, slice_label, slice_type: sliceType, items } = field || {}
				const { name, required, label, max_height: maxHeight, type, help_text } = primary || {}

				// Get # of columns the field should occupy in the grid
				const fieldCols = (slice_label as string) ?? 'col-span-6'

				// Get field name for back-end
				const fieldName = name
					? snakeCase(name).toLowerCase()
					: label?.[0]?.text
					? snakeCase(label[0].text).toLowerCase()
					: null

				// Check if field has valid data
				const isValidFormField = !!(sliceType && fieldName)
				const hasValidOptions = !!(
					isNotEmptyArray(items) && items.some((item) => !!(item?.label && item?.value))
				)

				const value = watch(fieldName)

				return isValidFormField ? (
					<div
						className={clsx(
							'field',
							sliceType !== 'radio' && sliceType !== 'checkbox' ? 'input' : '',
							`max-sm:col-span-12 ${fieldCols}`,
							value ? ' input--reset' : ''
						)}
						key={i}>
						{sliceType !== 'select' ? (
							<label
								className={clsx(
									sliceType == 'radio' || sliceType == 'checkbox' ? 'form-label' : ''
								)}
								{...(fieldName !== 'checkbox' && fieldName !== 'radio'
									? { htmlFor: fieldName }
									: {})}>
								{label?.[0]?.text ? label[0].text : fieldName}
							</label>
						) : null}

						{sliceType === 'text' ? (
							<div className="flex relative">
								{fieldName === 'phone-number' || type === 'tel' ? (
									<InputPhone
										className="w-full"
										name={fieldName ?? 'phone'}
										control={control}
										disabled={disabled}
										label={label?.[0]?.text}
									/>
								) : (
									<Input
										className="w-full"
										id={fieldName}
										name={fieldName}
										label={label?.[0]?.text}
										control={control}
										defaultValue={type === 'hidden' ? primary.placeholder : undefined}
										type={type ? type : 'text'}
										rules={{
											required: required ? `${label[0].text} is required` : false
										}}
										disabled={disabled}
									/>
								)}
								{help_text ? (
									<div className="absolute right-4 top-4">
										<HelpToolTip
											helpText={help_text}
											disabled={disabled}
											textContrast={textContrast}
											shiftLeft
										/>
									</div>
								) : null}
							</div>
						) : sliceType === 'textarea' ? (
							<div className="relative">
								<Textarea
									className="w-full mr-4"
									style={{ maxHeight }}
									id={fieldName}
									name={fieldName}
									rules={{
										required: required ? `${label[0].text} is required` : false
									}}
									label={label?.[0]?.text}
									control={control}
									disabled={disabled}
								/>
								{help_text ? (
									<div className="absolute top-4 right-4">
										<HelpToolTip
											helpText={help_text}
											disabled={disabled}
											textContrast={textContrast}
											shiftLeft
										/>
									</div>
								) : null}
							</div>
						) : sliceType === 'select' && hasValidOptions ? (
							<div className="relative flex items-center overflow-visible">
								<Select
									key={fieldName}
									name={fieldName}
									label={label?.[0]?.text}
									control={control}
									rules={{
										required: required ? `${label[0].text} is required` : false
									}}
									className="select"
									disabled={disabled}>
									{items.map((item, i) => {
										return (
											<option value={item.value} key={i}>
												{item.label}
											</option>
										)
									})}
								</Select>
								{help_text ? (
									<div className="absolute right-0 bg-transparent rounded mr-10">
										<HelpToolTip
											helpText={help_text}
											disabled={disabled}
											textContrast={textContrast ?? 'dark'}
											shiftLeft
										/>
									</div>
								) : null}
							</div>
						) : sliceType === 'radio' && hasValidOptions ? (
							<RadioGroup
								label={label}
								items={items}
								help_text={help_text}
								disabled={disabled}
								register={register}
								fieldName={fieldName}
								textContrast={textContrast}
								alignment={primary.alignment}
							/>
						) : sliceType === 'checkbox' && hasValidOptions ? (
							<CheckboxGroup
								label={label}
								items={items}
								help_text={help_text}
								disabled={disabled}
								register={register}
								fieldName={fieldName}
								textContrast={textContrast}
								alignment={primary.alignment}
								hide_label={primary.hide_label}
							/>
						) : null}
					</div>
				) : null
			})}
		</div>
	)
}

export default memo(FormFields)
