import { RadioGroup } from '@headlessui/react'
import { useState } from 'react'
import clsx from 'clsx'
import { faArrowRight } from '@fortawesome/pro-regular-svg-icons'
import { Icon } from 'ui'

const customerOptions = [
	{
		id: 1,
		label: "I'm a new customer",
		value: 'new'
	},
	{
		id: 2,
		label: "I'm a returning customer",
		value: 'existing'
	}
]

const ChatCustomerOptions = ({ setCurrentOption, setValue }) => {
	const [selectedValue, setSelectedValue] = useState('')

	const handleOnChange = (value) => {
		setSelectedValue(value)
		setValue('new_existing', value)
	}

	const handleOnSubmit = () => {
		if (selectedValue === 'new') {
			setCurrentOption((prev) => prev + 1)
		} else {
			setCurrentOption((prev) => prev + 2)
		}
	}

	return (
		<div className="flex flex-col items-start gap-2 h-full">
			<p className="font-medium">Are you a new or returning customer?</p>
			<RadioGroup
				name="new_existing"
				value={selectedValue}
				onChange={handleOnChange}
				className="flex gap-3">
				{customerOptions.map((option) => (
					<RadioGroup.Option
						key={option.id}
						value={option.value}
						className={({ checked }) =>
							clsx(
								checked ? 'bg-black-rt text-white' : 'bg-white text-black-rt hover:bg-gray-100',
								'flex items-center  w-1/2 aspect-square font-medium cursor-pointer rounded-lg px-4 border',
								'transition-colors duration-300 ease-out'
							)
						}>
						{option.label}
					</RadioGroup.Option>
				))}
			</RadioGroup>

			<div className="ml-auto mt-auto">
				<button
					type="button"
					className="hyperlink mr-5"
					onClick={() => setCurrentOption((prev) => prev - 1)}>
					Back
				</button>
				{selectedValue !== '' && (
					<button
						type="button"
						className="button button-dark button-md group pb-[3px]"
						onClick={handleOnSubmit}>
						<span className="-mb-1">Next</span>{' '}
						<Icon className="pl-3 text-sm right-arrow-hover" icon={faArrowRight} />
					</button>
				)}
			</div>
		</div>
	)
}

export default ChatCustomerOptions
