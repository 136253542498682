import { Icon } from 'ui'
import { Textarea } from '../form'
import { faArrowRight } from '@fortawesome/pro-regular-svg-icons'

const ChatQuestionText = ({ control, setCurrentOption, newCustomer }) => {
	return (
		<div className="flex flex-col flex-grow">
			<Textarea
				id="message"
				name="message"
				label="How can we help?"
				control={control}
				className="w-full"
			/>
			<div className="ml-auto mt-auto">
				<button
					type="button"
					className="hyperlink mr-5"
					onClick={() =>
						setCurrentOption((prev) => {
							if (newCustomer) {
								return prev - 1
							}
							return prev - 2
						})
					}>
					Back
				</button>
				<button type="submit" className="button button-dark">
					Start chat via text
					<Icon className="pl-3 text-sm right-arrow-hover -mt-1" icon={faArrowRight} />
				</button>
			</div>
		</div>
	)
}

export default ChatQuestionText
