import { createContext, useContext, useMemo, useState } from 'react'

type GlobalSearchContextType = {
	openGlobalSearch: boolean
	setOpenGlobalSearch: (_open: boolean) => void
}

const initialContextValue: GlobalSearchContextType = {
	openGlobalSearch: false,
	setOpenGlobalSearch: null
}

const GlobalSearchContext = createContext(initialContextValue)

export const useGlobalSearch = () => {
	const context = useContext(GlobalSearchContext)

	if (context === undefined) {
		throw new Error('useGlobalSearch must be used within a GlobalSearchProvider')
	}

	return context
}

export const GlobalSearchProvider = ({ children }) => {
	const [openGlobalSearch, setOpenGlobalSearch] = useState(false)

	const value = useMemo(
		() => ({
			openGlobalSearch,
			setOpenGlobalSearch
		}),
		[openGlobalSearch]
	)

	return <GlobalSearchContext.Provider value={value}>{children}</GlobalSearchContext.Provider>
}
