import Link from 'next/link'
import Image from 'next/image'
import { Icon } from 'ui'
import { faArrowRight } from '@fortawesome/pro-regular-svg-icons'

const ChatSuccessMessage = () => {
	return (
		<div className="flex flex-col items-center">
			<p className="text-3xl font-serif mt-28 mb-10">Thanks!</p>

			<div className="flex justify-center mb-10">
				<Image
					src="https://rstr.in/real-thread/marketing/LBlPXElBoTs"
					alt="Save for Later"
					width={136}
					height={125}
					sizes="136px"
					quality={70}
					className="scale-x-[-1]"
				/>
			</div>

			<p className="max-w-[328px] mb-4 mx-10 text-sm">
				You should receive a text any moment now. If you have any other questions, just reply to the
				text.
			</p>

			<p className="max-w-[328px] mb-8 mx-10 text-sm">
				For quick answers, check out our{' '}
				<a href="/help-center" className="hyperlink">
					help center
				</a>
				. Or, give us a call at{' '}
				<a href="tel:4076793895" className="hyperlink">
					(407) 679-3895
				</a>
			</p>

			<Link
				href={'/help-center'}
				type="button"
				className="button button-dark button-md pt-1 pb-[3px]">
				<span className="-mb-1">Help Center</span>
				<Icon className="pl-3 text-sm right-arrow-hover" icon={faArrowRight} />
			</Link>
		</div>
	)
}

export default ChatSuccessMessage
