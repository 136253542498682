import { Input, Select } from '../form'
import clsx from 'clsx'

type ShippingFormFieldProps = {
	field: {
		type: string
		name: string
		label: string
		className?: string
		required?: boolean
		options?: { code: string; state: string }[]
	}
	shippingAddress: any
	control: any
}

export default function ShippingFormField({
	field,
	shippingAddress,
	control
}: ShippingFormFieldProps) {
	switch (field.type) {
		case 'select':
			return (
				// States by country
				<Select
					className="max-sm:col-span-8 col-span-3"
					name={field.name}
					label={field.label}
					defaultValue={shippingAddress[field.name] ? shippingAddress[field.name] : null}
					control={control}
					rules={{ required: field.required ? `${field.label} is required` : false }}>
					{field.options?.map((state: { code: string; state: string }) => (
						<option key={state.code} value={state.code}>
							{state.state}
						</option>
					))}
				</Select>
			)

		case 'text':
			return (
				<Input
					className={clsx({
						'col-span-8': field.name === 'addressStreet',
						'col-span-8 sm:col-span-3':
							field.name === 'addressCity' || field.name === 'addressStateCode',
						'col-span-8 sm:col-span-2': field.name === 'addressPostalCode'
					})}
					name={field.name}
					label={field.label}
					defaultValue={shippingAddress[field.name] ? shippingAddress[field.name] : null}
					control={control}
					rules={{ required: field.required ? `${field.label} is required` : false }}
				/>
			)
	}
}
