import React, { ReactNode, useCallback, useMemo, useState } from 'react'
import { AnalyticalDataProvider } from './context'
import { toCustomerLabsProperty } from './utils'

export function useCartAnalyticsData(cart: any, data?: any) {
	const analyticsData = useMemo(() => {
		let result: any = {}
		if (cart?.items?.length) {
			let orderNames: any[] = []
			let deliveryTypes: any[] = []

			const productProperties = []
			const productData =
				cart?.items?.map((item: any) => {
					if (item?.name) orderNames.push(item?.name)
					if (item?.shippingSpeed) deliveryTypes.push(item?.shippingSpeed?.toLowerCase())

					let productData: any = {}

					item.itemStyles.forEach((item: any) => {
						;(productData['product_name'] = item?.style?.fullName),
							(productData['product_id'] = item?.style?.sfid),
							(productData['product_category'] = item?.style?.category),
							(productData['product_variant'] = item?.color?.name),
							(productData['product_quantity'] = item?.quantity)
						productData['product_size'] = item?.products
							.filter((product: any) => product.quantity)
							?.map((size: any) => `${size.sizeId}:${size.quantity}`)
							?.join('|')
					})

					productProperties.push(toCustomerLabsProperty(productData).v)
					productData['product_ink_colors_front'] = item.numColorsFront
					productData['product_ink_colors_back'] = item.numColorsBack
					productData['product_ink_colors_left'] = item.numColorsSleeveLeft
					productData['product_ink_colors_right'] = item.numColorsSleeveRight
					productData['product_tag_printing'] = item.tagPrinting
					productData['product_hem_tag'] = item.hemTags
					productData['product_foil_printing'] = item.foil
					productData['product_folding_baggin'] = item.foldPolicy

					return productData
				}) || []

			result = {
				...data,
				...result,
				productData,
				productProperties,
				orderNames,
				deliveryTypes,
				order_name: orderNames.join(','),
				order_delivery_type: deliveryTypes.join(',')
			}
		}

		return result
	}, [cart, data])

	return analyticsData
}

export function CartAnalyticsData({
	cart,
	data,
	children
}: {
	cart: any
	data?: any
	children: ReactNode
}) {
	const cartAnalyticsData = useCartAnalyticsData(cart, data)
	const [additionalAnalytics, _setAdditionalAnalytics] = useState<any>({})
	const setAdditionalAnalytics = useCallback((data: any) => {
		if (typeof data === 'function') _setAdditionalAnalytics(data)
		else if (typeof data === 'object')
			_setAdditionalAnalytics((prev: any) => ({ ...prev, ...data }))
	}, [])
	const analyticsData = useMemo(
		() => ({ ...cartAnalyticsData, ...additionalAnalytics, setAdditionalAnalytics }),
		[cartAnalyticsData, additionalAnalytics]
	)
	return <AnalyticalDataProvider data={analyticsData}>{children}</AnalyticalDataProvider>
}
