// Page slices data
export const allTopLevelFieldsQuery = `
  non-repeat {
    ...non-repeatFields
  }
  repeat {
    ...repeatFields
  }
`

export const bodySlicesWithoutLinkedDocs = [
	'architectural_cards',
	'anchor',
	'callout_cards',
	'code_slice',
	'full-width_image',
	'header_block',
	'icon_row',
	'image_card_grid',
	'image_scroll',
	'large_callout',
	'logo_party',
	'reviews_cta',
	'rich_text',
	'video_block',
	'product_grid',
	'product_slice',
	'image_gallery_grid',
	'xl_image_gallery'
]

export const productCategoryQuery = `
  ...on product_category {
    ...product_categoryFields
  }
`

export const bodySlicesWithLinkedDocs = `
        ${bodySlicesWithoutLinkedDocs
					.map((sliceName) => `...on ${sliceName} {\n${allTopLevelFieldsQuery}\n}`)
					.join('\n')}

        ...on 50-50 {
          non-repeat {
            ...non-repeatFields
            section_primary_cta_link {
              ...on product_category {
                ...product_categoryFields
              }
            }
            section_secondary_cta_link {
              ...on product_category {
                 ...product_categoryFields
              }
            }
            form {
              ...on form {
                ...formFields
              }
              ...on lead_gen_form {
                ...lead_gen_formFields
              }
            }
          }
          repeat {
            ...repeatFields
          }
        }

        ...on 50-50_group {
          non-repeat {
            ...non-repeatFields
          }
          repeat {
            ...repeatFields
              
            form {
              ...on form {
                ...formFields
              }
              ...on lead_gen_form {
                ...lead_gen_formFields
              }
            }
          }
        }

        ...on testimonial {
          non-repeat {
            ...non-repeatFields
            review {
              ...on testimonial {
                ...testimonialFields
              }
            }
          }
        }

        ...on accordions {
          non-repeat {
            ...non-repeatFields
          }
          repeat {
            faq {
              ...on faq_group {
                ...faq_groupFields
              }
            }
          }
        }

        ...on review_carousel {
          non-repeat {
            ...non-repeatFields
          }
        }

        ...on form {
          non-repeat {
            ...non-repeatFields
            form {
              ...on form {
                ...formFields
              }
              ...on lead_gen_form {
                ...lead_gen_formFields
              }
            }
          }
          repeat {
            ...repeatFields
          }
        }

        ...on slice_group {
          non-repeat {
            slice_group {
              ...on slice_group {
                ...slice_groupFields
              }
            }
          }
        }

        ...on table {
          non-repeat {
            ...non-repeatFields
            table_cta_link {
              ...on product_category {
                ...product_categoryFields
              }
            }
            table {
              ...on table {
                ...tableFields
              }
            }
          }
        }

        ...on product_slider {
          non-repeat {
            ...non-repeatFields
            manual_category_marketing_card {
              ...on marketing_card {
                ...marketing_cardFields
              }
            }
          }
          repeat {
            ...repeatFields
          }
        }

        ...on marketing_cards {
          non-repeat {
            ...non-repeatFields
            primary_cta_link {
              ...on product_category {
                ...product_categoryFields
              }
            }
            secondary_cta_link {
              ...on product_category {
                ...product_categoryFields
              }
            }
          }
          repeat {
            marketing_card {
              ...on simple_marketing_card {
                ...simple_marketing_cardFields
                card_cta_link {
                  ...on product_category {
                    ...product_categoryFields
                  }
                }
              }
            }
          }
        }

        ...on category_carousel {
          non-repeat {
            ...non-repeatFields
            section_primary_cta_link {
              ...on product_category {
                ...product_categoryFields
              }
            }
            section_secondary_cta_link {
              ...on product_category {
                ...product_categoryFields
              }
            }
          }
          repeat {
            ...repeatFields
            link {
              ...on product_category {
                ...product_categoryFields
              }
            }
          }
        }
`

export const pageQuery = (docType = 'page') =>
	`
  {
    ${docType} {
      ...${docType}Fields

      body {
        ${bodySlicesWithLinkedDocs}

        ...on tab_slice_group {
          non-repeat {
            ...non-repeatFields
          }
          repeat {
            ...repeatFields
            slice {
              ...on slice_group {
                ...slice_groupFields
                
              }
            }
          }
        }
      }
    }
  }
`.replace(/[ \t]+/g, '')
