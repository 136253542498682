import { useState } from 'react'
import { ModalChat } from 'ui/modals'
import type { GlobalDocument } from 'types/types.generated'
type ChatNowProps = {
	globalData: GlobalDocument
	className: string
	text: string
	textClassName: string
	icon?: JSX.Element
}

export default function ChatNow({
	globalData,
	className,
	text,
	textClassName,
	icon = null
}: ChatNowProps) {
	const [openChat, setOpenChat] = useState(false)

	const handleOnOpenChat = () => {
		setOpenChat(true)
	}

	return (
		<div>
			<button className={className} onClick={handleOnOpenChat} type="button">
				{icon && icon}
				<span className={textClassName}>{text}</span>
			</button>
			<ModalChat open={openChat} setOpen={setOpenChat} globalData={globalData} />
		</div>
	)
}
