import { faArrowRight } from '@fortawesome/pro-regular-svg-icons'
import { RadioGroup } from '@headlessui/react'
import { Icon } from 'ui'
import clsx from 'clsx'
import { useState } from 'react'

const quantitiesRanges = [
	{
		id: 1,
		label: '1-19',
		value: '1-19'
	},
	{
		id: 2,
		label: '20-49',
		value: '20-49'
	},
	{
		id: 3,
		label: '50-99',
		value: '50-99'
	},
	{
		id: 4,
		label: '100-199',
		value: '100-199'
	},
	{
		id: 5,
		label: '200-499',
		value: '200-499'
	},
	{
		id: 6,
		label: '500-999',
		value: '500-999'
	},
	{
		id: 7,
		label: '1,000-1,999',
		value: '1,000-1,999'
	},
	{
		id: 8,
		label: '2,000-4,999',
		value: '2,000-4,999'
	},
	{
		id: 9,
		label: '5,000+',
		value: '5,000+'
	}
]

const ChatQuantitiesOptions = ({ setCurrentOption, setValue }) => {
	const [selectedValue, setSelectedValue] = useState('')

	const handleOnChange = (value) => {
		setSelectedValue(value)
		if (value === 'question') return
		setValue('quantity_est_annual', value)
	}

	const handleOnSubmitNext = () => {
		setCurrentOption((prev) => prev + 1)
	}

	return (
		<div className="flex flex-col items-start gap-2 h-full">
			<p className="font-medium text-left">
				How many custom shirts, hats, etc to you plan to order over the next 12 months?
			</p>
			{/* Radio buttons */}
			<RadioGroup
				name="quantity_est_annual"
				value={selectedValue}
				onChange={handleOnChange}
				className="grid grid-cols-3 gap-3 w-full">
				{quantitiesRanges.map((quantityRange) => (
					<RadioGroup.Option
						key={quantityRange.id}
						value={quantityRange.value}
						className={({ checked }) =>
							clsx(
								checked ? 'bg-black-rt text-white' : 'bg-white text-black-rt hover:bg-gray-100',
								'flex items-center justify-center h-10 text-[13px] font-medium cursor-pointer rounded-full overflow-hidden border pb-0 pt-1 transition-colors duration-300 ease-out'
							)
						}>
						{quantityRange.label}
					</RadioGroup.Option>
				))}
				<RadioGroup.Option
					value="question"
					className={({ checked }) =>
						clsx(
							checked ? 'bg-black-rt text-white' : 'bg-white text-black-rt hover:bg-gray-100',
							'col-span-3 flex items-center justify-center text-[13px] h-10 font-medium cursor-pointer rounded-full overflow-hidden border pb-0 pt-1 transition-colors duration-300 ease-out'
						)
					}>
					None, I have a general question
				</RadioGroup.Option>
			</RadioGroup>

			<div className="ml-auto mt-auto">
				<button
					type="button"
					className="hyperlink mr-5"
					onClick={() => setCurrentOption((prev) => prev - 1)}>
					Back
				</button>
				{/* {selectedValue !== '' &&
					(selectedValue === 'question' ? (
						<button
							type="button"
							className="button button-dark button-md pb-[3px]"
							onClick={handleOnSubmitNext}>
							<span className="-mb-1">Next</span>{' '}
							<Icon className="pl-3 text-sm right-arrow-hover" icon={faArrowRight} />
						</button>
					) : (
						<button onClick={handleOnSubmitNext} type="submit" className="button button-dark">
							Start chat via text
						</button>
					))} */}

				<button
					type="button"
					className="button button-dark button-md pb-[3px]"
					onClick={handleOnSubmitNext}>
					<span className="-mb-1">Next</span>{' '}
					<Icon className="pl-3 text-sm right-arrow-hover" icon={faArrowRight} />
				</button>
			</div>
		</div>
	)
}

export default ChatQuantitiesOptions
