import { useEffect, useRef } from 'react'
import dayjs from 'dayjs'

import { formatCost } from 'utils'

type DateCardProps = {
	date: {
		disabled: boolean
		daysRush: number
		date: string
		holiday: {
			name: string
		}
		isStandardDeliveryDate: boolean
		rushFee: number
		total: number
	}
	highlighted: boolean
	onSelectDate: any
}

export default function DateCard({ date, highlighted, onSelectDate }: DateCardProps) {
	const activeElement = useRef<HTMLButtonElement>(null)

	useEffect(
		() => {
			// only scroll into view when the modal opens
			if (highlighted) {
				if (activeElement.current) {
					activeElement.current.scrollIntoView()
				}
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[]
	)

	return (
		// Date Card
		<button
			type="button"
			ref={activeElement}
			className={`relative rounded-lg text-center w-full h-full border
				flex flex-col items-center transition-colors duration-300 ease-out
				${date.disabled ? 'border-g-100 text-g-200' : 'border-g-200 hover:border-black-rt'}
				${highlighted ? 'text-white bg-black-rt !border-black-rt' : ''}
			`}
			disabled={date.disabled || date.daysRush < 0}
			onClick={() => onSelectDate(date)}>
			<div className="flex flex-col p-3 flex-grow">
				{/* Day of Week (for mobile) */}
				<span className="sm:hidden text-g-300 text-xs">{dayjs(date.date).format('ddd')}</span>

				{/* Month */}
				<span className="block font-medium">{dayjs(date.date).format('MMM')}</span>

				{/* Day */}
				<span className="block text-lg font-medium -mt-1">{dayjs(date.date).format('D')}</span>

				{/* Holiday */}
				{date.holiday && (
					<div className="flex flex-col">
						<span>Holiday</span>
						<span>{date.holiday.name}</span>
					</div>
				)}

				{/* Standard */}
				{date.isStandardDeliveryDate && (
					<span className="font-medium text-[11px] mt-auto">Standard </span>
				)}

				{/* Cost */}
				{!!date.rushFee && !!date.total && (
					<dl className="text-[11px] font-medium mt-auto pt-2">
						<dt className="srt">Change in cost:</dt>
						<dd className="inline">
							{' '}
							{date.rushFee > 0 && '+'}
							{formatCost(date.rushFee)}
						</dd>
					</dl>
				)}

				{/* Today Indicator */}
				{dayjs().isSame(dayjs(date.date), 'day') ? (
					<span className="text-black-rt font-medium text-[11px] mt-auto pt-2">Today</span>
				) : null}
			</div>
		</button>
	)
}
