import { sanitize as purify } from 'isomorphic-dompurify'

export const sanitizeObj = (obj: { [key: string]: any }) => {
	if (!!obj && typeof obj === 'object') {
		let sanitizedObj = {}
		Object.keys(obj).forEach((key) => {
			sanitizedObj[key] = purify(obj[key])
		})

		return sanitizedObj
	}

	return {}
}

const sanitize = (str: string) => (str ? purify(str) : '')
export default sanitize
