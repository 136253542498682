import { faSpinner } from '@fortawesome/pro-solid-svg-icons'
import Icon from './Icon'
import clsx from 'clsx'


export default function Spinner({ className }: { className?: string }) {
	return (
		<div className={clsx("button-spinner", className)}>
			<Icon icon={faSpinner} className="fa-spin" />
		</div>
	)
}
