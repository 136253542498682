import { forwardRef, MutableRefObject } from 'react'
import kebabCase from 'lodash.kebabcase'

type PlainTextProps = {
	content: string | { text: string }[]
	as?: 'h1' | 'h2' | 'h3' | 'h4' | 'p' | 'span'
	className?: string
	style?: any
}
const PlainText = forwardRef(
	(
		{ content, as = 'p', className = '', style }: PlainTextProps,
		ref: MutableRefObject<HTMLHeadingElement | HTMLParagraphElement>
	) => {
		// Wrapper can be turned into an `h1`, `span`, etc.
		const Wrapper = as

		// Prevent code repetition
		const parsedContent = typeof content === 'string' ? content : content?.[0]?.text

		return parsedContent ? (
			<Wrapper className={className} ref={ref} id={kebabCase(parsedContent)} style={style}>
				{parsedContent}
			</Wrapper>
		) : null
	}
)
PlainText.displayName = 'PlainText'

export default PlainText
