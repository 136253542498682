import React, { createContext, useEffect, useState, useMemo } from 'react'
import { useRouter } from 'next/router'

import {
	Identity,
	LoginFlow,
	RegistrationFlow,
	SettingsFlow,
	VerificationFlow,
	UpdateLoginFlowBody,
	UpdateRegistrationFlowBody,
	UpdateSettingsFlowBody,
	UpdateVerificationFlowBody,
	UpdateRecoveryFlowBody,
	RecoveryFlow,
	SessionAuthenticationMethod,
	Session
} from '@ory/client'
import { AnalyticalDataProvider } from '../../analytics'
import { ory } from '../client/ory'
import { SessionContextType } from '../types/Session.type'
import {
	CREATE_ACCOUNT,
	ORDERS,
	isNonAuthenticatedRoute,
	isProtectedRoute,
	LOGIN,
	SETTINGS,
	EMAIL_NOT_VERIFIED,
	VERIFY_EMAIL,
	ORDER_DETAILS
} from '../utils/routes'
import Loading from 'ui/Loading'
import { getCsrfToken, getOrySettingsFlow } from '../utils'

export const SessionContext = createContext<SessionContextType>(null)

export const SessionProvider = ({ children }) => {
	const [currentUser, setCurrentUser] = useState<Identity | undefined>(undefined)
	const [authenticationMethods, setAuthenticationMethods] = useState<
		SessionAuthenticationMethod[] | undefined
	>(undefined)
	const [flow, setFlow] = useState<
		LoginFlow | RegistrationFlow | SettingsFlow | VerificationFlow | any
	>()
	const [ready, setReady] = useState<boolean>(false)
	const [logOutUrl, setLogoutUrl] = useState<string | undefined>()

	const router = useRouter()

	const isLoading = useMemo(() => {
		return currentUser === undefined
	}, [currentUser])

	const isLoggedIn = !!currentUser

	const isEmailAddressVerified =
		currentUser?.verifiable_addresses?.length > 0 && currentUser?.verifiable_addresses[0].verified

	const isHomePage = router.route === '/' && router.basePath === ''
	const isAccountPage =
		router.basePath === '/account' &&
		(router.route === ORDERS || router.route === SETTINGS || router.route === '/')

	const redirectToLogin = !isLoggedIn && isProtectedRoute(router.pathname) && isAccountPage

	const redirectToOrders =
		!isLoading &&
		isLoggedIn &&
		((router.pathname !== ORDER_DETAILS && isNonAuthenticatedRoute(router.pathname)) ||
			(isProtectedRoute(router.pathname) &&
				!(
					router.pathname === ORDERS ||
					router.pathname === SETTINGS ||
					router.pathname === VERIFY_EMAIL
				) &&
				isAccountPage)) &&
		isEmailAddressVerified

	const { flow: flowId } = router.query

	const redirecToEmailNotVerified =
		!isLoading &&
		isLoggedIn &&
		(isNonAuthenticatedRoute(router.pathname) ||
			(isProtectedRoute(router.pathname) &&
				!(router.pathname === VERIFY_EMAIL || router.pathname === EMAIL_NOT_VERIFIED) &&
				!isHomePage)) &&
		!isEmailAddressVerified &&
		router.pathname !== SETTINGS &&
		router.pathname !== CREATE_ACCOUNT

	const isRedirecting = redirectToLogin || redirectToOrders || redirecToEmailNotVerified

	const fetchSession = async () => {
		try {
			const { data: sessionData } = await ory.toSession()

			// User has a session!
			setCurrentUser(sessionData.identity)
			setAuthenticationMethods(sessionData.authentication_methods)
		} catch (_error) {
			setCurrentUser(null)
			setAuthenticationMethods(null)
			setFlow(null)
		}
		setReady(true)
	}

	useEffect(() => {
		fetchSession()
	}, [])

	// Get the logout URL when the user is logged in
	useEffect(() => {
		const fetchLogoutUrl = async () => {
			if (currentUser) {
				// Create a logout url
				const { data: logoutData } = await ory.createBrowserLogoutFlow()
				setLogoutUrl(logoutData?.logout_url)
			}
		}

		fetchLogoutUrl()
	}, [currentUser])

	// If the user is not authenticated and is not on the login page, redirect to login
	useEffect(() => {
		if (!isLoading && redirectToLogin) {
			router.push(`${LOGIN}`)
		}
	}, [isLoading, redirectToLogin, router])

	// If the user is authenticated and is on the login page, redirect to orders
	useEffect(() => {
		if (redirecToEmailNotVerified) {
			router.push(EMAIL_NOT_VERIFIED)
		} else if (redirectToOrders) {
			router.push(ORDERS)
		}
	}, [router.pathname, redirecToEmailNotVerified, redirectToOrders])

	const createAccountWithEmailAndPassword = async (
		values: UpdateRegistrationFlowBody,
		flow: RegistrationFlow,
		onSuccess: (_: Session) => Promise<void>,
		isFromCheckout?: boolean
	) => {
		const { data } = await ory.updateRegistrationFlow({
			flow: String(flow?.id),
			updateRegistrationFlowBody: values
		})

		await onSuccess(data.session)
		setCurrentUser(data.session.identity)

		if (data.continue_with) {
			data.continue_with.forEach(async (continueWith) => {
				if (continueWith.action === 'show_verification_ui') {
					if (!isFromCheckout) {
						// @ts-ignore - flow is not defined
						await router.push(`${VERIFY_EMAIL}?flow=${continueWith.flow.id}`, undefined, {
							shallow: true
						})
					} else {
						// @ts-ignore - flow is not defined
						await router.push({ query: { flow: continueWith.flow.id } }, undefined, {
							shallow: true
						})
					}
				}
			})
		}
	}

	const loginWithEmailAndPassword = async (
		values: UpdateLoginFlowBody,
		loginFlow: LoginFlow,
		linkingAccounts: boolean = false
	) => {
		await ory.updateLoginFlow({
			flow: String(loginFlow?.id),
			updateLoginFlowBody: values
		})

		// Get the session data with verified email address and identity
		const { data: sessionData } = await ory.toSession()

		setCurrentUser(sessionData.identity)

		// If the user is linking accounts, we need to update the settings flow
		if (linkingAccounts) {
			const settingsFlow = await getOrySettingsFlow(flowId, setFlow, router)
			const token = getCsrfToken(settingsFlow)

			const values = {
				link: 'google',
				csrfToken: token,
				method: 'profile'
			}

			await updateAccount(values, settingsFlow)
		}

		if (loginFlow?.return_to) {
			await router.push(flow?.return_to)
		}

		return sessionData
	}

	const updateAccount = async (values: UpdateSettingsFlowBody, flow: SettingsFlow) => {
		const { data } = await ory.updateSettingsFlow({
			flow: String(flow?.id),
			updateSettingsFlowBody: values
		})

		setCurrentUser(data.identity)
	}

	const verifyEmail = async (
		values: UpdateVerificationFlowBody,
		flow: VerificationFlow,
		isFromCheckout?: boolean
	) => {
		const { data } = await ory.updateVerificationFlow({
			flow: String(flow?.id),
			updateVerificationFlowBody: values
		})

		setFlow(data)
		fetchSession()

		if (data.state === 'passed_challenge') {
			await router.push(`${SETTINGS}`, undefined, {
				shallow: true
			})
		}
	}

	const forgotPassword = async (values: UpdateRecoveryFlowBody, flow: RecoveryFlow) => {
		const { data } = await ory.updateRecoveryFlow({
			flow: String(flow?.id),
			updateRecoveryFlowBody: values
		})
		setFlow(data)
	}

	const recoveryPassword = async (values: UpdateRecoveryFlowBody, flow: RecoveryFlow) => {
		const { data } = await ory.updateRecoveryFlow({
			flow: String(flow?.id),
			updateRecoveryFlowBody: values
		})
		setFlow(data)
	}

	const providerValue = useMemo(
		() => ({
			currentUser,
			authenticationMethods,
			flow,
			createAccountWithEmailAndPassword,
			loginWithEmailAndPassword,
			updateAccount,
			verifyEmail,
			recoveryPassword,
			forgotPassword,
			isLoggedIn,
			logOutUrl,
			setFlow,
			ready
		}),
		[currentUser, authenticationMethods, flow, isLoggedIn, logOutUrl, ready]
	)

	if (isRedirecting) {
		return (
			<div className="flex justify-center items-center h-screen">
				<Loading />
			</div>
		)
	}
	return (
		<SessionContext.Provider value={providerValue}>
			<AnalyticalDataProvider data={{ user_id: currentUser?.id }}>
				{children}
			</AnalyticalDataProvider>
		</SessionContext.Provider>
	)
}
