import { Symbol } from 'ui'

type Props = {
	children: React.ReactNode
	className?: string
}

export default function Error({ children, className }: Props) {
	return (
		<div
			className={`px-3 pt-2 pb-[5px] rounded-lg bg-error/10 text-error flex items-center space-x-3 mt-4 ${
				className ?? ''
			}`}>
			<span className="text-sm">
				<Symbol id="warning" />
			</span>
			<span className="text-[13px] leading-[17px]">{children}</span>
		</div>
	)
}
