import { gql, useQuery } from '@apollo/client'
import { apolloClientNewServer } from 'utils/clients/apolloClientNewServer'

export const GET_AUTHENTICATION_METHOD_FOR_EMAIL = gql`
	query User($email: String) {
		user(email: $email) {
			id
			email
			contact_sfid
			lead_sfid
			auth_method
		}
	}
`

export const useGetAuthenticationMethodForEmail = (email: string) => {
	const { loading, error, data, refetch } = useQuery(GET_AUTHENTICATION_METHOD_FOR_EMAIL, {
		variables: { email },
		client: apolloClientNewServer
	})

	return {
		user: data && data.user,
		refetch,
		loading,
		error
	}
}
