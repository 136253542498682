/**
 * This context is used to provide analytical data to components which triggers the event, which might not have access to the data directly.
 * 
 * It is hierarchical in nature, meaning that the data from the parent AnalyticalDataProvider will be available to the children AnalyticalDataProvider.
 */
import { createContext, useContext, useEffect, useMemo, useState } from 'react'

type AnalyticalDataContext = {
    [K in string]: any;

}
type AnalyticalDataSetter = (_: AnalyticalDataContext) => AnalyticalDataContext;
const AnalyticalDataContext = createContext<AnalyticalDataContext>({});

export const useAnalyticalDataContext = () => {

    const context = useContext(AnalyticalDataContext)

	if (context === undefined) {
        console.warn("Analytical Data context is not defined. Please make sure you have wrapped your app with CustomerLabsProvider");
        return {} as AnalyticalDataContext;
	}
	return context;
}

type AnalyticalDataProviderProps = {
    data: AnalyticalDataContext;
    newRoot?: boolean,
    newContext?: boolean;
    children: React.ReactNode;
}

export const AnalyticalDataProvider = ({ data: newAnalyticalData, newContext, children }: AnalyticalDataProviderProps) => {
    const [backwardPropagatingData, setBackwardPropagatingData] = useState<AnalyticalDataContext | null>(null);
    const previousAnalyticalData = useAnalyticalDataContext();
    const propagateData = useMemo(() => {
        // Ensuring the root context is storing the propagated data
        // Passing the parent propagateData method so the effects are not updated on every render
        if (previousAnalyticalData?.propagateData) {
            return previousAnalyticalData.propagateData;
        } else {
            return (analyticalData: AnalyticalDataContext | AnalyticalDataSetter) => {
                setBackwardPropagatingData(pd => {
                    switch (typeof analyticalData) {
                        case 'function':
                            return analyticalData(pd || {});
                        case 'object':
                            if (pd) {
                                if (Object.entries(analyticalData).some(([key, value]) => pd[key] !== value)) {
                                    return {...pd, ...analyticalData }
                                } else {
                                    return pd;
                                }
                            } else {
                                return { ...analyticalData }
                            }
                        default:
                            return pd;
                    }
                });
            };
        }
    }, [previousAnalyticalData]);

    const propagateArrayAdd = useMemo(() => {
        if (previousAnalyticalData?.propagateArrayAdd) {
            return previousAnalyticalData.propagateArrayAdd;
        } else {
            return (key: string, value: any) => {
                setBackwardPropagatingData(pd => {
                    if (pd) {
                        return {...pd, [key]: [...(pd[key] || []), value] }
                    } else {
                        return { [key]: [value] }
                    }
                });
            };
        }
    }, [previousAnalyticalData]);

    const propagateArrayRemove = useMemo(() => {
        if (previousAnalyticalData?.propagateArrayRemove) {
            return previousAnalyticalData.propagateArrayRemove;
        } else {
            return (key: string, value: any) => {
                setBackwardPropagatingData(pd => {
                    if (pd) {
                        return {...pd, [key]: (pd[key] || []).filter((v: any) => v !== value) }
                    } else {
                        return { [key]: [] }
                    }
                });
            };
        }
    }, [previousAnalyticalData]);

    const value = useMemo(() => {
        const value = {} as any;
        if (backwardPropagatingData) {
            Object.assign(value, backwardPropagatingData);
        }
        if (previousAnalyticalData && !newContext) {
            Object.assign(value, previousAnalyticalData);
        }
        if (newAnalyticalData) {
            Object.assign(value, newAnalyticalData);
        }
        value.propagateData = propagateData;
        value.propagateArrayAdd = propagateArrayAdd;
        value.propagateArrayRemove = propagateArrayRemove;
        
        return value;
    }, [
        newAnalyticalData, newContext, backwardPropagatingData, 
        propagateData, previousAnalyticalData, propagateArrayAdd, propagateArrayRemove
    ])

	return <AnalyticalDataContext.Provider value={value}>{children}</AnalyticalDataContext.Provider>
}


export function useAnalyticsPropagation(data: any) {
    const { propagateData} = useAnalyticalDataContext();
    
    useEffect(() => {
        propagateData(data);
    }, [ propagateData]);
}