import Image from 'next/image'
import { Dispatch, SetStateAction, useRef, useState, useEffect } from 'react'
import { useIsomorphicLayoutEffect } from 'react-use'
import dynamic from 'next/dynamic'
import { Icon, PlainText, ReviewStars } from 'ui'
import { faX } from '@fortawesome/pro-regular-svg-icons'
import { useRouter } from 'next/router'
import { ImageJsonLd } from 'next-seo'
import clsx from 'clsx'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'

import { getDaysAgo, placeholderProps } from 'utils/client'
import { theme } from 'config/tailwind.config'
import type { TestimonialDocument } from 'types/types.generated'

const TestimonialModal = dynamic(() => import('./TestimonialModal'))

type TestimonialCardProps = {
	review: TestimonialDocument
	asModal?: boolean
	truncateReview?: boolean
	setIsOpen?: Dispatch<SetStateAction<boolean>>
	isLoading?: boolean
	bgColor?: string
}

dayjs.extend(relativeTime)

const TestimonialHeader = ({ title, rating, asModal, setIsOpen }) => (
	<div className="relative w-full">
		{title && <PlainText className="text-xl font-serif font-medium text-left" content={title} />}
		{rating != null && <ReviewStars rating={rating} className="w-3.5 mt-2" />}
		{asModal && setIsOpen && (
			<button className="absolute top-0 right-0 p-1" onClick={() => setIsOpen(false)}>
				<Icon icon={faX} className="w-3.5 h-3.5" />
			</button>
		)}
	</div>
)

const AuthorInfo = ({ profilePhoto, name, location, daysAgo, createdAt }) => {
	const [mounted, setMounted] = useState(false)

	useEffect(() => {
		setMounted(true)
	}, [])

	return (
		<div className="flex gap-3 items-center">
			{profilePhoto?.url && (
				<div className="rounded-full w-12 min-w-[3rem] aspect-square relative overflow-hidden">
					<Image
						src={profilePhoto.url}
						alt={profilePhoto.alt || 'Profile Photo'}
						fill
						style={{ objectFit: 'cover' }}
						sizes="48px"
						quality={70}
						{...placeholderProps(profilePhoto)}
					/>
					<ImageJsonLd
						contentUrl={profilePhoto.url}
						images={[profilePhoto]}
						creator="Real Thread"
						creditText="Real Thread"
					/>
				</div>
			)}
			<div className="text-left">
				<PlainText
					className="text-xs uppercase font-semibold font-condensed tracking-[.1rem]"
					content={name}
				/>
				<span className="text-xs text-gray-600">
					<PlainText as="span" content={location} />
					{location && daysAgo != null && <span className="font-thin mx-1 text-g-200"> | </span>}
					{mounted && daysAgo != null && !Number.isNaN(daysAgo) && (
						<span>{daysAgo === 0 ? 'today' : `${daysAgo} days ago`}</span>
					)}
					{mounted && createdAt && (
						<span>{createdAt.includes('T') ? dayjs(createdAt).fromNow() : createdAt}</span>
					)}
				</span>
			</div>
		</div>
	)
}

export default function TestimonialCard({
	review,
	asModal,
	truncateReview = true,
	setIsOpen,
	isLoading,
	bgColor
}: TestimonialCardProps) {
	const router = useRouter()
	const daysAgo = getDaysAgo(review?.data?.date)

	// Read More modal
	// Conditionally show 'read more' button when review overflows
	const reviewRef = useRef<HTMLParagraphElement | HTMLHeadingElement>(null)
	const [isReviewTruncated, setIsReviewTruncated] = useState(false)

	useIsomorphicLayoutEffect(() => {
		const { clientHeight, scrollHeight } = reviewRef?.current || {}

		if (clientHeight && scrollHeight) {
			setIsReviewTruncated(clientHeight < scrollHeight)
		}
	}, [])

	const [isModalOpen, setIsModalOpen] = useState(false)
	const openModal = () => setIsModalOpen(true)

	const closeModal = () => {
		setIsOpen(false)
		router.push('/reviews')
	}

	if (isLoading) {
		return (
			<div className="testimonial-card flex flex-col w-full min-w-[calc(100vw_-_2rem)] sm:min-w-[369px] min-h-80 p-8 mb-4 rounded-lg animate-pulse">
				<div className="bg-skeleton-gray w-2/3 h-5 rounded-lg" />
				<div className="bg-skeleton-gray w-1/2 h-5 rounded-lg" />
				<div className="bg-skeleton-gray w-full h-1/2 rounded-lg" />
				<div className="bg-skeleton-gray w-2/3 h-12 rounded-lg" />
			</div>
		)
	}

	return (
		<button
			onClick={openModal}
			className={clsx(
				'testimonial-card flex flex-col w-full min-w-[calc(100vw_-_2rem)] sm:min-w-[369px] min-h-80 p-8 mb-4 rounded-lg'
			)}
			style={{ backgroundColor: bgColor || theme.extend.colors['warm-gray']['100'] }}>
			<TestimonialHeader
				title={review.data.testimonial_title}
				rating={review.data.rating}
				asModal={asModal}
				setIsOpen={setIsOpen}
			/>

			<PlainText
				className={`text-left text-gray-600 w-full mt-5 leading-[26px] ${
					!asModal && truncateReview ? 'truncate-multi' : ''
				}`}
				content={review.data.testimonial}
				ref={reviewRef}
			/>

			<div
				className={clsx(
					'flex max-xl:flex-col flex-wrap max-xl:items-start items-center justify-between gap-3 mt-auto',
					asModal ? 'pt-10' : 'mt-4 pt-4'
				)}>
				<AuthorInfo
					profilePhoto={review.data.customer_profile_photo}
					name={review.data.customer_name}
					location={review.data.customer_location}
					daysAgo={daysAgo}
					createdAt={review.data.created_at}
				/>

				{isReviewTruncated ? (
					<TestimonialModal review={review} isOpen={isModalOpen} setIsOpen={setIsModalOpen} />
				) : asModal ? (
					<button className="button button-dark" onClick={closeModal}>
						Read all reviews
					</button>
				) : null}
			</div>
		</button>
	)
}
