import type { PreviewData } from 'next'
import type { IconDefinition } from '@fortawesome/fontawesome-svg-core'

export const unslugify = (slug: string) => {
	const result = slug ? slug.replace(/\-/g, ' ') : ''

	return result.replace(
		/\w\S*/g,
		(txt) => txt.charAt(0).toUpperCase() + txt.substring(1).toLowerCase()
	)
}

export const removeDuplicates = (arr: any[]) =>
	Array.isArray(arr) ? Array.from(new Set(arr)) : arr
export const removeNullOrUndefined = (arr: any[]) =>
	Array.isArray(arr) ? arr.filter((item) => item != null) : arr
export const cleanArray = (arr: any[]) =>
	Array.isArray(arr) && removeDuplicates(removeNullOrUndefined(arr))

export const getDaysAgo = (dateStr: string) => {
	const today = new Date()
	const createdOn = new Date(dateStr)

	const msInDay = 24 * 60 * 60 * 1000

	createdOn.setHours(0, 0, 0, 0)
	today.setHours(0, 0, 0, 0)

	const diff = Math.round((today.getTime() - createdOn.getTime()) / msInDay)
	return diff
}

export const isNotEmptyArray = (arr: any[]) => Array.isArray(arr) && arr.length > 0
export const isNotEmptyObject = (obj: { [key: string]: any }) =>
	!!obj && typeof obj === 'object' && Object.keys(obj).length > 0
export const isNotEmptyString = (str: string) => !!str && typeof str === 'string'

export const isValidIcon = (iconObj: IconDefinition) => {
	const { icon, iconName, prefix } = iconObj || {}

	return !!(iconName && prefix && Array.isArray(icon) && icon.length === 5)
}

export const getPreviewData = (previewData: PreviewData) => {
	const previewRef = (previewData as any)?.ref ? (previewData as any).ref : null
	const refOption = previewRef ? { ref: previewRef } : null

	return { previewRef, refOption }
}

export const getNumValFromComputedStyle = (el: Element, desiredStyle: string) => {
	if (typeof window !== 'undefined' && el && desiredStyle) {
		const computedStyle = window.getComputedStyle(el)
		const style = computedStyle?.[desiredStyle]

		const numStr = style && style.match(/\d+/)?.[0]
		const num = numStr ? parseFloat(numStr) : null

		return !isNaN(num) ? num : 0
	} else {
		return 0
	}
}

export const formatJsonLdString = (str: string) =>
	str && JSON.stringify(str).replace(/(^"|"$)/g, '')

export const getOgImageFallback = (page: any) => {
	const { data } = page || {}

	if (!!data && typeof data === 'object') {
		const { hero, hero_image } = data || {}

		if (Array.isArray(hero)) {
			return hero.find((slice: any) => slice?.primary?.background_image?.url)?.primary
				?.background_image
		} else if (hero_image?.url) {
			return hero_image
		}
	}
}

export const getFileExtensionFromUrl = (url: string) => url.split(/[#?]/)[0].split('.').pop().trim()

export const constructOgImage = (
	ogImage: {
		url: string
		alt: string
		dimensions: {
			width: number
			height: number
		}
	},
	page?: any
) => {
	const foundOgImage = ogImage?.url ? ogImage : getOgImageFallback(page)

	const { url, alt, dimensions } = foundOgImage || {}
	const { width, height } = dimensions || {}

	if (url) {
		return [
			{
				url,
				alt,
				width,
				height,
				type: `image/${getFileExtensionFromUrl(foundOgImage.url)}`
			}
		]
	}
}

export const isValidLink = (link: { url?: string; uid?: string }) => !!(link?.url || link?.uid)

export const isValidObject = (obj: any) => !!obj && typeof obj === 'object'

export const placeholderProps = (imgObj: { url: string; alt: string; placeholder: string }) => {
	const { url, placeholder: blurDataURL } = imgObj || {}

	if (url && blurDataURL) {
		const placeholder = 'blur' as 'blur' | 'empty'
		return { placeholder, blurDataURL }
	}

	return {}
}

export const mergeRefs = (...refs) => {
	return (node) => {
		for (const ref of refs) {
			if (ref?.current) {
				ref.current = node
			}
		}
	}
}

export const toCamelCase = (str: string) =>
	str
		?.replace(/(?:^\w|[A-Z]|\b\w)/g, (word, i) =>
			i === 0 ? word.toLowerCase() : word.toUpperCase()
		)
		?.replace(/\s+/g, '')
