export const getCanonicalUrl = (pathname: string) => {
	const url = `https://www.realthread.com${pathname}`
	try {
		const urlObj = new URL(url) // Create a URL object
		return urlObj.origin + urlObj.pathname // Combine origin and pathname
	} catch (error) {
		console.error('Invalid URL:', error)
		return null
	}
}
