import { useState, ComponentPropsWithoutRef } from 'react'
import { faEye, faEyeSlash } from '@fortawesome/pro-light-svg-icons'
import { faCheck, faXmark } from '@fortawesome/pro-regular-svg-icons'

import { Icon, Input } from 'ui'
import type { InputType } from 'ui/form/Input'

import clsx from 'clsx'

export interface InputNumberType extends ComponentPropsWithoutRef<'input'> {
	surcharge?: string
	shouldShowValidation?: boolean
}

type Props = {
	label: string
	shouldShowValidation?: boolean
	maxWidth?: string
	preventAutoFocus?: boolean
} & InputType

export default function InputPassword({
	label,
	shouldShowValidation,
	maxWidth,
	preventAutoFocus,
	...props
}: Props) {
	const [passwordShown, setPasswordShown] = useState(false)
	const [isPasswordLengthValid, setIsPasswordLengthValid] = useState(false)
	const [isPasswordSpecialCharacterValid, setIsPasswordSpecialCharacterValid] = useState(false)
	const [isPasswordNumberValid, setIsPasswordNumberValid] = useState(false)
	const [showValidatons, setShowValidations] = useState(false)

	const togglePassword = () => {
		setPasswordShown(!passwordShown)
	}

	const passwordValidation = (value: string) => {
		const validation = [
			{ type: 'special', value },
			{ type: 'length', value },
			{ type: 'number', value }
		]

		validation.forEach((v) => {
			switch (v.type) {
				case 'special':
					// @ts-ignore
					setIsPasswordSpecialCharacterValid(v.value.match(/\W/))
					break

				case 'length':
					setIsPasswordLengthValid(v.value.length >= 8)
					break

				case 'number':
					// @ts-ignore
					setIsPasswordNumberValid(v.value.match(/[0-9]/))
					break

				default:
					break
			}
		})
	}

	return (
		<div className="relative flex items-center">
			<Input
				className="w-full"
				label={label}
				type={passwordShown ? 'text' : 'password'}
				onChange={(e) => {
					passwordValidation(e.currentTarget.value)
				}}
				onBlur={(e) => {
					passwordValidation(e.currentTarget.value)
					setShowValidations(false)
				}}
				onFocus={() => setShowValidations(true)}
				{...props}
				tabIndex={preventAutoFocus ? -1 : 0}
			/>
			<button
				className="flex absolute right-0 bg-transparent rounded m-4"
				onClick={togglePassword}
				type="button">
				<Icon icon={passwordShown ? faEyeSlash : faEye} />
			</button>
			{shouldShowValidation && showValidatons && (
				<div
					className="help-tooltip center-tooltip focus-tooltip"
					style={{ maxWidth: maxWidth ? maxWidth : '368px' }}>
					<ValidationRow
						condition={isPasswordLengthValid}
						message="Password must be at least 8 characters"
					/>
					<ValidationRow
						condition={isPasswordSpecialCharacterValid}
						message="Password must contain at least one special character"
					/>
					<ValidationRow
						condition={isPasswordNumberValid}
						message="Password must contain at least one number"
					/>
				</div>
			)}
		</div>
	)
}

const ValidationRow = ({ condition, message }: { condition: boolean; message: string }) => {
	return (
		<div className="flex gap-2 items-start">
			<Icon
				className={clsx('text-sm mt-px', condition ? 'text-green-rt' : 'text-error')}
				icon={condition ? faCheck : faXmark}
			/>
			<span className="text-sm text-left">{message}</span>
		</div>
	)
}
