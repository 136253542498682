import { Input } from '../form'
import { Control } from 'react-hook-form'

type Props = {
	control: Control
}

export default function PayWithPurchaseOrder({ control }: Readonly<Props>) {
	return (
		<>
			<p className="text-sm leading-6 text-g-500 mt-4">
				Paying with a purchase order requires approval. We will review your account and contact your
				accounting email to complete the application process.
			</p>
			<div className="grid grid-cols-2 gap-4 mt-4">
				<Input
					className="w-full"
					name="customerPo"
					label="Purchase Order Number"
					rules={{ required: 'Purchase Order Number is required' }}
					control={control}
				/>
				<Input
					className="w-full"
					name="email"
					label="Accounting Email Address"
					control={control}
					rules={{ required: 'Accounting Email Address is required' }}
				/>
			</div>
		</>
	)
}
