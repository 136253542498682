import Image from 'next/image'
import Logo from './layout/Logo'

interface Props {
	className?: string
}

export default function LoadingBoxScreen({ className }: Props) {
	return (
		<div
			className={`flex flex-col relative justify-between items-center h-[calc(100vh_-_20px)] w-screen ${
				className ?? ''
			}`}>
			<Logo className="my-6" />

			<div className="flex items-center justify-center flex-grow w-full">
				{/* Paint Stroke BG */}
				<div className="relative w-full h-full max-h-[370px] max-w-[720px] mt-10 z-0">
					<Image
						src="https://rstr.in/real-thread/marketing/X15Fj6fSq4y"
						alt=""
						aria-hidden="true"
						fill
						className="absolute object-contain object-center"
						sizes="720px"
						quality={70}
					/>
				</div>

				{/* Animated Box Unwrapping */}
				<Image
					src="https://rstr.in/real-thread/marketing/ozwiU15cefj"
					alt="animated box unwrapping"
					width={400}
					height={400}
					className="absolute top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2 max-w-[228px] z-10"
					sizes="228px"
					quality={70}
				/>
			</div>

			<h1 className="text-4xl md:text-5xl max-sm:px-10 font-serif text-center max-w-[400px] mb-36">
				Unpacking your order details.
			</h1>
		</div>
	)
}
