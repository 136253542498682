import { ReactNode, useEffect, useRef } from 'react'
import ScrollButtons from './prismic/slices/ScrollButtons'

type CarouselProps = {
	cards: ReactNode
	contentToLeftOfScrollButtons?: ReactNode
	scrollButtonsClassName?: string
	isProductList?: boolean
}

export default function Carousel({
	cards,
	contentToLeftOfScrollButtons,
	scrollButtonsClassName,
	isProductList = false
}: CarouselProps) {
	const sliderRef = useRef<HTMLDivElement>(null)
	const gap = 20

	// When Carousel is in view, fade cards in from the right
	useEffect(() => {
		// check if reduced motion is enabled
		const isReduced = window.matchMedia(`(prefers-reduced-motion: reduce)`).matches === true

		if (sliderRef.current && !isReduced && !isProductList) {
			let cards = sliderRef.current.children
			for (let i = 0; i < cards.length; i++) {
				let card = cards[i] as HTMLElement
				card.classList.add('translate-x-10')
				card.classList.add('opacity-0')
				card.classList.add('transition-all', 'duration-1000', 'ease-in-out')
				card.style.transitionDelay = `${i * 0.2}s`
			}
		}

		const observer = new IntersectionObserver(
			(entries) => {
				entries.forEach((entry) => {
					if (entry.isIntersecting) {
						// target sliderRef childern
						if (sliderRef.current) {
							let cards = sliderRef.current.children
							for (let i = 0; i < cards.length; i++) {
								let card = cards[i] as HTMLElement
								card.classList.remove('translate-x-10')
								card.classList.remove('opacity-0')
							}
						}
					}
				})
			},
			{ threshold: 0.1 }
		)
		if (sliderRef.current) {
			observer.observe(sliderRef.current)
		}
	}, [])

	return (
		<div className="relative">
			{/* Header Content */}
			<div className="-mb-10">{contentToLeftOfScrollButtons}</div>
			{/* Content to Left & Scroll Buttons */}
			<div className="flex justify-end">
				<ScrollButtons
					className={scrollButtonsClassName}
					previousSlide={(): void => {
						if (sliderRef.current) {
							let card = sliderRef.current.children[0] as HTMLElement
							sliderRef.current.scrollBy({
								left: -card.offsetWidth - gap,
								behavior: 'smooth'
							})
						}
					}}
					nextSlide={(): void => {
						if (sliderRef.current) {
							let card = sliderRef.current.children[0] as HTMLElement
							sliderRef.current.scrollBy({
								left: card.offsetWidth + gap,
								behavior: 'smooth'
							})
						}
					}}
				/>
			</div>
			{/* Cards */}
			<div
				ref={sliderRef}
				className="scroll-bar-invisible flex gap-4 overflow-x-scroll overflow-y-hidden pb-10 snap-x items-stretch rounded-lg">
				{cards}
			</div>
		</div>
	)
}
