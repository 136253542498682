import { sanitize } from 'isomorphic-dompurify'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import type { IconProp } from '@fortawesome/fontawesome-svg-core'

import type { RawSVGObject } from 'types'
import { CSSProperties } from 'react'
type FontAwesomeProperties = {
	'--fa-primary-color'?: string
	'--fa-secondary-color'?: string
	'--fa-secondary-opacity'?: number
}

type IconProps = {
	icon: any
	className?: string
	style?: CSSProperties & FontAwesomeProperties
}

export default function Icon({ icon, className = '', style }: IconProps) {
	const html = (icon as RawSVGObject)?.html?.replace('<svg', `<svg class="${className}"`)

	return html ? (
		<div
			className="contents"
			dangerouslySetInnerHTML={{
				__html: sanitize(html)
			}}
		/>
	) : !icon?.src ? (
		icon?.prefix === 'fa-kit' ? (
			// Since custom icons from kit can't be rendered in FontAwesomeIcon, we need to use the custom icon component
			<i className={`fa-kit ${icon.iconName} ${className}`} />
		) : (
			<FontAwesomeIcon icon={icon as IconProp} className={className} style={style} />
		)
	) : null
}
