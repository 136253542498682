import { LOGIN, ORDERS } from 'auth'
import { getBaseUrl } from 'utils'
import Link from 'next/link'

import { Symbol } from 'ui'

interface Props {
	isLoggedIn?: boolean
	linkClass: string
	className?: string
	analyticalClickFactory?: (a: string) => () => void
	loading?: boolean
}

export default function NavLinks({
	linkClass,
	isLoggedIn = false,
	className,
	analyticalClickFactory,
	loading = false
}: Props) {
	if (loading) {
		return (
			<div className={`lg:flex items-center ${className ?? ''}`}>
				<div className="animate-pulse bg-gray-200 rounded h-[1.125rem] w-20" />
			</div>
		)
	}

	return (
		<div className={`lg:flex items-center ${className ?? ''}`}>
			{isLoggedIn ? (
				<>
					<Link
						href={`${getBaseUrl()}/account${ORDERS}`}
						className={`${linkClass}`}
						{...(analyticalClickFactory ? { onClick: analyticalClickFactory('My Account') } : {})}>
						<Symbol id="account" className="mr-3 lg:hidden" />
						My Account
					</Link>
				</>
			) : (
				<Link
					href={`${getBaseUrl()}/account${LOGIN}`}
					className={linkClass}
					{...(analyticalClickFactory ? { onClick: analyticalClickFactory('Sign In') } : {})}>
					Sign In
				</Link>
			)}
		</div>
	)
}
