import useSWR from 'swr'
import prismicClient from 'config/prismic.config'

const fetcher = async () => await prismicClient().getAllByType('global')

export const useGlobalData = () => {
	const { data, error } = useSWR({}, fetcher)

	return {
		data: data?.[0]?.data,
		isLoading: !error && !data,
		isError: error
	}
}
