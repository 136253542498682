import { useAnalyticalDataContext } from './context/AnalyticalDataContext'
import { useCustomerLabsContext } from './context/CustomerLabsContext'
import dayjs from 'dayjs'

export function toCustomerLabsProperty(v: any) {
	if (v === null || v === undefined) return

	const type = typeof v
	switch (type) {
		case 'string':
			return { t: 'string', v }
		case 'number':
			return { t: 'number', v }
		case 'boolean':
			return { t: 'string', v: v ? 'true' : 'false' }
		case 'object':
			if (Array.isArray(v)) return
			const result = { t: 'object', v: {} }
			for (const [k, val] of Object.entries(v)) {
				const processed = toCustomerLabsProperty(val)
				if (processed) result.v[k] = processed
			}
			return result
		default:
			return
	}
}

export function processStyleData(style: any) {
	const categories = style?.additionalProductDetail
		?.split(/ ?> ?/)
		.slice(1, -1)
		.map((ap: any) => {
			const res = /\[([^\]]+)\]/.exec(ap)
			if (res && res[1]) return res[1]
			else return undefined
		})
		.filter((a: any) => a)
		.join(',')

	return {
		product_name: style?.detail,
		product_id: style?.sfid,
		product_category: categories,
		product_variant: style?.colors?.map((color: any) => color.name).join(',') || ''
	} as any
}

export function processFormData(userData: any, otherData?: any) {
	const parsedUserData = {
		email: userData['email'],
		first_name: userData['first_name'] || userData['first-name'],
		last_name: userData['last_name'] || userData['last-name'],
		company: userData['company'],
		city: userData['city'],
		zip_code: userData['zip_code'],
		state: userData['state']
	}
	return {
		identifyData: {
			customProperties: {
				user_traits: toCustomerLabsProperty(parsedUserData),
				identify_by_email: {
					t: 'string',
					ib: true,
					v: userData['email']
				}
			}
		},
		submitData: {
			customProperties: toCustomerLabsProperty({
				...(otherData || {}),
				...parsedUserData
			}).v
		}
	}
}

export function formatAnalyticsDate(date: Date) {
	return dayjs(date).format('YYYYMMDD')
}

export function useCheckoutAnalyticsTrigger(checkout_step: string) {
	const { trackClick } = useCustomerLabsContext()
	const {
		page_url,
		page_title,
		order_name,
		currency,
		value,
		user_id,
		user_type,
		productProperties
	} = useAnalyticalDataContext()

	return () => {
		trackClick('Checkout Made', {
			customProperties: toCustomerLabsProperty({
				page_url,
				page_title,
				order_name,
				currency,
				value,
				user_id,
				user_type,
				checkout_step
			}).v,
			productProperties
		})
	}
}
