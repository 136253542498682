import { gql } from '@apollo/client'
import { apolloClientNewServer } from 'utils/clients/apolloClientNewServer'

const GET_REVIEWS = gql`
	query Reviews($skip: Int, $take: Int) {
		reviews(skip: $skip, take: $take) {
			sfid
			contact {
				firstname
				lastname
				profilePhoto
				mailingCountry
				city
				state
			}
			comments
			rating
			createdAt
		}
	}
`

const GET_REVIEWS_METADATA = gql`
	query ReviewsMetadata {
		reviewsMetadata {
			totalCount
			averageRating
			starDistribution {
				oneStar
				twoStars
				threeStars
				fourStars
				fiveStars
			}
		}
	}
`

export const getReviews = async (args?: { skip?: number; take?: number }) => {
	try {
		const response = await apolloClientNewServer.query({
			query: GET_REVIEWS,
			variables: { skip: args?.skip || 0, take: args?.take || 10 }
		})

		// Transform the data to include only the initial of the last name
		const transformedData = response.data.reviews.map((review) => ({
			...review,
			contact: {
				...review.contact,
				lastname: review.contact.lastname ? review.contact.lastname.charAt(0) : ''
			}
		}))

		return {
			...response,
			data: {
				reviews: transformedData
			}
		}
	} catch (error) {
		console.error('Error fetching reviews:', error)
		throw error
	}
}

export const getReviewsMetadata = async () => {
	try {
		const response = await apolloClientNewServer.query({
			query: GET_REVIEWS_METADATA
		})

		return response.data.reviewsMetadata
	} catch (error) {
		console.error('Error fetching reviews count:', error)
		throw error
	}
}
