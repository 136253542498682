import { mapValuesDeep, mapDeep } from 'deepdash/standalone'

import prismicClient from 'config/prismic.config'
import { promotionalPopupQuery, globalBuilderQuery } from 'utils/queries'
import { PageDocument } from 'types/types.generated'

export const getDropdownDeepLinks = async (page: PageDocument) => {
	if (page && typeof page === 'object') {
		const pageIds = Array.from(
			new Set(
				mapDeep(page, (value: { id?: string; type: string }, key: string) => {
					if (key === 'link' && value?.id && value.type === 'article') {
						return value.id
					}
				}).filter((id: string) => !!id && typeof id === 'string')
			)
		) as string[]

		const deepLinkedDocs = (await prismicClient()
			.getAllByIDs(pageIds, {
				graphQuery: `
        {
          article {
            category {
              ...on article_category {
                uid
              }
            }
          }
        }
        `
			})
			.catch((err) => console.log(err))) as any[]

		const productCategoryIds = Array.from(
			new Set(
				mapDeep(page, (value: { id?: string; type: string }, key: string) => {
					if (key === 'link' && value?.id && value.type === 'product_category') {
						return value.id
					}
				}).filter((id: string) => !!id && typeof id === 'string')
			)
		) as string[]

		const deepLinkedProductCategories = (await prismicClient()
			.getAllByIDs(productCategoryIds, {
				graphQuery: `
		{
			product_category {
				...product_categoryFields
		  	}
		}
		`
			})
			.catch((err) => console.log(err))) as any[]

		const pageWithDropdownDeepLinks = mapValuesDeep(page, (value) => {
			if (pageIds.includes(value?.id)) {
				return (deepLinkedDocs || []).find((doc) => doc?.id === value.id)
			}

			if (productCategoryIds.includes(value?.id)) {
				return (deepLinkedProductCategories || []).find((doc) => doc?.id === value.id)
			}

			return value
		})

		return pageWithDropdownDeepLinks
	}

	return page
}

export const getProductsLinksData = async (links, needLinks = false) => {
	if (needLinks) {
		const promises = []

		links?.forEach((linkObj) => {
			if (
				linkObj?.link?.id &&
				(linkObj?.link?.type === 'product_category' || linkObj?.link?.type === 'product_spec')
			)
				promises.push(prismicClient().getByID(linkObj?.link?.id))
			if (linkObj?.marketing_card?.data?.card_cta_link?.id)
				promises.push(prismicClient().getByID(linkObj?.marketing_card?.data?.card_cta_link?.id))
		})

		const data = await Promise.all(promises).catch((err) => null)

		const linksData = []
		data?.map((link) => {
			linksData.push({
				...link,
				position: links.find((linkObj) => linkObj?.link?.id === link.id)?.link_position ?? null
			})
		})

		return linksData ?? links
	}
}

export const getGlobalBuilderData = async () => {
	let globalBuilderData = await prismicClient()
		.getSingle('global_builder', {
			graphQuery: globalBuilderQuery()
		})
		.catch(() => null)

	if (!globalBuilderData) return null

	let ctaLinks = []

	globalBuilderData.data.add_ons.map((addOn) => {
		ctaLinks.push(
			{
				link: addOn.primary_cta_link3
			},
			{
				link: addOn.secondary_cta_link3
			}
		)
	})

	const linksData = await getProductsLinksData(ctaLinks, true)

	const getLinksData = (link) => {
		if (!link) return null

		return linksData?.find((linkData) => linkData.id === link?.id) || link
	}

	globalBuilderData.data.add_ons.map((addOn) => {
		addOn.primary_cta_link3 = getLinksData(addOn.primary_cta_link3)
		addOn.secondary_cta_link3 = getLinksData(addOn.secondary_cta_link3)
	})

	return globalBuilderData
}

export const getPromotionalPopups = async (
	slug: string,
	parentSlug: string = null,
	isFromProductChildPage: boolean = false,
	isFromHelpCenterChildPage: boolean = false,
	isFromBlogChildPage: boolean = false,
	isFromAccountChildPage: boolean = false
) => {
	const promotionalPopups = await prismicClient()
		.getAllByType('promotion_popup_modal', {
			graphQuery: promotionalPopupQuery()
		})
		.catch(() => null)

	const filteredPromotionalPopups = []

	promotionalPopups?.map((popup) => {
		if (
			// If the popup is not excluded from the current page
			(slug === 'cart' && !popup?.data?.exclude_cart_parent_child_pages) ||
			(slug === 'help-center' && !popup?.data?.exclude_help_center_parent_child_pages) ||
			(slug === 'account' && !popup?.data?.exclude_account_parent_child_pages) ||
			(slug === 'blog' && !popup?.data?.exclude_blog_parent_child_pages) ||
			(slug === 'products' && !popup?.data?.exclude_products_parent_child_pages) ||
			(slug === 'collections' && !popup?.data?.exclude_collections_parent_child_pages) ||
			(isFromProductChildPage && !popup?.data?.exclude_products_parent_child_pages) ||
			(isFromHelpCenterChildPage && !popup?.data?.exclude_help_center_parent_child_pages) ||
			(isFromBlogChildPage && !popup?.data?.exclude_blog_parent_child_pages) ||
			(isFromAccountChildPage && !popup?.data?.exclude_account_parent_child_pages) ||
			(!(
				slug === 'cart' ||
				slug === 'help-center' ||
				slug === 'account' ||
				slug === 'blog' ||
				slug === 'products' ||
				slug === 'collections' ||
				slug === 'blog'
			) &&
				!isFromProductChildPage &&
				!isFromHelpCenterChildPage &&
				!isFromBlogChildPage &&
				!isFromAccountChildPage)
		) {
			if (
				// If the popup is not excluded from the current page
				(!popup?.data?.exclusion_pages?.find(
					(page) =>
						page?.page?.uid === slug ||
						page?.slug === `/${slug}` ||
						page?.slug === `/${parentSlug}/${slug}`
				) &&
					// If the popup does not have any included pages
					popup?.data?.included_pages?.length <= 1 &&
					!popup?.data?.included_pages?.[0]?.page?.id &&
					!popup?.data?.included_pages?.[0]?.slug) ||
				// If the popup is included in the current page
				popup?.data?.included_pages?.find(
					(page) =>
						page?.page?.uid === slug ||
						page?.slug === `/${slug}` ||
						page?.slug === `/${parentSlug}/${slug}`
				)
			) {
				filteredPromotionalPopups.push(popup)
			}
		}
	})

	return filteredPromotionalPopups
}
