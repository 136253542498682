import { useEffect, useState, useRef } from 'react'

export function useHeadsObserver() {
	const observer = useRef<IntersectionObserver | null>()
	const [activeId, setActiveId] = useState('')

	useEffect(() => {
		const handleObsever = (entries) => {
			let maxRatio = 0
			let mostVisibleId = ''

			entries.forEach((entry) => {
				if (entry.isIntersecting && entry.intersectionRatio > maxRatio) {
					maxRatio = entry.intersectionRatio
					mostVisibleId = entry.target.id
				}
			})

			if (mostVisibleId) {
				setActiveId(mostVisibleId)
			}
		}

		observer.current = new IntersectionObserver(handleObsever, {
			rootMargin: '-10% 0% -88% 0px'
		})

		const elements = document.querySelectorAll('.rich-text h2')
		elements.forEach((elem) => observer.current.observe(elem))
		return () => observer.current?.disconnect()
	}, [])

	return { activeId, setActiveId }
}
