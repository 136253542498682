// ['phone', 'street1', 'street2', 'city']
const defaultFields = [
	{
		label: 'Street address (include suite, unit, or apt #)',
		name: 'addressStreet',
		type: 'text',
		required: true
	},
	{
		label: 'City',
		name: 'addressCity',
		type: 'text',
		required: true
	}
]

//STATES BY COUNTRY
const australiaStates = [
	{ country: 'AU', code: 'ACT', state: 'Australian Capital Territory' },
	{ country: 'AU', code: 'NSW', state: 'New South Wales' },
	{ country: 'AU', code: 'NT', state: 'Northern Territory' },
	{ country: 'AU', code: 'QLD', state: 'Queensland' },
	{ country: 'AU', code: 'SA', state: 'South Australia' },
	{ country: 'AU', code: 'TAS', state: 'Tasmania' },
	{ country: 'AU', code: 'VIC', state: 'Victoria' },
	{ country: 'AU', code: 'WA', state: 'Western Australia' }
]

const canadaStates = [
	{ country: 'CA', code: 'AB', state: 'Alberta' },
	{ country: 'CA', code: 'BC', state: 'British Columbia' },
	{ country: 'CA', code: 'MB', state: 'Manitoba' },
	{ country: 'CA', code: 'NB', state: 'New Brunswick' },
	{ country: 'CA', code: 'NL', state: 'Newfoundland and Labrador' },
	{ country: 'CA', code: 'NS', state: 'Nova Scotia' },
	{ country: 'CA', code: 'NT', state: 'Northwest Territories' },
	{ country: 'CA', code: 'NU', state: 'Nunavut' },
	{ country: 'CA', code: 'ON', state: 'Ontario' },
	{ country: 'CA', code: 'PE', state: 'Prince Edward Island' },
	{ country: 'CA', code: 'QC', state: 'Quebec' },
	{ country: 'CA', code: 'SK', state: 'Saskatchewan' },
	{ country: 'CA', code: 'YT', state: 'Yukon Territories' }
]

const chinaStates = [
	{ country: 'CN', code: '11', state: 'Beijing' },
	{ country: 'CN', code: '12', state: 'Tianjin' },
	{ country: 'CN', code: '13', state: 'Hebei' },
	{ country: 'CN', code: '14', state: 'Shanxi' },
	{ country: 'CN', code: '15', state: 'Nei Mongol' },
	{ country: 'CN', code: '21', state: 'Liaoning' },
	{ country: 'CN', code: '22', state: 'Jilin' },
	{ country: 'CN', code: '23', state: 'Heilongjiang' },
	{ country: 'CN', code: '31', state: 'Shanghai' },
	{ country: 'CN', code: '32', state: 'Jiangsu' },
	{ country: 'CN', code: '33', state: 'Zhejiang' },
	{ country: 'CN', code: '34', state: 'Anhui' },
	{ country: 'CN', code: '35', state: 'Fujian' },
	{ country: 'CN', code: '36', state: 'Jiangxi' },
	{ country: 'CN', code: '37', state: 'Shandong' },
	{ country: 'CN', code: '41', state: 'Henan' },
	{ country: 'CN', code: '42', state: 'Hubei' },
	{ country: 'CN', code: '43', state: 'Hunan' },
	{ country: 'CN', code: '44', state: 'Guangdong' },
	{ country: 'CN', code: '45', state: 'Guangxi' },
	{ country: 'CN', code: '46', state: 'Hainan' },
	{ country: 'CN', code: '50', state: 'Chongqing' },
	{ country: 'CN', code: '51', state: 'Sichuan' },
	{ country: 'CN', code: '52', state: 'Guizhou' },
	{ country: 'CN', code: '53', state: 'Yunnan' },
	{ country: 'CN', code: '54', state: 'Xizang' },
	{ country: 'CN', code: '61', state: 'Shaanxi' },
	{ country: 'CN', code: '62', state: 'Gansu' },
	{ country: 'CN', code: '63', state: 'Qinghai' },
	{ country: 'CN', code: '64', state: 'Ningxia' },
	{ country: 'CN', code: '65', state: 'Xinjiang' },
	{ country: 'CN', code: '71', state: 'Chinese Taipei' },
	{ country: 'CN', code: '91', state: 'Hong Kong' },
	{ country: 'CN', code: '92', state: 'Macao' }
]

const irelandStates = [
	{ country: 'IE', code: 'CE', state: 'Clare' },
	{ country: 'IE', code: 'CN', state: 'Cavan' },
	{ country: 'IE', code: 'CO', state: 'Cork' },
	{ country: 'IE', code: 'CW', state: 'Carlow' },
	{ country: 'IE', code: 'D', state: 'Dublin' },
	{ country: 'IE', code: 'DL', state: 'Donegal' },
	{ country: 'IE', code: 'G', state: 'Galway' },
	{ country: 'IE', code: 'KE', state: 'Kildare' },
	{ country: 'IE', code: 'KK', state: 'Kilkenny' },
	{ country: 'IE', code: 'KY', state: 'Kerry' },
	{ country: 'IE', code: 'LD', state: 'Longford' },
	{ country: 'IE', code: 'LH', state: 'Louth' },
	{ country: 'IE', code: 'LK', state: 'Limerick' },
	{ country: 'IE', code: 'LM', state: 'Leitrim' },
	{ country: 'IE', code: 'LS', state: 'Laois' },
	{ country: 'IE', code: 'MH', state: 'Meath' },
	{ country: 'IE', code: 'MN', state: 'Monaghan' },
	{ country: 'IE', code: 'MO', state: 'Mayo' },
	{ country: 'IE', code: 'OY', state: 'Offaly' },
	{ country: 'IE', code: 'RN', state: 'Roscommon' },
	{ country: 'IE', code: 'SO', state: 'Sligo' },
	{ country: 'IE', code: 'TA', state: 'Tipperary' },
	{ country: 'IE', code: 'WD', state: 'Waterford' },
	{ country: 'IE', code: 'WH', state: 'Westmeath' },
	{ country: 'IE', code: 'WW', state: 'Wicklow' },
	{ country: 'IE', code: 'WX', state: 'Wexford' }
]

export const japanStates = [
	{ country: 'JP', code: '23', state: 'Aichi' },
	{ country: 'JP', code: '05', state: 'Akita' },
	{ country: 'JP', code: '02', state: 'Aomori' },
	{ country: 'JP', code: '12', state: 'Chiba' },
	{ country: 'JP', code: '38', state: 'Ehime' },
	{ country: 'JP', code: '18', state: 'Fukui' },
	{ country: 'JP', code: '40', state: 'Fukuoka' },
	{ country: 'JP', code: '07', state: 'Fukushima' },
	{ country: 'JP', code: '21', state: 'Gifu' },
	{ country: 'JP', code: '10', state: 'Gunma' },
	{ country: 'JP', code: '34', state: 'Hiroshima' },
	{ country: 'JP', code: '01', state: 'Hokkaido' },
	{ country: 'JP', code: '28', state: 'Hyōgo' },
	{ country: 'JP', code: '08', state: 'Ibaraki' },
	{ country: 'JP', code: '17', state: 'Ishikawa' },
	{ country: 'JP', code: '03', state: 'Iwate' },
	{ country: 'JP', code: '37', state: 'Kagawa' },
	{ country: 'JP', code: '46', state: 'Kagoshima' },
	{ country: 'JP', code: '14', state: 'Kanagawa' },
	{ country: 'JP', code: '39', state: 'Kōchi' },
	{ country: 'JP', code: '43', state: 'Kumamoto' },
	{ country: 'JP', code: '26', state: 'Kyōto' },
	{ country: 'JP', code: '24', state: 'Mie' },
	{ country: 'JP', code: '04', state: 'Miyagi' },
	{ country: 'JP', code: '45', state: 'Miyazaki' },
	{ country: 'JP', code: '20', state: 'Nagano' },
	{ country: 'JP', code: '42', state: 'Nagasaki' },
	{ country: 'JP', code: '29', state: 'Nara' },
	{ country: 'JP', code: '15', state: 'Niigata' },
	{ country: 'JP', code: '44', state: 'Ōita' },
	{ country: 'JP', code: '33', state: 'Okayama' },
	{ country: 'JP', code: '47', state: 'Okinawa' },
	{ country: 'JP', code: '27', state: 'Ōsaka' },
	{ country: 'JP', code: '41', state: 'Saga' },
	{ country: 'JP', code: '11', state: 'Saitama' },
	{ country: 'JP', code: '25', state: 'Shiga' },
	{ country: 'JP', code: '32', state: 'Shimane' },
	{ country: 'JP', code: '22', state: 'Shizuoka' },
	{ country: 'JP', code: '09', state: 'Tochigi' },
	{ country: 'JP', code: '36', state: 'Tokushima' },
	{ country: 'JP', code: '13', state: 'Tōkyō' },
	{ country: 'JP', code: '31', state: 'Tottori' },
	{ country: 'JP', code: '16', state: 'Toyama' },
	{ country: 'JP', code: '30', state: 'Wakayama' },
	{ country: 'JP', code: '06', state: 'Yamagata' },
	{ country: 'JP', code: '35', state: 'Yamaguchi' },
	{ country: 'JP', code: '19', state: 'Yamanashi' }
]

const mexicoStates = [
	{ country: 'MX', code: 'AG', state: 'Aguascalientes' },
	{ country: 'MX', code: 'BC', state: 'Baja California' },
	{ country: 'MX', code: 'BS', state: 'Baja California Sur' },
	{ country: 'MX', code: 'CH', state: 'Chihuahua' },
	{ country: 'MX', code: 'CL', state: 'Colima' },
	{ country: 'MX', code: 'CM', state: 'Campeche' },
	{ country: 'MX', code: 'CO', state: 'Coahuila' },
	{ country: 'MX', code: 'CS', state: 'Chiapas' },
	{ country: 'MX', code: 'DF', state: 'Federal District' },
	{ country: 'MX', code: 'DG', state: 'Durango' },
	{ country: 'MX', code: 'GR', state: 'Guerrero' },
	{ country: 'MX', code: 'GT', state: 'Guanajuato' },
	{ country: 'MX', code: 'HG', state: 'Hidalgo' },
	{ country: 'MX', code: 'JA', state: 'Jalisco' },
	{ country: 'MX', code: 'ME', state: 'Mexico State' },
	{ country: 'MX', code: 'MI', state: 'Michoacán' },
	{ country: 'MX', code: 'MO', state: 'Morelos' },
	{ country: 'MX', code: 'NA', state: 'Nayarit' },
	{ country: 'MX', code: 'NL', state: 'Nuevo León' },
	{ country: 'MX', code: 'OA', state: 'Oaxaca' },
	{ country: 'MX', code: 'PB', state: 'Puebla' },
	{ country: 'MX', code: 'QE', state: 'Querétaro' },
	{ country: 'MX', code: 'QR', state: 'Quintana Roo' },
	{ country: 'MX', code: 'SI', state: 'Sinaloa' },
	{ country: 'MX', code: 'SL', state: 'San Luis Potosí' },
	{ country: 'MX', code: 'SO', state: 'Sonora' },
	{ country: 'MX', code: 'TB', state: 'Tabasco' },
	{ country: 'MX', code: 'TL', state: 'Tlaxcala' },
	{ country: 'MX', code: 'TM', state: 'Tamaulipas' },
	{ country: 'MX', code: 'VE', state: 'Veracruz' },
	{ country: 'MX', code: 'YU', state: 'Yucatán' },
	{ country: 'MX', code: 'ZA', state: 'Zacatecas' }
]

const newZealandStates = [
	{ country: 'NZ', code: 'NTL', state: 'Northland' },
	{ country: 'NZ', code: 'AUK', state: 'Auckland' },
	{ country: 'NZ', code: 'WKO', state: 'Waikato' },
	{ country: 'NZ', code: 'BOP', state: 'Bay of Plenty' },
	{ country: 'NZ', code: 'GIS', state: 'Gisborne' },
	{ country: 'NZ', code: 'HKB', state: "Hawke's Bay" },
	{ country: 'NZ', code: 'TKI', state: 'Taranaki' },
	{ country: 'NZ', code: 'MWT', state: 'Manawatū-Whanganui' },
	{ country: 'NZ', code: 'WGN', state: 'Wellington' },
	{ country: 'NZ', code: 'TAS', state: 'Tasman' },
	{ country: 'NZ', code: 'NSN', state: 'Nelson' },
	{ country: 'NZ', code: 'MBH', state: 'Marlborough' },
	{ country: 'NZ', code: 'WTC', state: 'West Coast' },
	{ country: 'NZ', code: 'CAN', state: 'Canterbury' },
	{ country: 'NZ', code: 'OTA', state: 'Otago' },
	{ country: 'NZ', code: 'STL', state: 'Southland' }
]

const portugalStates = [
	{ country: 'PT', code: '20', state: 'Azores' },
	{ country: 'PT', code: '01', state: 'Aveiro' },
	{ country: 'PT', code: '02', state: 'Beja' },
	{ country: 'PT', code: '03', state: 'Braga' },
	{ country: 'PT', code: '04', state: 'Bragança' },
	{ country: 'PT', code: '05', state: 'Castelo Branco' },
	{ country: 'PT', code: '06', state: 'Coimbra' },
	{ country: 'PT', code: '07', state: 'Évora' },
	{ country: 'PT', code: '08', state: 'Faro' },
	{ country: 'PT', code: '09', state: 'Guarda' },
	{ country: 'PT', code: '10', state: 'Leiria' },
	{ country: 'PT', code: '11', state: 'Lisbon' },
	{ country: 'PT', code: '30', state: 'Madeira' },
	{ country: 'PT', code: '12', state: 'Portalegre' },
	{ country: 'PT', code: '13', state: 'Porto' },
	{ country: 'PT', code: '14', state: 'Santarém' },
	{ country: 'PT', code: '15', state: 'Setúbal' },
	{ country: 'PT', code: '16', state: 'Viana do Castelo' },
	{ country: 'PT', code: '17', state: 'Vila Real' },
	{ country: 'PT', code: '18', state: 'Viseu' }
]

const spainStates = [
	{ country: 'ES', code: 'VI', state: 'Vizcaya' },
	{ country: 'ES', code: 'AB', state: 'Albacete' },
	{ country: 'ES', code: 'A', state: 'Alicante' },
	{ country: 'ES', code: 'AL', state: 'Almería' },
	{ country: 'ES', code: 'O', state: 'Asturias' },
	{ country: 'ES', code: 'AV', state: 'Ávila' },
	{ country: 'ES', code: 'BA', state: 'Badajoz' },
	{ country: 'ES', code: 'IB', state: 'Balearic Islands' },
	{ country: 'ES', code: 'B', state: 'Barcelona' },
	{ country: 'ES', code: 'BU', state: 'Burgos' },
	{ country: 'ES', code: 'CC', state: 'Cáceres' },
	{ country: 'ES', code: 'CA', state: 'Cádiz' },
	{ country: 'ES', code: 'S', state: 'Cantabria' },
	{ country: 'ES', code: 'CS', state: 'Castellón' },
	{ country: 'ES', code: 'CR', state: 'Ciudad Real' },
	{ country: 'ES', code: 'CO', state: 'Córdoba' },
	{ country: 'ES', code: 'C', state: 'A Coruña' },
	{ country: 'ES', code: 'CU', state: 'Cuenca' },
	{ country: 'ES', code: 'GI', state: 'Girona' },
	{ country: 'ES', code: 'GR', state: 'Granada' },
	{ country: 'ES', code: 'GU', state: 'Guadalajara' },
	{ country: 'ES', code: 'SS', state: 'Guipúzcoa' },
	{ country: 'ES', code: 'H', state: 'Huelva' },
	{ country: 'ES', code: 'HU', state: 'Huesca' },
	{ country: 'ES', code: 'J', state: 'Jaén' },
	{ country: 'ES', code: 'LO', state: 'La Rioja' },
	{ country: 'ES', code: 'GC', state: 'Las Palmas' },
	{ country: 'ES', code: 'LE', state: 'León' },
	{ country: 'ES', code: 'L', state: 'Lleida' },
	{ country: 'ES', code: 'LU', state: 'Lugo' },
	{ country: 'ES', code: 'M', state: 'Madrid' },
	{ country: 'ES', code: 'MA', state: 'Málaga' },
	{ country: 'ES', code: 'MU', state: 'Murcia' },
	{ country: 'ES', code: 'NA', state: 'Navarra' },
	{ country: 'ES', code: 'OR', state: 'Ourense' },
	{ country: 'ES', code: 'P', state: 'Palencia' },
	{ country: 'ES', code: 'PO', state: 'Pontevedra' },
	{ country: 'ES', code: 'SA', state: 'Salamanca' },
	{ country: 'ES', code: 'TF', state: 'Santa Cruz de Tenerife' },
	{ country: 'ES', code: 'SG', state: 'Segovia' },
	{ country: 'ES', code: 'SE', state: 'Sevilla' },
	{ country: 'ES', code: 'SO', state: 'Soria' },
	{ country: 'ES', code: 'T', state: 'Tarragona' },
	{ country: 'ES', code: 'TE', state: 'Teruel' },
	{ country: 'ES', code: 'TO', state: 'Toledo' },
	{ country: 'ES', code: 'V', state: 'Valencia' },
	{ country: 'ES', code: 'VA', state: 'Valladolid' },
	{ country: 'ES', code: 'BI', state: 'Biscay' },
	{ country: 'ES', code: 'ZA', state: 'Zamora' },
	{ country: 'ES', code: 'Z', state: 'Zaragoza' },
	{ country: 'ES', code: 'Álava', state: 'Álava' }
]

const usStates = [
	{ country: 'US', code: 'AA', state: 'Armed Forces Americas' },
	{ country: 'US', code: 'AE', state: 'Armed Forces Europe' },
	{ country: 'US', code: 'AK', state: 'Alaska' },
	{ country: 'US', code: 'AL', state: 'Alabama' },
	{ country: 'US', code: 'AP', state: 'Armed Forces Pacific' },
	{ country: 'US', code: 'AR', state: 'Arkansas' },
	{ country: 'US', code: 'AS', state: 'American Samoa' },
	{ country: 'US', code: 'AZ', state: 'Arizona' },
	{ country: 'US', code: 'CA', state: 'California' },
	{ country: 'US', code: 'CO', state: 'Colorado' },
	{ country: 'US', code: 'CT', state: 'Connecticut' },
	{ country: 'US', code: 'DC', state: 'District of Columbia' },
	{ country: 'US', code: 'DE', state: 'Delaware' },
	{ country: 'US', code: 'FL', state: 'Florida' },
	{ country: 'US', code: 'FM', state: 'Federated Micronesia' },
	{ country: 'US', code: 'GA', state: 'Georgia' },
	{ country: 'US', code: 'GU', state: 'Guam' },
	{ country: 'US', code: 'HI', state: 'Hawaii' },
	{ country: 'US', code: 'IA', state: 'Iowa' },
	{ country: 'US', code: 'ID', state: 'Idaho' },
	{ country: 'US', code: 'IL', state: 'Illinois' },
	{ country: 'US', code: 'IN', state: 'Indiana' },
	{ country: 'US', code: 'KS', state: 'Kansas' },
	{ country: 'US', code: 'KY', state: 'Kentucky' },
	{ country: 'US', code: 'LA', state: 'Louisiana' },
	{ country: 'US', code: 'MA', state: 'Massachusetts' },
	{ country: 'US', code: 'MD', state: 'Maryland' },
	{ country: 'US', code: 'ME', state: 'Maine' },
	{ country: 'US', code: 'MH', state: 'Marshall Islands' },
	{ country: 'US', code: 'MI', state: 'Michigan' },
	{ country: 'US', code: 'MN', state: 'Minnesota' },
	{ country: 'US', code: 'MO', state: 'Missouri' },
	{ country: 'US', code: 'MP', state: 'Northern Mariana Islands' },
	{ country: 'US', code: 'MS', state: 'Mississippi' },
	{ country: 'US', code: 'MT', state: 'Montana' },
	{ country: 'US', code: 'NC', state: 'North Carolina' },
	{ country: 'US', code: 'ND', state: 'North Dakota' },
	{ country: 'US', code: 'NE', state: 'Nebraska' },
	{ country: 'US', code: 'NH', state: 'New Hampshire' },
	{ country: 'US', code: 'NJ', state: 'New Jersey' },
	{ country: 'US', code: 'NM', state: 'New Mexico' },
	{ country: 'US', code: 'NV', state: 'Nevada' },
	{ country: 'US', code: 'NY', state: 'New York' },
	{ country: 'US', code: 'OH', state: 'Ohio' },
	{ country: 'US', code: 'OK', state: 'Oklahoma' },
	{ country: 'US', code: 'OR', state: 'Oregon' },
	{ country: 'US', code: 'PA', state: 'Pennsylvania' },
	{ country: 'US', code: 'PR', state: 'Puerto Rico' },
	{ country: 'US', code: 'PW', state: 'Palau' },
	{ country: 'US', code: 'RI', state: 'Rhode Island' },
	{ country: 'US', code: 'SC', state: 'South Carolina' },
	{ country: 'US', code: 'SD', state: 'South Dakota' },
	{ country: 'US', code: 'TN', state: 'Tennessee' },
	{ country: 'US', code: 'TX', state: 'Texas' },
	{ country: 'US', code: 'UM', state: 'US Minor Islands' },
	{ country: 'US', code: 'UT', state: 'Utah' },
	{ country: 'US', code: 'VA', state: 'Virginia' },
	{ country: 'US', code: 'VI', state: 'US Virgin Islands' },
	{ country: 'US', code: 'VT', state: 'Vermont' },
	{ country: 'US', code: 'WA', state: 'Washington' },
	{ country: 'US', code: 'WI', state: 'Wisconsin' },
	{ country: 'US', code: 'WV', state: 'West Virginia' },
	{ country: 'US', code: 'WY', state: 'Wyoming' }
]

const australiaExtraFields = {
	label: 'State/Territory',
	name: 'addressStateCode',
	type: 'select',
	required: true,
	options: australiaStates
}

const canadaExtraFields = {
	label: 'Province',
	name: 'addressStateCode',
	type: 'select',
	required: true,
	options: canadaStates
}

const chinaExtraFields = {
	label: 'Province',
	name: 'addressStateCode',
	type: 'select',
	required: true,
	options: chinaStates
}

const irelandExtraFields = {
	label: 'County',
	name: 'addressStateCode',
	type: 'select',
	required: true,
	options: irelandStates
}

const japanExtraFields = {
	label: 'Prefecture',
	name: 'addressStateCode',
	type: 'select',
	required: true,
	options: japanStates
}

const mexicoExtraFields = {
	label: 'State',
	name: 'addressStateCode',
	type: 'select',
	required: true,
	options: mexicoStates
}

const newZealandExtraFields = {
	label: 'Region',
	name: 'addressStateCode',
	type: 'select',
	required: true,
	options: newZealandStates
}

const portugalExtraFields = {
	label: 'Region',
	name: 'addressStateCode',
	type: 'select',
	required: true,
	options: portugalStates
}

const spainExtraFields = {
	label: 'Province',
	name: 'addressStateCode',
	type: 'select',
	required: true,
	options: spainStates
}

const usExtraFields = {
	label: 'State',
	name: 'addressStateCode',
	type: 'select',
	required: true,
	options: usStates
}

const defaultPostalCode = {
	label: 'Postal code',
	name: 'addressPostalCode',
	type: 'text',
	required: true
}

const postCode = {
	...defaultPostalCode,
	label: 'Postcode'
}

const zipCode = {
	...defaultPostalCode,
	label: 'ZIP code'
}

export const countries = [
	{
		id: '0',
		name: 'Australia',
		code: 'AU',
		fields: [...defaultFields, australiaExtraFields, postCode]
	},
	{
		id: '1',
		name: 'Bermuda',
		code: 'BM',
		fields: [...defaultFields, defaultPostalCode]
	},
	{
		id: '2',
		name: 'Canada',
		code: 'CA',
		fields: [...defaultFields, canadaExtraFields, zipCode]
	},
	{
		id: '3',
		name: 'China',
		code: 'CN',
		fields: [...defaultFields, chinaExtraFields, defaultPostalCode]
	},
	{
		id: '4',
		name: 'Denmark',
		code: 'DK',
		fields: [...defaultFields, defaultPostalCode]
	},
	{
		id: '5',
		name: 'France',
		code: 'FR',
		fields: [...defaultFields, defaultPostalCode]
	},
	{
		id: '6',
		name: 'Germany',
		code: 'DE',
		fields: [...defaultFields, defaultPostalCode]
	},
	{
		id: '7',
		name: 'Greece',
		code: 'GR',
		fields: [...defaultFields, postCode]
	},
	{
		id: '8',
		name: 'Greenland',
		code: 'GL',
		fields: [...defaultFields, defaultPostalCode]
	},
	{
		id: '9',
		name: 'Haití',
		code: 'HT',
		fields: [...defaultFields, defaultPostalCode]
	},
	{
		id: '10',
		name: 'Iceland',
		code: 'IS',
		fields: [...defaultFields, defaultPostalCode]
	},
	{
		id: '11',
		name: 'Ireland',
		code: 'IE',
		fields: [...defaultFields, irelandExtraFields, defaultPostalCode]
	},
	{
		id: '12',
		name: 'Japan',
		code: 'JP',
		fields: [...defaultFields, japanExtraFields, defaultPostalCode]
	},
	{
		id: '13',
		name: 'Mexico',
		code: 'MX',
		fields: [...defaultFields, mexicoExtraFields, defaultPostalCode]
	},
	{
		id: '14',
		name: 'Netherlands',
		code: 'NL',
		fields: [...defaultFields, defaultPostalCode]
	},
	{
		id: '15',
		name: 'New Zealand',
		code: 'NZ',
		fields: [...defaultFields, newZealandExtraFields, defaultPostalCode]
	},
	{
		id: '16',
		name: 'Norway',
		code: 'NO',
		fields: [...defaultFields, defaultPostalCode]
	},
	{
		id: '17',
		name: 'Poland',
		code: 'PL',
		fields: [...defaultFields, defaultPostalCode]
	},
	{
		id: '18',
		name: 'Portugal',
		code: 'PT',
		fields: [...defaultFields, portugalExtraFields, defaultPostalCode]
	},
	{
		id: '19',
		name: 'Singapore',
		code: 'SG',
		fields: [...defaultFields, defaultPostalCode]
	},
	{
		id: '20',
		name: 'Spain',
		code: 'ES',
		fields: [...defaultFields, spainExtraFields, defaultPostalCode]
	},
	{
		id: '21',
		name: 'Sweden',
		code: 'SE',
		fields: [...defaultFields, defaultPostalCode]
	},
	{
		id: '22',
		name: 'Saudi Arabia',
		code: 'SA',
		fields: [...defaultFields, defaultPostalCode]
	},
	{
		id: '23',
		name: 'Turkey',
		code: 'TR',
		fields: [...defaultFields, defaultPostalCode]
	},
	{
		id: '24',
		name: 'United States',
		code: 'US',
		fields: [...defaultFields, usExtraFields, zipCode]
	},
	{
		id: '25',
		name: 'United Kingdom',
		code: 'GB',
		fields: [...defaultFields, defaultPostalCode]
	}
]

export const countriesIds = [
	{
		name: 'Australia',
		id: 0
	},
	{
		name: 'Bermuda',
		id: 1
	},
	{
		name: 'Canada',
		id: 2
	},
	{
		name: 'China',
		id: 3
	},
	{
		name: 'Denmark',
		id: 4
	},
	{
		name: 'France',
		id: 5
	},
	{
		name: 'Germany',
		id: 6
	},
	{
		name: 'Greece',
		id: 7
	},
	{
		name: 'Greenland',
		id: 8
	},
	{
		name: 'Haití',
		id: 9
	},
	{
		name: 'Iceland',
		id: 10
	},
	{
		name: 'Ireland',
		id: 11
	},
	{
		name: 'Japan',
		id: 12
	},
	{
		name: 'Mexico',
		id: 13
	},
	{
		name: 'Netherlands',
		id: 14
	},
	{
		name: 'New Zealand',
		id: 15
	},
	{
		name: 'Norway',
		id: 16
	},
	{
		name: 'Poland',
		id: 17
	},
	{
		name: 'Portugal',
		id: 18
	},
	{
		name: 'Singapore',
		id: 19
	},
	{
		name: 'Spain',
		id: 20
	},
	{
		name: 'Sweden',
		id: 21
	},
	{
		name: 'Saudi Arabia',
		id: 22
	},
	{
		name: 'Turkey',
		id: 23
	},
	{
		name: 'United States',
		id: 24
	},
	{
		name: 'United Kingdom',
		id: 25
	}
]

export const completeStatesList = [
	...australiaStates,
	...canadaStates,
	...chinaStates,
	...irelandStates,
	...japanStates,
	...mexicoStates,
	...newZealandStates,
	...portugalStates,
	...spainStates,
	...usStates
]
