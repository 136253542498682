import Link from 'next/link'

import { getBaseUrl, linkResolver } from 'utils'

type FooterLinkOptionsProps = {
	link: {
		button_label: string
		link: {
			link_type: string
			id: string
			uid: string
		}
	}, 
	analyticalClickFactory?: ((_: string) => () => void)
}

export default function FooterLinkOptions({ link, analyticalClickFactory }: FooterLinkOptionsProps) {
	return (
		<Link
			className="block font-medium hover:text-yellow-mesh transition-colors duration-300 ease-in-out py-1.5"
			href={getBaseUrl() + linkResolver(link.link)}
			{...(analyticalClickFactory? { onClick: analyticalClickFactory(link.button_label)}: {})}>
			{link.button_label}
		</Link>
	)
}
