import { Fragment } from 'react'
import Link from 'next/link'
import { useRouter } from 'next/router'

import Icon from '../Icon'
import { PreFooter, FooterLinkOptions, PlainText } from '../'

import { linkResolver } from 'utils/prismic'
import { isNotEmptyArray, toCamelCase } from 'utils/client'
import { getBaseUrl, getNavigationIcon } from 'utils'
import { faArrowRight } from '@fortawesome/pro-regular-svg-icons'

import { useGlobalSearch } from 'context'
import { toCustomerLabsProperty, useAnalyticalDataContext, useCustomerLabsContext } from 'analytics'

import {
	ORDER_DETAILS,
	CART,
	CHECKOUT,
	LOGIN,
	CREATE_ACCOUNT,
	FORGOT_PASSWORD,
	RECOVER_ACCOUNT
} from 'auth'
import clsx from 'clsx'
import SocialIcons from './SocialIcons'
import Wrapper from '../order/Wrapper'
import type { GlobalDocument } from 'types/types.generated'

interface FooterType {
	showMarketingSection?: boolean
	showSearchAndLinks?: boolean
	globalData?: GlobalDocument
}

export default function Footer({
	showMarketingSection = false,
	showSearchAndLinks = false,
	globalData
}: FooterType) {
	const currentYear = new Date().getFullYear()
	const { setOpenGlobalSearch } = useGlobalSearch()

	const router = useRouter()

	const {
		body: footer_links,
		social_media_links,
		support_links,
		disclaimer_links
	} = globalData?.data || globalData || {}

	const hasValidFooterLinks = !!(
		isNotEmptyArray(footer_links) &&
		footer_links.some((linkGroup) => isNotEmptyArray(linkGroup?.items))
	)

	const analyticalClickFactory = useAnalyticsFooterClickFactory()

	return (
		<footer
			className={clsx(
				'w-full',
				(router.pathname === LOGIN ||
					router.pathname === CREATE_ACCOUNT ||
					router.pathname === FORGOT_PASSWORD ||
					router.pathname === RECOVER_ACCOUNT) &&
					'bg-g-100'
			)}>
			{/* Pre-Footer */}
			{showMarketingSection ? <PreFooter globalData={globalData} /> : null}

			{/* Search & Footer Cols */}
			<div
				className={clsx(
					showSearchAndLinks && router.pathname !== ORDER_DETAILS ? 'pt-28' : '',
					'bg-black-rt relative'
				)}>
				{/* Search & Footer Links */}
				<div className="flex flex-col space-y-16 text-white/70 transition-colors duration-300 max-w-[1700px] lg:px-10 xl:px-28 mx-auto">
					{showSearchAndLinks && router.pathname !== ORDER_DETAILS ? (
						<>
							{/* Search */}
							<button
								type="button"
								onClick={() => setOpenGlobalSearch(true)}
								className="desktop-h3 text-white/40 text-left box-border px-8 md:pl-8 flex justify-between items-center hover:text-white transition-colors duration-300 pt-20 pb-2">
								Search for products, services, or resources...
								<div className="border border-white/40 rounded-full w-9 h-9 flex justify-center items-center flex-shrink-0 group-hover:border-white transition-border duration-300">
									<Icon icon={faArrowRight} className="w-3.5 h-3.5 text-white" />
								</div>
							</button>

							<hr className=" border-t border-white/10 mx-8 md:ml-8 box-border" />

							<div className="px-8 flex max-sm:flex-col gap-14 lg:gap-40 pb-14">
								{/* Support Links, Search, & Social Media Links */}
								{isNotEmptyArray(support_links) ? (
									<div className="flex flex-col gap-y-3 sm:w-fit">
										{support_links.map((linkObj, i) => {
											const { icon, link, button_label, intercom_actions } = linkObj || {}
											const intercomAction = toCamelCase(intercom_actions)

											return (link?.url || link?.uid || intercomAction) && button_label ? (
												<Wrapper
													key={i}
													link={link}
													button_label={button_label}
													globalData={globalData?.data ?? globalData}
													icon={icon}>
													{icon ? (
														<Icon
															className="flex-shrink-0 w-3 mr-1"
															icon={getNavigationIcon(icon)}
														/>
													) : null}

													<span className="font-medium -mb-1 whitespace-nowrap">
														{button_label}
													</span>
												</Wrapper>
											) : null
										})}
									</div>
								) : null}

								{hasValidFooterLinks ? (
									<div className="container grid grid-cols-2 md:grid-cols-3 lg:grid-cols-[repeat(5,minmax(min-content,auto))] gap-y-9 gap-x-2 !pr-0">
										{footer_links.map((linkGroup, i) => {
											const { primary, items: links } = linkGroup || {}
											const { heading } = primary || {}

											return (
												// Each footer column of links on the right side
												<div className="footer-column max-w-[126px] text-left" key={i}>
													{/* Title */}
													<PlainText
														content={heading}
														as="h3"
														className="text-white font-medium py-1"
													/>

													{links?.map((link, i) =>
														linkGroup?.items ? (
															<FooterLinkOptions
																link={link}
																key={i}
																analyticalClickFactory={analyticalClickFactory}
															/>
														) : null
													)}
												</div>
											)
										})}
									</div>
								) : null}
							</div>
						</>
					) : null}
				</div>

				{/* Black Bottom Footer */}
				{router.pathname !== CART && router.pathname !== CHECKOUT && (
					<div className="lg:px-8 bg-g-900 text-white/60 py-12">
						<div className="px-8 lg:px-10 xl:px-28 flex max-sm:flex-col justify-between items-center gap-2 max-w-[1700px] mx-auto">
							{/* Disclaimer Text */}
							{currentYear || Array.isArray(disclaimer_links) ? (
								<p className="text-xs">
									{currentYear ? <span>{currentYear} © Real Thread</span> : null}
									{isNotEmptyArray(disclaimer_links)
										? disclaimer_links.map((linkObj, i) => {
												const { link, button_label } = linkObj || {}
												return (
													<Fragment key={i}>
														<span> | </span>
														<Link
															href={getBaseUrl() + linkResolver(link)}
															onClick={analyticalClickFactory(button_label)}
															key={i}
															className="hover:text-yellow-mesh">
															{button_label}
														</Link>
													</Fragment>
												)
										  })
										: null}
								</p>
							) : null}

							<SocialIcons
								social_media_links={social_media_links}
								analyticalClickFactory={analyticalClickFactory}
							/>
						</div>
					</div>
				)}
			</div>
		</footer>
	)
}

function useAnalyticsFooterClickFactory() {
	const { trackClick } = useCustomerLabsContext()
	const { page_title, page_url } = useAnalyticalDataContext()
	const analyticalClickFactory = (click_value: string) => () => {
		trackClick('Click', {
			customProperties: toCustomerLabsProperty({
				click_type: 'Footer Menu Navigation',
				click_value,
				page_title,
				page_url
			}).v
		})
	}
	return analyticalClickFactory
}
