import { gql, useMutation } from '@apollo/client'
import { apolloClientNewServer } from 'utils/clients/apolloClientNewServer'

export const useCreateUser = () => {
	const [createUser] = useMutation(CREATE_USER, {
		client: apolloClientNewServer
	})

	return {
		createUser
	}
}

const CREATE_USER = gql(`
    mutation CreateUser(
        $email: String!, 
        $firstName: String!, 
        $lastName: String!, 
        $authMethod: String, 
        $mobilePhone: String
        $taxExempt: Boolean, 
        $website: String, 
        $use: String, 
        $mailingCountry: String, 
        $estAnnualQuantity:  String
        $company: String
    ) {
        createUser(
            email: $email, 
            firstName: $firstName, 
            lastName: $lastName, 
            authMethod: $authMethod, 
            mobilePhone: $mobilePhone, 
            taxExempt: $taxExempt, 
            website: $website, 
            use: $use, 
            mailingCountry: $mailingCountry, 
            estAnnualQuantity: $estAnnualQuantity,
            company: $company
        ) {
            sfid
            type
        }
    }
`)
