export const getTabNames = (tab_cards, items) => {
	if (!tab_cards) return null

	// get tab names and no repeat
	const names = items.map((item: any) => item.tab_name ?? item.tab_label)

	return names.filter((name, i) => names.indexOf(name) === i).reverse()
}

export const getTabItems = (tab_cards, items, tabNames) => {
	// get tab items
	if (!tab_cards) return null

	return tabNames.map((name) =>
		items.filter((item: any) => item.tab_name === name || item.tab_label === name)
	)
}
